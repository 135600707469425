.select-content-disposition {
	text-align: $center;
	padding-bottom: 30px;

	@media #{$breakpoint-below-tablet} {
		padding: 0% 3% 1%;
	}

	& .stripes {
		margin: -3px auto 0;
		height: 30px;
	}

	& .disposition-headline {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 24px;
		text-align: $center;
		text-transform: uppercase;
		color: $Brand-Black;
		margin-top: 24px;
	}

	& .disposition-info-text {
		margin-top: 1%;
		font-family: $National2Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: -0.1px;
		color: $Brand-Black;

		@media #{$breakpoint-below-tablet} {
			margin-top: 2%;
		}
	}

	& .disposition-btns {
		background: $Light-Gray;
		display: $d-inline-flex;
		border-radius: 200px;
		margin-top: 20px;
		padding: 2px;

		@media #{$mobile-only} {
			margin-top: 19.71px;
		}

		& .button {
			padding: 12px 47px;
			font-family: $National2Condensed;
			text-transform: uppercase;
			font-weight: bold;
			font-size: 16px;
			line-height: 20px;
			letter-spacing: 1px;
			color: $Dark-Gray;

			@media #{$breakpoint-below-tablet} {
				padding: 10px 24px;
			}

			@media #{$breakpoint-below-include-iphoneX} {
				padding: 10px 27px;
			}

			@media #{$breakpoint-iphone-ios15} {
				padding: 10px 0 !important;
    			width: 7rem;
			}

			@media #{$breakpoint-below-include-iphone5} {
				padding: 10px 20px;
			}

			@media #{$breakpoint-below-include-iphone5SE} {
				padding: 10px 15px;
			}

			&.active-disposition {
				background: $Brand-Red;
				color: $white;
			}
		}
	}
}

.content-location-img {
	height: 18px;
	width: 13.2px;
	margin-right: 5px;

	@media #{$breakpoint-below-tablet} {
		height: 16px;
		width: 11px;
		margin-right: 9px;
		margin-left: 6px;
	}
}

.headBottomTxt.content-text {
	font-family: $National2Regular;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	text-transform: none;
	display: $d-inline-flex;
	letter-spacing: normal;

	@media #{$breakpoint-below-tablet} {
		width: 100%;
		display: block;
		margin-right: 5px;
	}

	.store-location {
		display: flex !important;
	}
}

.content-clock-img {
	margin-left: 15px;
	height: 18px;
	width: 18px;
	margin-right: 5px;

	@media #{$breakpoint-below-tablet} {
		margin-left: 6px;
		height: 14px;
		width: 14px;
		margin-right: 9px;
	}
}

.content-time {
	margin-right: 7px;
}

.change-store-btn {
	padding: 2px 10px 3px;
	height: 32px;
	width: 71px;
	font-family: $National2Regular;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 22px;
	align-self: center;

	@media #{$breakpoint-below-tablet} {
		height: 26px;
		width: 61px;
	}
}

.headerBottom.content-ribbon {
	position: relative;

	& .headBottomParent.content-parent {
		.content-stroe-wrap {
			@media #{$breakpoint-above-tablet} {
				max-width: 600px;
				text-overflow: ellipsis;
				overflow: hidden;
				white-space: nowrap;
			}
		}
		@media #{$breakpoint-below-tablet} {
			width: inherit;
			display: $d-flex;
			padding: 2%;
			text-align: left;
			margin: unset;

			& .content-store {
				font-family: $National2Regular;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				text-align: left;
				letter-spacing: normal !important;
			}

			.address {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 1;
				overflow: hidden;
			}
		}
	}
}
