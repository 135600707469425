.communication_container {
	display: flex;
	padding: 0 5%;
	flex-wrap: wrap;
	margin: 3% 0;

	& .communication-card {
		position: relative;
		display: flex;
		justify-content: $center;
		width: 50%;
		padding: 1%;
		max-height: 340px;

		@media #{$breakpoint-above-desktop} {
			max-height: max-content;
		}

		@media #{$breakpoint-below-tablet} {
			width: 100%;
			margin: 2% auto;
		}

		& .communicationCard-background {
			object-fit: cover;
			border-radius: 10px;
			width: 100%;

			@media #{$breakpoint-above-desktop} {
				object-fit: contain;
			}

			@media #{$breakpoint-below-tablet} {
				height: 230px;
			}
		}

		& .communicationCard-content {
			position: absolute;
			display: flex;
			align-items: $center;
			flex-direction: column;
			justify-content: $center;
			top: 34%;
			text-align: $center;

			@media #{$breakpoint-below-tablet} {
				top: 22%;
			}

			& .communicationCard-text {
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 24px;
				letter-spacing: 1.5px;
				text-transform: uppercase;
				color: #fff;
				padding: 0 10%;
				margin-bottom: 4%;

				@media #{$breakpoint-above-desktop} {
					margin-bottom: 6%;
					font-size: 40px;
					line-height: 40px;
				}

				@media #{$breakpoint-below-tablet} {
					font-size: 20px;
					line-height: 22px;
				}
			}

			& .communicationCard-button {
				// outline: none;
				border: none;

				@media #{$breakpoint-above-desktop} {
					width: 39%;
					height: 60px !important;
				}
			}
		}

		& .cummunication-card {
			width: 50%;
			height: 361px;
		}
	}

	& .communicationCard-full {
		max-height: 340px;
		width: 100%;

		@media #{$breakpoint-below-tablet} {
			width: auto;
		}

		& .communicationCard-background {
			object-fit: cover;
			border-radius: 10px;
			width: 100%;
		}
	}
}
