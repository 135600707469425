.secondary-header.au .secondary-header-container{
    max-width: 100%;
}
.secondary-header.au .secondary-header-container .kfc-header-container{
    padding-right: 0;
}

.thankyou-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    &.thankyou-stripes-section {
        top: 0;
        background-color: transparent;
        z-index: 111;
    
        @media #{$breakpoint-above-tablet} {
          padding-bottom: 24px;
        }
        @media #{$breakpoint-below-tablet} {
          padding-bottom: 16px;
        }
      }

.main-text-addhope-thankyou{
    font-family: $National2Condensed;
    font-weight: 700;
    line-height: 100%;
    text-align: center;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $Brand-Black;
   
    @media #{$breakpoint-above-tablet} {
        margin-top: 58px;
        max-width: 808px;
        width: 491px;
        font-size: 64px;
   
    }
      @media #{$breakpoint-below-tablet} {
        margin-top: 40px;
        max-width: 328px;
        width: 314px;
        font-size: 40px;
      }
    
}
.addhope-thankyou-confirm-button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: 44px;
    background: $Brand-Black;
    border-radius: 22px;
    @media #{$breakpoint-above-tablet} {
        width: 188px;
        margin-top: 48px;
    }
    @media #{$breakpoint-below-tablet} {
        width: 328px;
        margin-top: 40px;
    } 
 
}
.take-me-home-addhope-thankyou{
    display: flex;
    align-items: center;
    text-align: right;
    font-family: $National2Regular;
    color: $white;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    background: $Brand-Black;
    line-height: 24px;
    height: 24px;
    border: 1.5px solid $Brand-Black;
    @media #{$breakpoint-below-tablet} {
        font-size: 16px;
    }
}
.addhope-thankyou-img{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    margin-top: 48px;
    @media #{$breakpoint-below-tablet} {
        margin-top: 40px;
    }
}
}