.headerBottom,
.plpHeaderBottom,
.headerTrackOrderPanel {
	display: flex;
	background: $Brand-Black;
	height: 68px;

	@media #{$breakpoint-below-tablet} {
		height: unset !important;
	}
}

.headBottomParent {
	display: flex;
	margin: 0.5% auto;
	align-items: center;
	.headBottomTxt {
		margin: auto;
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 20px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		color: #fff;
		margin-right: 15px;
		min-width: 142px;
	}

}

@media #{$breakpoint-below-tablet} {
	.headerBottom {
		display: flex;
		// position: fixed;
		width: 100%;
		bottom: 0;
		z-index: 1;
		font-size: 16px;
		line-height: 20px;
		letter-spacing: 1px;
		padding: 5px;

		& .headBottomParent {
			display: block;
			text-align: $center;
			margin: 1% auto;

			& .start-order-btn {
				margin-top: 16px;
				width: 70%;
				margin-bottom: 21px;

				@media #{$mobile-only} {
					width: 75%;
					margin-bottom: 1%;
				}
			}

			& .headBottomTxt {
				font-size: 16px;
				line-height: 20px;
				letter-spacing: 1px;
				margin-top: unset;
			}
		}
	}

	.headerBottom.plpHeaderBottom {
		position: relative;
	}
}

.headerTrackOrderPanel {
	height: 50px;
	border-top: #ffffff 0.1px solid;

	.headBottomParent {
		margin: auto;
		align-self: center;
		display: flex;
		justify-content: center;

		.header-track-order-btn {
			button {
				float: left;
				border: 1.5px solid rgb(256, 256, 256);
				background: #202124;
				color: #fff;
				font-family: $National2Regular;
				font-size: 12px;
				height: 32px;
				letter-spacing: 1px;
				text-align: center;
				line-height: 22px;
				padding: 5px 20px;
				min-width: 100px;
			}
		}

		.head-track-order-Txt {
			padding-right: 25px;

			span {
				font-family: $National2Regular;
				font-style: normal;
				font-size: 12px;
				letter-spacing: 1px;
				color: #fff;
				text-align: center;
				line-height: 22px;
				font-weight: 700;
				padding: 5px 0px !important;
				float: right;
			}
		}
	}

	@media #{$breakpoint-below-tablet} {
		height: auto !important;
		padding: 0px 3% !important;

		.headBottomParent {
			margin: 0.5% 0px;
			padding: 0px !important;

			.head-track-order-Txt {
				padding: 0px 10px 0px 0px !important;
				width: inherit;

				span {
					text-align: left !important;
					float: left;
					letter-spacing: 0px;
				}
			}

			.header-track-order-btn {
				padding: 0px !important;
				align-self: center;

				button {
					float: right;
					height: 30px;
					padding: 3px 10px;
				}
			}
		}
	}
}

@media #{$breakpoint-above-tablet}{
.order-history-button{
	min-width: 130px !important;
	
}
}
