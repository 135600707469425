body.kfc-container {
	// margin: 8px 0;
	font-family: $National2Regular;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.wrapper-base-component{
	min-height:400px;
}

.error-box {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 0 auto;
	max-width: 600px;
	margin-top: 15%;
	height: 500px;
	justify-content: space-between;
	& img {
		height: 40%;
	}
	& h2 {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: 700;
		font-size: 36px;
		line-height: 22px;
		text-align: center;
		letter-spacing: 1px;
		text-transform: uppercase;
	}
	& p {
		font-family: $National2;
		font-style: normal;
		font-weight: 400;
		font-size: 20px;
		line-height: 24px;
		text-align: center;
	}
}

//display
.hidden{
	display: none !important;
}
.d-flex {
	display: $d-flex;
}

.d-inline-flex {
	display: $d-inline-flex;
}

// margins
.mt-1 {
	margin-top: 1%;
}

.mt-2 {
	margin-top: 2%;
}

.mt-3 {
	margin-top: 3%;
}

.mt-4 {
	margin-top: 4%;
}

.mt-5 {
	margin-top: 5%;
}

.mb-1 {
	margin-bottom: 1%;
}

.mb-2 {
	margin-bottom: 2%;
}

.mb-3 {
	margin-bottom: 3%;
}

.mb-4 {
	margin-bottom: 4%;
}

.mb-5 {
	margin-bottom: 5%;
}

.mx-3 {
	margin: auto 3%;
}

.my-3 {
	margin: 3% auto;
}

//widths
.w-100 {
	width: 100%;
}

.w-50 {
	width: 50%;
}

.text-center {
	text-align: $center;
}

.text-left {
	text-align: $left;
}

.text-right {
	text-align: $right;
}

.mx-2 {
	margin: 0 2%;
}

.p-12-40 {
	padding: 12px 40px !important;
}

.px-2 {
	padding: 2% 0;
}

.skiptocontent {
	position: absolute;
	top: auto;
	width: 1px;
	height: 1px;
	overflow: hidden;
	z-index: -999;

	&:active {
		color: #fff;
		background-color: #000;
		left: auto;
		top: auto;
		width: auto;
		height: auto;
		overflow: auto;
		padding: 5px;
		z-index: 999;
	}

	&:focus {
		color: #fff;
		background-color: #000;
		left: auto;
		top: auto;
		width: auto;
		height: auto;
		overflow: auto;
		padding: 5px;
		z-index: 999;
	}
}

.sr-only{
	position:absolute;
	left:-10000px;
	top:auto;
	width:1px;
	height:1px;
	overflow:hidden;
}

.kfc-loader {
    height: 4px;
    width: 100%;
    // position: relative;
    overflow: hidden;
    background-color: #ddd;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
}

.kfc-loader:before {
    display: block;
    position: absolute;
    content: "";
    left: -200px;
    width: 200px;
    height: 4px;
    background-color: #e4002b;
    animation: bar-loading 2s linear infinite;
}
@keyframes bar-loading {
	from {
	  left: -200px;
	  width: 30%;
	}
	50% {
	  width: 30%;
	}
	70% {
	  width: 70%;
	}
	80% {
	  left: 50%;
	}
	95% {
	  left: 120%;
	}
	to {
	  left: 100%;
	}
  }
.centeredLoader {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.pull-down-loader {
		&.small {
			width: 2rem;
			text-align: center;
		}

		&.medium {
			width: 3rem;
			text-align: center;
		}

		&.big {
			width: 4rem;
			text-align: center;
		}
	}
}

.appNotAvailable {
	text-align: center;
}

.user-refresh-warning-toast {
	text-align: center;
}

.scroll-lock-background {
  visibility: hidden;
  overflow: hidden;
}

.Toastify{
 & .Toastify__toast-container {
	@media #{$breakpoint-ipad-12-pro-portrait}{
	top: 47.4% !important;
	left: 50% !important;
	 }
	@media #{$breakpoint-ipad-pro-12-landscape}{
	top: 63.22% !important;
	}
}
}

.grid-container{
    width: 100%;
    max-width: 1180px;
    position: relative;
    margin-right: auto;
    margin-left: auto;
}
@media #{$breakpoint-above-tablet}{
    .grid-container{
        padding-left: 1.25rem;
        padding-right: 1.25rem;
    }
}
@media #{$breakpoint-below-desktop-above-tablet}{
    .grid-container{
        padding-left: 1.75rem;
        padding-right: 1.75rem;
    }
}
@media #{$breakpoint-desktop-small}{
    .grid-container{
        padding-left: 3.25rem;
        padding-right: 3.25rem;
    }
}
@media #{$breakpoint-below-tablet}{
	.grid-container{
		padding-left: 0rem;
		padding-right: 0rem;
	}
}

