.browse-menu-container{
    margin-bottom: 40px;
    margin-top: 66px !important;
    @media #{$breakpoint-below-tablet} {
        margin-top:40px !important;
        margin-left: 0px;
        padding: 0px 20px;
    }
    @media #{$mobile-only}{
        margin-top:50px !important;
    }
    @media #{$breakpoint-medium-desktop} {
        margin-left: 4.9%;
    }
    .browse-menu-header{
        >span{
            // flex-basis: 50%;
            width: 53%;
            padding-right: 30px !important; 
            @media #{$mobile-only}{
                width: 100% !important;
                padding-right: 15px !important;
            }
            @media #{$breakpoint-ipad-12-pro-portrait}{
                width: 91% !important;
            }
            @media #{$breakpoint-samsung-galaxy-tab-s7}{
                width: 76% !important;
            }
            @media #{$breakpoint-iphone-12-pro-portrait}{
                width: 73% !important;
            }
            @media #{$breakpoint-googlepixel-5}{
                width: 84% !important;
            }
            @media #{$breakpoint-samsung-s20}{
                width: 348px !important;
            }
            @media #{$breakpoint-iphone13}{
                width: 70% !important;
            }
            @media #{$breakpoint-above-tablet}{
                width: 91% !important;
            }
        }
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 38px;
        line-height: 43px;
        letter-spacing: 1px;
        text-transform: uppercase;
        color: #202124;
        display: flex;
        justify-content: center;
        margin-bottom: 40px;
        padding-top: 34px !important;
        align-items: center;
        @media #{$mobile-only}{
            padding-top: 20px !important;
        }
        @media #{$breakpoint-below-tablet}{
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 20px;
        }
        @media #{$breakpoint-samsung-galaxy-tab-s7} {
            @media (orientation: portrait) {
                font-size: 28px;
            }
        }
       

        .browse-menu-header-border{
            display: inline;
            border-top: 2px solid #E3E3E3;
            //width: 580px;
            width: 100%;
            margin-left: 0px !important;
            align-items: center;
            @media #{$mobile-only}{
                width: 50% !important;
            }
            @media #{$breakpoint-below-iphone5}{
                width: 25% !important;
            }
            @media #{$breakpoint-iphone13}{
                width: 30% !important;
            }
            @media #{$breakpoint-iphone-11-pro-portrait}{
                width: 37% !important;
            }
            /*
             @media #{$breakpoint-below-tablet}{
                margin-left: 15px;
                flex: 2;
             }
             @media #{$breakpoint-tablet-only}{
				width: 410px;
                flex: none;             
			}
            @media #{$breakpoint-ipad-12-pro-portrait} {
				@media (orientation: portrait) {
                    width: 340px;    
                }         
			}
            @media #{$breakpoint-ipad-pro-12-landscape} {
                @media (orientation: landscape) {
                    width: 537px;    
                }     
			}
            @media #{$breakpoint-above-large-desktop} {
				width: 43.2%;        
			}
            */
        }
    }
    .browse-menu-header-pos{
        // line-height: 168px !important; 
        // margin-bottom: -40px !important; 
       }  
    .browse-menu-item-container{
        >a{
            background-color: #f8f7f5;
        }
        display: grid;
        //grid-template-columns: 230px 230px 230px 230px;
        //grid-gap: 20px;
        grid-template-columns: 23.725% 23.725% 23.725% 23.725%;
        grid-gap: 20px;
        justify-content: center;
        
/*
        @media #{$breakpoint-ipad-12-pro-portrait} {
            @media (orientation: portrait) {
                grid-template-columns: 180px 180px 180px 180px;  
            }         
        }

        @media #{$breakpoint-above-large-desktop} {
            grid-template-columns: 15.2% 15.2% 15.2% 15.2%;     
        }
        */
        @media #{$breakpoint-below-tablet} {
             //grid-template-columns: 48% 48%;
             //grid-gap: 12px;
            grid-template-columns: 49% 49%;
            grid-gap: 0.75rem;
        }
        /*
        @media #{$breakpoint-samsung-galaxy-tab-s7} {
            @media (orientation: portrait) {
                grid-gap: 20px;
            }
        }
        */
        a {
            text-decoration: none;
        }
        a:hover {
             text-decoration: none;
        }
        .browse-lto{
            grid-column: 1 / span 2;
            grid-row: 1 / span 2;
            cursor: pointer;
            border-radius: 4px;
            //overflow: hidden;
            //height: 504px;
            @media #{$breakpoint-below-tablet} {
                height:auto;
            }
            @media #{$mobile-only} {
                width: 100%;
                height: auto;
            }
            .browse-lto-img{
                width: 100%;
                //height: 360px;
                @media #{$breakpoint-below-tablet} {
                   height:auto;
                   width: 100%;
                   bottom: 74px;
                }
                @media #{$mobile-only} {
                    height: auto;
                }
            }
            .browse-lto-name{
                //background-color: #F8F7F5;
                height:160px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: bold;
                font-size: 24px;
                //line-height: 24px;
                line-height: 10rem;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: #202124;
                @media #{$breakpoint-below-tablet}{
                    height:74px;
                }
                .browse-lto-heading{
                    //width: 400px;
                    text-align: center;
                    //padding-bottom: 1.36%;
                    align-items: center;
                    line-height: 4.625rem;
                    @media #{$breakpoint-below-tablet}{
                        font-size: 20px;
                        width: 256px;
                        line-height: 22px;
                    }
                    @media #{$breakpoint-samsung-galaxy-tab-s7} {
                        @media (orientation: portrait) {
                            font-size: 32px;
                        }
                    }
                }
            }
        }
        .browse-item{
             cursor: pointer;
             border-radius: 4px;
             //overflow: hidden;
             height: auto;
             @media #{$mobile-only} {
                 height: auto;
             }
           .browse-item-img{
                width: 100%;
                //height: 168px;
                bottom: 74px;
                @media #{$breakpoint-below-tablet}{
                    height:auto;
                    bottom: 56px;
                    width:100%;
                }
                @media #{$breakpoint-above-large-desktop} {
                    //height: 75%;
                }
                @media #{$mobile-only} {
                    height: auto;
                }
           }
           .browse-item-name{
               background-color: #F8F7F5;
                height: 74px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-family: $National2Condensed;
                font-style: normal;
                font-weight: bold;
                font-size: 18px;
                line-height: 25px;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: #202124;
                @media #{$breakpoint-below-tablet}{
                    height: 56px;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 1px;
                }
                @media #{$breakpoint-samsung-galaxy-tab-s7} {
                    @media (orientation: portrait) {
                        height: 120px;
                        font-size: 22px;
                    }
                }
                @media #{$breakpoint-ipad-12-pro-portrait} {
                    @media (orientation: portrait) {
                        height: 70px;
                    }         
                }
                @media #{$breakpoint-iphone-12-pro-landscape} {
                        height: 120px;     
                }
                .browse-item-heading {
                    text-align: center;
                }
           }
        }
        .browse-fingerlicking{
            cursor: pointer;
            grid-column-start: 2;
            grid-column-end: 5;
            background: #f8f7f5;
            @media #{$breakpoint-below-tablet} {
                height:auto;
                border-radius: 4px;
                grid-column-end: unset !important;
                grid-column-start: unset !important;
            }
            @media #{$breakpoint-above-medium-desktop}{
                margin-bottom: 0px !important;
                min-height: 279px;
            }
            @media #{$breakpoint-ipad-12-pro-portrait} {
                @media (orientation: portrait) {
                    margin-bottom: 0px !important;
                }
            }
            .catering-fingerlicking{
                grid-column-start: unset !important;
                grid-column-end: unset !important;
            }
            
            .browse-fingerlicking-desk{
                //display: block;
                //border-radius: 4px;
                height: 100%;
                width:100%;
                /*
                @media #{$breakpoint-above-large-desktop} {
                    width: 914px; 
                }
                */
                @media #{$breakpoint-ipad-12-pro-portrait} {
                    @media (orientation: portrait) {
                        background-color: #F8F7F5;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-family: $National2Condensed;
                        font-style: normal;
                        font-weight: bold;
                        font-size: 18px;
                        line-height: 25px;
                        letter-spacing: 0.5px;
                        text-transform: uppercase;
                        color: #202124;
                    }         
                }
        
            }
            @media #{$breakpoint-below-tablet} {
                .browse-fingerlicking-desk{
                    height: auto;
                    width: 100%;
                }
            }
        }
        .catrening-fingerdesk{
            @media #{$breakpoint-above-tablet}{
                width: 100% !important;
            }
            @media #{$breakpoint-tablet-only}{
                width: 100% !important;
            }
          }
    }
}