.global-custom-select-container {
	width: 100% !important;
	padding: 0 !important;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	font-family: $National2Regular;
	border-bottom: 1px solid #494949;
	font-size: 14px;

	span.label-custom-select {
		font-size: 12px;
	}
    .customSelect{
		background-color: transparent;
		border: transparent;
		text-align: left;
		color: $Brand-Black;
	}
	.customSelectOption{
		background-color: transparent;
		border: transparent;
		text-align: left;
		width: 100%;
		color: white;
		margin-top: 5px;
	}
	.dropdown-img {
		background: url("../../staticAssets/images/dropdown.svg") no-repeat 100% 100%;
		height: 15px;
		width: 15px;
		float: right;
		background-size: 15px;
		cursor: pointer;
		margin-top: 5px;
		border: none;

		&.upArrow {
			transform: rotate(180deg);
			margin-top: 10px;
		}
	}

	.global-custom-select-dropdown {
		display: none;
		position: absolute;
		background-color: #202124;
		min-width: 160px;
		box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
		z-index: 10;
		min-height: 100px;
		max-height: 200px;
		overflow: auto;
		margin-top: 3px;

		&.open {
			display: block;
		}

		.global-custom-select-dropdown-option {
			padding: 5px 0;
			text-decoration: none;
			display: block;

			&:hover {
				background-color: #f8f7f510;
			}

			span {
				color: #fff;
			}
		}
	}
}
