.deals-module-modal {
	.modal-content {
		@media #{$breakpoint-above-tablet} {
			width: 40% !important;
			height: 64% !important;
			left: 30% !important;
			top: 18% !important;
			
		}
		.modal-body{
			background:#fff
		}

		.modal-header {
			font-family: $National2Condensed;
			font-weight: 700;

			.header-title {
				@media #{$breakpoint-above-tablet} {
					margin-left: 3.5% !important;
				}

				@media #{$breakpoint-below-tablet} {
					font-family: $National2Medium !important;
					font-size: 14px !important;
					line-height: 24px !important;
					text-transform: capitalize !important;
				}
			}
		}

		.modal-body{
			background: #FFFFFF;
		}
	}

	.offers-coupon-mid-box {
		width: 80%;
		color: #202124;
		text-align: center;
		margin: 4.25% auto 2.1% auto;
		height: 90%;
		display: flex;
		flex-direction: column;
		// align-content: space-between;

		.offers-modal-mid-header {
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: 700;
			font-size: 24px;
			line-height: 32px;
			text-align: center;
			margin-bottom: 20px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $Brand-Black;
		}

		.offers-deals-product-valid {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			top: 19.79%;
			line-height: 24px;
			text-align: center;
			letter-spacing: -0.1px;
			color: $Dark-Gray;
			margin-bottom: 17px;
		}

		.offers-modal-mid-desc {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			text-align: center;
			letter-spacing: -0.1px;
			color: $Dark-Gray;
			height: 90%;
			overflow: hidden;
			// word-break: break-all;
			// display: -webkit-box;
			// -webkit-line-clamp: 4;
			// -webkit-box-orient: vertical;
		}

		.offers-modal-deals-footer {
			background: transparent;
			color: #202124;
			display: block;
			text-align: center;
			margin-top: auto;

			.redeem-btn {
				padding: 11px;
				width: 80%;
				border-radius: 200px;
				height: 44px;
				text-align: center;
				align-items: center;
				flex-direction: row;
				cursor: pointer;
				outline: none;
				margin: auto;
				font-style: normal;
				font-family: $National2Medium;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				background-color: transparent;
				border: 1.5px solid $Brand-Black;
				color: $Brand-Black;

				:disabled {
					border: 1px solid $Dark-Gray;
					color: $Dark-Gray;
					cursor: not-allowed;
				}

				.btn-text {
					margin: 0 auto;
				}
			}
		}
	}
}
