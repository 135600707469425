.general-menu-container {
	margin-left: 140px;
	margin-right: 80px;

	@media #{$breakpoint-below-tablet} {
		margin-left: 50px;
		margin-right: 50px;
	}

	@media #{$mobile-only} {
		margin-left: 20px;
		margin-right: 20px;
	}

	@media #{$breakpoint-above-desktop} {
		//margin-right: 230px;
		margin-left: 270px;
	}

	img {
		border-radius: 4px 4px 0 0;
	}

	& .browse-parent {
		@media #{breakpoint-between-tablet-desktop} {
			margin-right: 100px !important;
		}

		@media #{$breakpoint-above-destop-wide} {
			margin-right: 5% !important;
		}

		@media #{$mobile-only} {
			padding-left: 0px !important;
			padding-right: 0px !important;
		}
		& .browse {
			margin-right: 31px;
			//width: 27.5vw;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: 700;
			font-size: 38px;
			line-height: 43px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $Brand-Black;
			margin-top: 100px;
			margin-bottom: 55px;

			@media #{$breakpoint-below-tablet} {
				margin-right: 15px;
				font-size: 4.5vw;
				line-height: 22px;
				margin-top: 100px;
				margin-bottom: 28px;
			}

			@media #{$mobile-only} {
				white-space: nowrap;
				width: fit-content;
				font-size: 20px;
				margin-top: 60.09px;
			}
		}

		& .horizontal-line {
			border-top: 2px solid $Light-Gray;
			flex: 1;
			margin-top: 120px;
			margin-right: 9%;

			@media #{$breakpoint-below-desktop-above-tablet} {
				margin-right: 2%;
			}

			@media #{$breakpoint-below-tablet} {
				margin-top: 111px;
				margin-right: 2%;
			}

			@media #{$mobile-only} {
				width: 100%;
				margin-top: 70px;
				margin-right: 2%;
			}

			@media #{$breakpoint-above-desktop} {
				margin-right: 40px !important;
			}
		}
	}

	@media #{$breakpoint-below-tablet} {
		padding: 3%;
	}

	& .menu-cards.menu-card-full {
		@media #{$breakpoint-below-tablet} {
			width: 96% !important;
		}
	}

	& .menu-cards {
		/* width: 16.5vw;
		height: 16.5vw;
		margin: 2%;
		margin-right: 20px; */
		width: 17.8vw;
		height: 17.8vw;
		margin: 1%;
		display: inline-grid;
		background: $Mashed-Potato-Gray;
		position: relative;
		border-radius: 4px;
		cursor: pointer;
		text-decoration: none;

		@media #{$breakpoint-below-tablet} {
			height: calc(43vw * 1.09);
			width: 45.5%;
			margin: 2%;
		}

		@media #{$breakpoint-above-desktop} {
			/* margin-right: 8px;
			margin-left: 16px; */
			width: 17.5vw;
			height: 17.5vw;
		}

		& img {
			width: 17.8vw;
			height: calc(17.8vw / 1.35);

			@media #{$breakpoint-above-desktop} {
				width: 100%;
				height: calc(17.5vw / 1.35);
			}

			@media #{$breakpoint-below-tablet} {
				width: 100%;
				height: calc(43vw / 1.35);
			}
		}

		& .mobile-single-img {
			width: 29.5vw;
			margin: 0 auto;
		}
	}

	.more-arrow-img {
		height: 1vw !important;
		width: 2vw !important;
		margin-left: 7px;

		@media #{$breakpoint-below-tablet} {
			width: 4vw !important;
		}
	}

	& .single-lickin-card {
		& .single-img {
			width: 13.5vw;
			margin: 0 auto;
		}
	}

	& .full-lickin-card {
		width: 36vw;

		@media #{$breakpoint-below-tablet} {
			width: 100%;
		}

		& .double-img {
			margin: 0 auto;
		}
	}

	& .all-menu-text {
		font-family: $National2Regular;
		font-style: normal;
		font-weight: 600;
		font-size: 14px;
		line-height: 24px;
		align-items: $center;
		color: $Brand-Black;
		text-align: $center;
		letter-spacing: .5px;

		@media #{$breakpoint-below-tablet} {
			font-size: 2.5vw;
		}

		& .menu-arrow-span {
			vertical-align: middle;
		}
	}

	.menu-text {
		text-align: $center;
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: 700;
		font-size: 18px;
		line-height: 25px;
		letter-spacing: 0.5px;
		text-transform: $uppercase;
		color: $Brand-Black;
		bottom: 7%;
		// position: $pos-abs;
		@media #{$breakpoint-below-tablet} {
			font-size: 5vw;
			line-height: 5vw;
		}

		@media #{$mobile-only} {
			font-size: 16px;
			line-height: 20px;
		}
	}

	.first-of-five {
		width: 50%;
		height: 470px;
		background-color: $Mashed-Potato-Gray;
		margin: 1.4% 0;
		border-radius: 4px;

		& a {
			width: 100%;
		}

		& img {
			width: 100%;
			height: 370px;
		}

		& .menu-text {
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 0.5px;
			text-align: center;
			margin-top: 7%;
		}
	}

	.rest-of-five {
		width: 50%;
		height: 500px;
		margin: 1%;

		& .small-four {
			width: 48%;
			background-color: $Mashed-Potato-Gray;
			height: 230px;
			margin: 1%;
			border-radius: 4px;
			display: inline-grid;

			& .small-four-img {
				height: 170px;
			}
		}
	}

	.row-of-nine {
		display: $d-flex;

		& .first-two {
			display: flex;
			width: 50%;

			& .small-row {
				width: 48%;
				background-color: $Mashed-Potato-Gray;
				margin: 0 1% 0 0.7%;
				height: 230px;
				display: inline-grid;
				border-radius: 4px;
			}

			& img {
				height: 170px;
			}
		}

		& .last-two {
			display: $d-flex;
			width: 50%;

			& .small-row {
				width: 47%;
				margin: 0 1%;
				background: $Mashed-Potato-Gray;
				height: 230px;
				display: inline-grid;
				border-radius: 4px;
			}

			& img {
				height: 170px;
			}
		}
	}
}
