.nutrition-alleren-accordian-desktop {
    margin-bottom: 120px;
    @media #{$breakpoint-above-tablet} {
        margin-top: 60px;
    }

    .nutrition-alleren-accordian {
        padding-top: 50px;
        padding-bottom: 50px;
        border-bottom: 2px solid $Light-Gray;
        
        @media #{$mobile-only}  {
            border-bottom: 1px solid $Light-Gray;
        }
        .global-accordian-container {
            &:focus {
                outline: none !important;
            }

            .global-accordian-header-container {
                padding-left: 30px;
                padding-right: 44px;

                .global-accordian-header {
                    font-family: $National2Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    display: flex;
                    align-items: center;
                    color: $Brand-Black;
                }

                .global-accordian-arrow {
                    width: 15px;
                    height: 15px;
                }
            }

            .global-accordian-content {
                margin-top: 50px;
                font-family: $National2Regular;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: -0.1px;
                color: $Dark-Gray;
                padding-left: 30px;
                padding-right: 70px;

                .nutrition-alleren-accordian-answer > p > b {
                    font-family: $National2Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 30px;
                    color: $Brand-Black;
                }
                .nutrition-alleren-accordian-answer > ul > li > p > b {
                    font-family: $National2Medium;
                }

                .nutrition-alleren-accordian-answer > ul > li > p > a {
                    color: #202124;
                    font-family: $National2Medium;
                    font-weight: 400;
                    font-size: 16px;
                }

                .nutrition-alleren-accordian-answer > ul {
                    padding-left: 14px;
                }

                .nutrition-alleren-accordian-answer > p > a {
                    color: #202124;
                    font-family: $National2Medium;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                }
            }
        }
   
        @media #{$breakpoint-below-tablet} {
            padding: 30px 0px;
        }
     }

    .nutrition-alleren-accordian:first-child {
        border-top: 2px solid $Light-Gray;
        @media #{$mobile-only}  {
            border-top: 1px solid $Light-Gray;
        }
    }

    .nutrition-alleren-accordian-open {
        background-color: $Mashed-Potato-Gray;
    }
}