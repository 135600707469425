.privacy-desktop-container {
	display: flex;
	@media #{$breakpoint-above-tablet} {
		padding-left: 130px;
	    padding-right: 130px;
	}

	.privacy-section-left {
		min-width: 282px;

		.privacy-header-container {
			
			.privacy-icon {
				margin-bottom: 36px;
			}

			.privacy-header {
				margin-bottom: 30px;
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 38px;
				line-height: 43px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $Brand-Black;
			}

			.privacy-eff-date {
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: -0.1px;
				color: $Dark-Gray;
			}
		}
	}

	.privacy-section-right {
		margin-left: 120px;
		width: 780px;
		margin-top: 70px;

		@media #{$breakpoint-above-desktop} {
			margin-left: 288px;
			width: 1000px;
		}

		.privacy-description-desktop {
			margin-bottom: 60px;

			.privacy-description {
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: -0.1px;
				color: $Dark-Gray;
			}

			p:last-child {
				margin-top: 74px;
			}

			u::after {
				content: "\a";
				white-space: pre;
			}

			.privacy-description > p > u {
				font-family: $National2Regular;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				color: $Brand-Black;
				cursor: pointer;
			}
		}

		.privacy-questions-desktop {
			margin-bottom: 120px;

			.privacy-accordian {
				padding-top: 50px;
				padding-bottom: 50px;
				border-bottom: 2px solid $Light-Gray;

				.global-accordian-container {
					&:focus {
						outline: none !important;
					}

					.global-accordian-header-container {
						padding-left: 30px;
						padding-right: 44px;

						.global-accordian-header {
							font-family: $National2Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 24px;
							display: flex;
							align-items: center;
							color: $Brand-Black;
						}

						.global-accordian-arrow {
							width: 10px;
							height: 10px;
						}
					}

					.global-accordian-content {
						margin-top: 50px;
						font-family: $National2Regular;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 24px;
						letter-spacing: -0.1px;
						color: $Dark-Gray;
						padding-left: 30px;
						padding-right: 70px;

						.privacy-accordian-answer > p > b {
							font-family: $National2Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 30px;
							color: $Brand-Black;
						}
						.privacy-accordian-answer > ul > li > p > b {
                            font-family: $National2Medium;
                        }

						.privacy-accordian-answer > ul {
							padding-left: 14px;
						}
					}
				}
			}

			.privacy-accordian:first-child {
				border-top: 2px solid $Light-Gray;
			}

			.privacy-accordian-open {
				background-color: $Mashed-Potato-Gray;
			}
		}
	}
}

.privacy-mobile-container {
	.privacy-mobile-header {
		margin-bottom: 40px;
		padding-right: 20px;
		padding-left: 20px;

		.privacy-icon {
			margin-bottom: 20px;
			max-width: 40px;
		}

		.privacy-header {
			margin-bottom: 20px;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 32px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $Brand-Black;
		}

		.privacy-eff-date {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.1px;
			color: $Dark-Gray;
		}
	}

	.privacy-mobile-description {
		margin-bottom: 40px;
		font-family: $National2Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.1px;
		color: $Dark-Gray;
		padding-right: 20px;
		padding-left: 20px;
	}

	p:last-child {
		margin-top: 30px;
	}

	u::after {
		content: "\a";
		white-space: pre;
	}

	.privacy-description > p > u {
		font-family: $National2Regular;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
		color: $Brand-Black;
		cursor: pointer;
	}

	.privacy-mobile-questions {
		margin-bottom: 60px;

		.privacy-accordian {
			padding-top: 36px;
			padding-bottom: 36px;
			border-bottom: 2px solid $Light-Gray;
			padding-right: 20px;
			padding-left: 20px;

			.global-accordian-container {
				&:focus {
					outline: none !important;
				}

				.global-accordian-header-container {
					.global-accordian-header {
						font-family: $National2Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						display: flex;
						align-items: center;
						color: $Brand-Black;
					}
				}

				.global-accordian-content {
					margin-top: 24px;
					font-family: $National2Regular;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: -0.1px;
					color: $Dark-Gray;

					.privacy-accordian-answer > p > b {
						font-family: $National2Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 30px;
						color: $Brand-Black;
					}

					.privacy-accordian-answer > ul > li > p > b {
						font-family: $National2Medium;
					}

					.privacy-accordian-answer > ul {
						padding-left: 14px;
					}
				}
			}
		}

		.privacy-accordian:first-child {
			border-top: 2px solid $Light-Gray;
		}

		.privacy-accordian-open {
			background-color: $Mashed-Potato-Gray;
		}
	}
}

.privacy-policy-au {
	.privacy-desktop-container {
		display: flex;
		padding-left: 130px;
		padding-right: 130px;
		
		@media #{$breakpoint-ipad-12-pro-portrait} {
			padding-right: 56px;
		}
		
		// @media #{$breakpoint-above-desktop} {
		// 	padding-left: 143px;
		// }
	
		.privacy-section-left {
			min-width: 282px;
	
			.privacy-header-container {
				
				.privacy-icon {
					margin-bottom: 36px;
				}
	
				.privacy-header {
					margin-bottom: 30px;
					font-family: $National2Condensed;
					font-style: normal;
					font-weight: bold;
					font-size: 38px;
					line-height: 43px;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: $Brand-Black;
				}
	
				.privacy-eff-date {
					font-family: $National2Regular;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: -0.1px;
					color: $Dark-Gray;
				}
			}
		}
	
		.privacy-section-right {
			margin-left: 120px;
			width: 780px;
			margin-top: 23px;
			
			@media #{$breakpoint-ipad-12-pro-portrait} {
				margin-left: 26px;
			}
			@media #{$breakpoint-above-desktop} {
				margin-left: 288px;
				width: 1000px;
			}
	
			.privacy-description-desktop {
				margin-bottom: unset;
	
				.privacy-description {
					font-family: $National2Regular;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: -0.1px;
					color: $Dark-Gray;
				}
	
				p:last-child {
					margin-top: 74px;
				}
	
				u::after {
					content: "\a";
					white-space: pre;
				}
	
				.privacy-description > p > u {
					font-family: $National2Regular;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					color: $Brand-Black;
					cursor: pointer;
				}
			}
	
			.privacy-questions-desktop {
				margin-bottom: 120px;
	
				.privacy-accordian {
					padding-top: 50px;
					padding-bottom: 50px;
					border-bottom: 2px solid $Light-Gray;
	
					.global-accordian-container {
						&:focus {
							outline: none !important;
						}
	
						.global-accordian-header-container {
							padding-left: 30px;
							padding-right: 44px;
	
							.global-accordian-header {
								font-family: $National2Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 16px;
								line-height: 24px;
								display: flex;
								align-items: center;
								color: $Brand-Black;
							}
	
							.global-accordian-arrow {
								width: 15px;
								height: 15px;
							}

						}
	
						.global-accordian-content {
							margin-top: 50px;
							font-family: $National2Regular;
							font-style: normal;
							font-weight: normal;
							font-size: 16px;
							line-height: 24px;
							letter-spacing: -0.1px;
							color: $Dark-Gray;
							padding-left: 30px;
							padding-right: 70px;
	
							.privacy-accordian-answer > p > b {
								font-family: $National2Medium;
								font-style: normal;
								font-weight: 500;
								font-size: 18px;
								line-height: 30px;
								color: $Brand-Black;
							}
							.privacy-accordian-answer > ul > li > p > b {
								font-family: $National2Medium;
							}
	
							.privacy-accordian-answer > ul {
								padding-left: 14px;
							}
						}
					}
				}
	
				.privacy-accordian:first-child {
					border-top: unset ;
				}
	
				.privacy-accordian-open {
					background-color: $Mashed-Potato-Gray;
				}
			}
		}
	}
	
	.privacy-mobile-container {
		.global-accordian-container .global-accordian-header-container .global-accordian-arrow{
			width: 15px;
			height: 15px;
		}
		.privacy-mobile-header {
			margin-bottom: 40px;
			padding-right: 20px;
			padding-left: 20px;
	
			.privacy-icon {
				margin-bottom: 20px;
				max-width: 40px;
			}
	
			.privacy-header {
				margin-bottom: 20px;
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 24px;
				line-height: 32px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $Brand-Black;
			}
	
			.privacy-eff-date {
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: -0.1px;
				color: $Dark-Gray;
			}
		}
	
		.privacy-mobile-description {
			margin-bottom: 40px;
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.1px;
			color: $Dark-Gray;
			padding-right: 20px;
			padding-left: 20px;
		}
	
		p:last-child {
			margin-top: 30px;
		}
	
		u::after {
			content: "\a";
			white-space: pre;
		}
	
		.privacy-description > p > u {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			color: $Brand-Black;
			cursor: pointer;
		}
	
		.privacy-mobile-questions {
			margin-bottom: 60px;
	
			.privacy-accordian {
				padding-top: 36px;
				padding-bottom: 36px;
				border-bottom: 2px solid $Light-Gray;
				padding-right: 20px;
				padding-left: 20px;
	
				.global-accordian-container {
					&:focus {
						outline: none !important;
					}
	
					.global-accordian-header-container {
						.global-accordian-header {
							font-family: $National2Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 24px;
							display: flex;
							align-items: center;
							color: $Brand-Black;
						}
					}
	
					.global-accordian-content {
						margin-top: 24px;
						font-family: $National2Regular;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 24px;
						letter-spacing: -0.1px;
						color: $Dark-Gray;
	
						.privacy-accordian-answer > p > b {
							font-family: $National2Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 30px;
							color: $Brand-Black;
						}
	
						.privacy-accordian-answer > ul > li > p > b {
							font-family: $National2Medium;
						}
	
						.privacy-accordian-answer > ul {
							padding-left: 14px;
						}

						.privacy-accordian-answer > p {
							padding-left: 5px;
						}
					}
				}
			}
	
			.privacy-accordian:first-child {
				border-top: 2px solid $Light-Gray;
			}
	
			.privacy-accordian-open {
				background-color: $Mashed-Potato-Gray;
			}
		}
	}
}