
.error-alert-location-popup {
	width: 66%;
	margin: 0 auto;
	height: 170px;
	background: $white;
	border: 1px solid $Light-Gray;
	box-sizing: border-box;
	border-radius: 8px;
	// position: absolute;
	// top: 36%;
	display: flex;
	align-items: center;
	// justify-content: center;

	flex-direction: column;
	font-family: $National2Regular;
	z-index: 2;

	@media #{$breakpoint-below-tablet} {
		width: 100%;
		margin: 0 auto;
		padding: 0 2%;
		height: 160px;
		position: unset;
		// position: absolute;
		// top: 200px;
	}

	.location-alert-icon {
		margin-top: 23px;
	}

	.location-error-message {
		margin-top: 5px;
		font-size: 14px;
		font-weight: normal;
		// color: $Error-State;
	}

	.location-error-address {
		margin-top: 3px;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
		color: #ff2e00;
	}

	.location-error-button-section {
		margin-top: 20px;
		display: flex;

		// .location-warning-button {
		// 	display: flex;
		// 	justify-content: center;
		// 	align-items: center;
		// 	// width: 125px;
		// 	height: 32px;
		// 	padding: 5px 15px;
		// 	font-family: $National2Regular;
		// 	// color: $Brand-Black;
		// 	border: 1px solid $Dark-Gray;
		// 	box-sizing: border-box;
		// 	border-radius: 200px;
		// 	font-weight: 500;
		// 	font-size: 12px;
		// 	// background: $white;
		// }

		.ml {
			margin-left: 7px;
		}

		.mr {
			margin-right: 7px;
		}
		@media #{$breakpoint-below-iphone5} {
			margin-top: 0px !important;
			.button {
				font-size: 11px !important;
			}
			
		}
		@media #{$breakpoint-below-tablet} {
			.button {
				padding: 11px 5px !important;
			
			}
		
			.mr {
				margin-right: unset !important;
			}

			.ml {
				margin-left: 6px;
			}

			.retrybtn {
				padding: 11px 40px !important;
			}
		
		}
	}
}

.error-adjust {
	// width: 100% !important;
	margin: 0 0 0 0 !important;

	@media #{$breakpoint-below-tablet} {
		top: unset;
	}
}

.error-alert-location-popup-with-map-view {
	top: 55% !important;

	@media #{$breakpoint-below-tablet} {
		top: 275px !important;
	}
}
