.shared-modal-container {
	position: fixed;
	top: 0;
	left: 0;
	z-index: $z-index-max + 1;
	background-color: #202124a6;
	width: 100%;
	height: 100%;

	& .modal-content {
		position: absolute;
		width: 60%;
		height: 80%;
		left: 20%;
		top: 10%;
    
		@media #{$mobile-only}{
      border: none !important;
		}

		@media #{$breakpoint-below-tablet} {
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			// border-radius: 0;
		}

		@media #{$breakpoint-ipad-12-pro-portrait} {
			top: 25%;
			height: 51%;
		}

		& .button-container{
			justify-content: center;
		}

		& .modal-header, .modal-footer {
			padding: 2% 1%;
			background: #fff;
			border-radius: 5px 5px 0 0;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 24px;
			display: flex;
			align-items: $center;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			color: $Brand-Black;
			border-bottom: 1px solid #dbdbdb;

			@media #{$breakpoint-below-tablet} {
				// border-radius: 0;
				border-radius: 0;
				font-size: 20px;
				letter-spacing: 1px;
				line-height: 22px;
				background: unset;
			}

			& .header-title {
				font-family: $National2Condensed;
				margin: auto;
			}

			& .custom-modal-close {
				display: flex;
				background-color: transparent;
				position: absolute;
				right: 2%;
	      border: none;
				cursor: pointer;

				& img {
					width: 80%;
				}
				
			}


			& .custom-modal-close-focus:focus{
				outline: none !important;
			}
			& .custom-modal-back {
				display: flex;
				background-color: transparent;
				position: absolute;
				left: 2%;
				border: none;
				cursor: pointer;
				outline: none;

				& img {
					width: 80%;
				}
			}
		}

		& .modal-body {
			padding: 3%;
			height: 80%;
			//max-height: 86%;
			overflow-y: auto;
			overflow-x: hidden;
			position: relative;
			background: #f8f7f5;
			box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
			border-radius: 0 0 5px 5px;

			@media #{$breakpoint-below-tablet} {
				height: 100%;
				padding: 5% 3%;
				//max-height: 89%;
				overflow-y: auto;
			}
		}
	}

	&.storeclosedmodal {
		.modal-content {
			position: absolute;
			width: 40%;
			height: auto;
			min-height: 30%;
			left: 30%;
			top: 35%;

			@media #{$breakpoint-below-tablet} {
				position: absolute;
				width: 100% !important;
				height: 296px;
				left: 0%;
				top: auto;
				bottom: 0;
				border-top-left-radius: 1rem;
				border-top-right-radius: 1rem;
			}

			.modal-header, .modal-footer {
				border-bottom: none;
				padding: 1rem;

				.custom-modal-close {
					top: 1rem;

					@media #{$breakpoint-below-tablet} {
						// margin: 10px;
						z-index: 2;
					}
				}
			}

			.modal-body {
				display: flex;
				flex-direction: column;
				width: 80%;
				margin: auto;
				background-color: #fff;
				box-shadow: none;
				align-items: center;
				align-content: center;
				justify-content: space-evenly;

				@media #{$breakpoint-below-tablet} {
					background-color: unset;
					width: 100% !important;
				}

				h1 {
					font-family: $National2Condensed;
					font-weight: bold;
					font-size: 24px;
					line-height: 24px;
					text-align: center;
					letter-spacing: 0.5px;
					text-transform: uppercase;
				}

				p {
					font-size: 14px;
					line-height: 24px;
					text-align: center;
				}

				.action-buttons {
					.btn-light {
						background-color: #fff;
					}

					button {
						font-size: 14px;
						line-height: 24px;
						border-radius: 2rem;
						border: 2px solid #000;
						padding: 0.625rem 2rem;
					}
				}
			}

			@media #{$breakpoint-below-tablet} {
				.modal-header,.modal-footer {
					border-bottom: none;
					padding: 1rem;

					.custom-modal-close {
						top: 1rem;
					}
				}

				.modal-body {
					display: flex;
					flex-direction: column;
					width: 80%;
					margin: auto;
					background-color: #fff;
					box-shadow: none;
					align-items: center;
					align-content: center;
					justify-content: space-evenly;

					h1 {
						font-family: $National2Condensed;
						font-weight: bold;
						font-size: 24px;
						line-height: 24px;
						text-align: center;
						letter-spacing: 0.5px;
						text-transform: uppercase;
					}

					p {
						font-size: 14px;
						line-height: 24px;
						text-align: center;
					}

					.action-buttons {
						button {
							font-size: 14px;
							line-height: 24px;
							border-radius: 2rem;
							border: 2px solid #000;
							// padding: .375rem .75rem;
							margin-right: 0 !important;
						}
					}
				}
			}
		}
	}

	&.edit-occasion-modal {
		h1 {
			font-size: 24px;
		}

		.modal-content {
			width: 580px;
			left: 27%;

			@media #{$breakpoint-below-tablet} {
				width: 100%;
				left: 0;
			}

			.modal-header, .modal-footer {
				border-bottom: 0;
				background: #f8f7f5;
				margin-top: 12px;
			}

			.modal-body {
				font-family: $National2Condensed;
				font-size: 24px;
				font-weight: 700;
				padding: 20px 117px;

				@media #{$mobile-only} {
					width: 100%;
					left: 0;
					padding: 20px 29px;
				}

				h1 {
					text-align: center;
					text-transform: uppercase;
				}

				.edit-occassion-input-container {
					margin-top: 20px;
					position: relative;

					& > * {
						display: block;
						font-family: $National2Regular;
						width: 100%;
					}

					& label {
						font-size: 12px;
					}

					& input[name='occasion-name'] {
						background-color: #f8f7f5;
						font-size: 14px;
						border: none;
						border-bottom: 1px solid #202124;
					}

					.edit-input-clear-icon {
						cursor: pointer;
						width: 15px;
						height: 15px;
						position: absolute;
						right: 0;
						top: 28px;
					}
				}

				.errorMessageEditOccasion {
					color: red;
					font-family: $National2Regular;
					font-style: normal;
					font-weight: normal;
					font-size: 12px;
					line-height: 15px;
					letter-spacing: -0.1px;
					text-transform: capitalize;
					margin-top: 6px;
					margin-left: 0;
				}
			}
		}
	}

	&.menu-plp-no-category-modal {
		text-align: center;

		.modal-content {
			width: 40%;
			height: 30%;
			left: 30%;
			top: 30%;

			@media #{$breakpoint-below-tablet} {
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				text-align: center;
			}

			.modal-header, .modal-footer {
				border: none;
			}
		}
	}
}

.au-modal-container{
	.modal-content{
		@media #{$mobile-only}{
			border: none !important;
			border-radius: 0px !important;
		}
		.modal-header{
			@media #{$mobile-only}{
				padding: 2.5% 1%;
			}
			.header-title{
          font-size: 24px;
					font-weight: 700;
					line-height: 24px;
				@media #{$mobile-only}{
					font-size: 1.3rem;
					line-height: 22px;
					font-weight: 700;
					letter-spacing: 1px;
				}
			}
		}
	}
}