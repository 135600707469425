.giftCard-desktop-container {
	.giftcard-headline-container {
		height: 280px;
		background: #f8f7f5;
		margin-bottom: 78px;
		text-align: center;

		.giftcard-headline {
			text-align: center;
			letter-spacing: 2px;
			text-transform: uppercase;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 74px;
			line-height: 78px;
			position: absolute;
			width: 100%;
			height: 78px;
			padding-top: 55px;
			color: #202124;
		}

		.check-balance {
			position: relative;
			top: 55%;
			background: #f8f7f5;
		}

		.check-balance:hover {
			background: #202124;
			color:  #f8f7f5;
		}

		button.check-balance {
			&:focus-visible{
				color: #202124;

				&:hover{
					color: #f8f7f5;
				}
			}
		}
	}

	.main-container {
		width: 780px;
		margin-left: auto;
		margin-right: auto;

		.buy-card-text {
			top: 29%;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			color: #202124;
			padding-bottom: 10px;
			border-bottom: 2px solid #202124;
		}

		.card-image {
			margin: 70px 0;
			height: 348px;
			width: 100%;
		}

		.container-heading {
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			color: #202124;
		}

		.card-amount-container {
			background: #f8f7f5;
			height: 112px;
			display: flex;

			.card-amount-heading {
				padding: 44px 0 0 60px;
			}

			.amount-list {
				margin-left: 85px;
				display: flex;
				margin-top: 42px;

				.amount-block {
			        background-color: #f8f7f5;
					font-family: $National2Medium;
					font-size: 13px;
					line-height: 22px;
					padding: 5px;
					height: 32px;
					width: 58px;
					border-radius: 112px;
					border: solid 1px #202124;
					text-align: center;
					margin-right: 10px;
				}

				.amount-block-select {
					color: #f8f7f5;
					background: #202124;
				}				
			}
		}

		.infomation-container {
			height: 329px;
			display: flex;
			background: #f8f7f5;
			margin-top: 10px;

			.information-heading {
				padding: 44px 0 260px 60px;
			}

			.error-text {
				color: #ff2e00;
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 18px;
			}

			.infomation-input-container {
				margin-left: 83px;
				width: 488px;
				padding-top: 40px;

				.info-input {
					background: #f8f7f5;
					padding-bottom: 8px;
				}

				.mandatory-note{
					font-family: $National2Regular;
					font-size: 12px;
					font-style: normal;
					font-weight: 400;
					line-height: 12px;
					letter-spacing: 0px;
					text-align: right;
					color: #494949;
					margin: 0px;
					padding-top: 12px;
				}

				.info-label {
					color: #202124;
				}

				.field {
					margin: 0 !important;
				}
			}
		}

		.msg-container {
			height: 135px;
			display: flex;
			background: #f8f7f5;
			margin-top: 10px;

			.msg-inputbox {
				margin-left: 113px;
				font-family: $National2Regular;
				font-size: 14px;
				border: solid 1px #202124;
				height: 55px;
				width: 488px;
				margin-top: 40px;
				background: #f8f7f5;
			}

			.msg-heading {
				padding: 44px 0 260px 60px;
			}

			::placeholder {
				padding: 15px;
			}
		}
	}
	#terms-condition-button-container {
		display: flex;
		flex-direction: column-reverse;
	}
	.button-container {
		margin: 50px 160px 0 160px;
		width: 460px;
		margin-left: auto;
		margin-right: auto;
		

		.cancel-button {
			width: 210px;
			float: left;
		}

		.payment-button {
			float: right;
		}
	}

	.terms-condition-container {
		width: 980px;
		margin-left: auto;
		margin-right: auto;
		padding-top: 45px;
		margin-bottom: 60px;

		.terms-condition-data {
			font-family: $National2TestRegular;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 22px;
			color: #494949;
		}

		.terms-condition-title {
			font-family: $National2TestMedium;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 22px;
			color: #494949;
		}
	}
}

.giftCard-mobile-container {
	.giftcard-headline-container {
		height: 205px;
		background: #f8f7f5;
		margin-bottom: 4%;
		text-align: center;

		.giftcard-headline {
			letter-spacing: 1px;
			text-transform: uppercase;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 42px;
			line-height: 46px;
			position: absolute;
			width: 100%;
			height: 78px;
			padding-top: 55px;
			color: #202124;
		}

		.check-balance {
			position: relative;
			top: 55%;
			background: #f8f7f5;
		}

		.check-balance:hover {
			background: #202124;
		}
	}

	.main-container {
		width: 100%;
		margin-left: auto;
		margin-right: auto;

		.buy-card-text {
			top: 29%;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			color: #202124;
			padding-bottom: 18px;
			margin: 50px 20px 0 20px;
			border-bottom: 2px solid #202124;
		}

		.card-image {
			margin: 35px 0 55px 0;
			height: 180px;
			width: 100%;
		}

		.container-heading {
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			color: #202124;
		}

		.card-amount-container {
			background: #f8f7f5;
			height: 136px;

			.card-amount-heading {
				padding: 24px 0 0 20px;
			}

			.amount-list {
				display: flex;
				margin: 35px 20px 0;

				.amount-block {
					font-family: $National2Medium;
					font-size: 13px;
					line-height: 22px;
					padding: 5px;
					height: 32px;
					width: 58px;
					border-radius: 112px;
					border: solid 1px #202124;
					text-align: center;
					margin-right: 10px;
				}

				.amount-block-select {
					color: #f8f7f5;
					background: #202124;
				}
			}
		}

		.infomation-container {
			height: 329px;
			background: #f8f7f5;
			margin-top: 10px;

			.information-heading {
				padding: 24px 0 0 20px;
			}

			.error-text {
				color: #ff2e00;
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 18px;
			}

			.infomation-input-container {
				padding: 7px 20px 20px 20px;

				.info-input {
					background: #f8f7f5;
					padding-bottom: 4px;
				}

				.info-label {
					color: #202124;
				}

				.field {
					margin: 0 !important;
				}
			}
		}

		.msg-container {
			height: 172px;
			background: #f8f7f5;
			margin-top: 10px;
			padding: 24px 20px;

			.msg-inputbox {
				font-family: $National2Regular;
				font-size: 14px;
				border: solid 1px #202124;
				height: 78px;
				width: 100%;
				margin-top: 25px;
				background: #f8f7f5;
			}

			::placeholder {
				padding: 15px;
			}
		}
	}
	#terms-condition-button-container {
		display: flex;
		flex-direction: column-reverse;
	}
		.button-container {
			margin: 30px 20px 0 20px;
			width: 95%;
		    margin-left: auto;
		    margin-right: auto;

			.cancel-button {
				width: 45%;
			}

			.payment-button {
				width: 45%;
				padding: 0;
				float: right;
			}
      		.payment-button-in {
        		height: 44px;
      		}
		}

	.terms-condition-container {
		width: 90%;
		margin-left: auto;
		margin-right: auto;
		padding-top: 40px;
		margin-bottom: 40px;

		.terms-condition-data {
			font-family: $National2TestRegular;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 22px;
			color: #494949;
		}

		.terms-condition-title {
			font-family: $National2TestMedium;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 22px;
			color: #494949;
		}
	}
}
