@keyframes loading {
	0% {
		transform: translateX(-100%);
	}

	60% {
		transform: translateX(100%);
	}

	100% {
		transform: translateX(100%);
	}
}

.placeholder {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: 1.5rem;

	.card {
		background-color: #fff;
		height: auto;
		width: auto;
		overflow: hidden;
		margin: 12px;
		border-radius: 3px;
		box-shadow: 9px 17px 45px -29px rgba(0, 0, 0, 0.44);
	}

	.card-title {
		padding: 8px;
		font-size: 22px;
		font-weight: 700;
	}

	.card-title.loading {
		height: 1rem;
		margin: 1rem;
		border-radius: 3px;
		width: 85%;
	}

	.card-title.loading.shorter-m {
		width: 50%;
	}

	.card-title.loading.shorter-s {
		width: 25%;
	}

	.card-description {
		padding: 8px;
		font-size: 16px;
	}

	.card-description.loading {
		height: 2rem;
		border-radius: 3px;
	}

	.table {
		background-color: #fff;
		height: auto;
		overflow: hidden;
	}

	.table-content {

		font-size: 22px;
		font-weight: 700;
	}

	.table-content.loading {
		height: 1rem;
		margin: 1rem;
		border-radius: 3px;
		width: 92%;
	}
}

.loading {
	position: relative;
	background-color: #f5f5f5;
	overflow: hidden;

	&::after {
		display: block;
		content: "";
		position: absolute;
		width: 100%;
		height: 100%;
		transform: translateX(-100%);
		animation: 2s loading linear 0.5s infinite;
		background: linear-gradient(90deg, transparent, #ededed, transparent);
		bottom: 0;
		left: 0;
		right: 0;
		top: 0;
		z-index: 1;
	}
}