.language-module-modal {
	z-index: 111;

	& .modal-content {
		height: max-content;
		width: 26.53%;
		left: 36.81%;
		top: 19%;
		background: $white;
		box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
		border-radius: 8px;
		border: 0;

		@media #{$breakpoint-below-tablet} {
			left: 0;
			top: 0;
			height: 100%;
			width: 100%;
			border-radius: 0;
		}

		& .modal-header {
			padding: 3% 4%;
			//border-bottom: none;
			//text-transform: none;
			font-size: 18px;
			line-height: 25px;
			letter-spacing: 0.5px;

			@media #{$breakpoint-below-tablet} {
				padding: 0 1%;
				border-radius: 0;
				font-size: 20px;
				letter-spacing: 1px;
				line-height: 22px;
				border-bottom: 1px solid #dbdbdb;
				height: 40px;
				width: 100%;
			}

			& .header-title {
				padding: 2%;
				margin: 0;
				width: 90%;
				text-transform: uppercase;
				font-family: $National2Condensed;
				font-weight: bold;
				font-size: 18px;
				line-height: 25px;

				@media #{$breakpoint-below-tablet} {
					text-align: center;
					text-transform: none;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					font-family: $National2Medium;
					height: 32px;
					margin: auto;
					color: $Dark-Gray;
				}
			}

			& .custom-modal-close {
				border: none;
				background: $white;
				right: 5%;

				img {
					width: 16.1px;
					height: 15.85px;
					left: 2.23px;
					top: 2.25px;
				}
			}
		}

		& .modal-body {
			box-shadow: none;
			background: $white;
			border-radius: 8px;
			height: auto;
			padding: 0;

			& .form {
				overflow-y: auto;
				height: 33.45vh;

				@media #{$breakpoint-below-tablet} {
					height: 80vh;
				}
			}

			& .languageList {
				list-style: none;
				padding: 0;
				margin: 0;
				cursor: pointer;
				//margin-top: 2%;
				margin-top: 14px;

				@media #{$breakpoint-below-tablet} {
					margin-top: 0;
				}
			}

			& .wrapper {
				width: 100%;
				position: relative;
				//padding: 2% 0 2% 0;
				display: flex;
				align-items: center;
				font-family: $National2Medium;
				font-style: normal;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				color: $Brand-Black;
				height: 38px;

				@media #{$breakpoint-below-tablet} {
					//margin-top: 5%;
					padding: 0;
					height: 55px;
				}
			}

			& .languageConfirm {
				text-align: center;
				position: sticky;
				bottom: 0;
				background-color: $white;
				padding: 6% 7%;
				display: flex;
				justify-content: center;

				button {
					width: 300px;
					height: 44px;
				}
			}
		}
	}

	input[type="radio"] {
		margin: 0 4% 0 4%;
		appearance: none;
		height: 16px;
		width: 16px;
		background-color: transparent;
		border-radius: 100%;
		cursor: pointer;
		outline: none;
		border: 2px solid $Light-Gray;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	input[type="radio"]::after {
		content: "•";
		color: $Brand-Black;
		font-weight: 900;
		font-size: 2em;
		display: none;
		margin: 0.3;
		//margin: 1.5px 1.5px 0.3px 1.5px;
	}

	input[type="radio"]:checked {
		background-color: inherit;
	}

	input[type="radio"]:checked::after {
		display: block;
	}

	@media (hover: hover) and (pointer: fine) {
		li:hover {
			background-color: $Mashed-Potato-Gray;
		}
	}

	.wrapperactive {
		@media #{$breakpoint-below-tablet} {
			background-color: $Mashed-Potato-Gray;
		}
	}
}
