.localization-change-modal {
    .modal-content {
      height: 456px;
      width: 580px;
      position: absolute;
      float: left;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .modal-header {
        border-bottom: none;
        .custom-modal-close {
          top: 5%;
          z-index: 1;
        }
        .custom-modal-close:focus-visible {
          outline: none;
        }
      }
      .modal-body {
        background: $white;
        box-shadow: none;
      }
    }
  
    .welcome-container {
      .welcome-back-heading {
        font-family: $National2Condensed;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
        font-style: normal;
        padding: 15px 100px 20px 100px;
      }
  
      .order-details-section {
        background: $Mashed-Potato-Gray;
        border-radius: 8px;
        width: 420px;
        height: 112px;
        margin-left: 65px;
        padding: 10px 65px 10px 22px;
        font-size: 14px;
        line-height: 24px;
        font-style: normal;
        overflow: hidden;
        .order-type {
          font-weight: 500;
          text-transform: capitalize;
        }
      }
      .update-text{
        margin-top: 30px;
        text-align: center;
        font-size: 14px;
      }
      .buttonItems {
        margin-top: 30px;
        .closebtn-cls {
          margin-right: 15px;
          width: 190px;
          padding: 10px 25px;
        }
        .save-button {
          width: 190px;
        }
      }
    }
}
  
.localization-change-mobile-modal {
    .modal-content {
      height: 386px;
      bottom: 0;
      top: 48%;
      right: 0;
      .modal-header {
        border-bottom: none;
        .custom-modal-close {
          top: 5%;
          z-index: 1;
        }
        .custom-modal-close:focus-visible {
          outline: none;
        }
      }
      .modal-body {
        background: $white;
        box-shadow: none;
      }
    }
    .welcome-container {
      .welcome-back-heading {
        font-family: $National2Condensed;
        font-weight: bold;
        font-size: 24px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
        font-style: normal;
      }
  
      .order-details-section {
        background: $Mashed-Potato-Gray;
        border-radius: 8px;
        width: 336px;
        height: 106px;
        padding: 10px 65px 10px 22px;
        font-size: 14px;
        line-height: 24px;
        font-style: normal;
        overflow: hidden;
        .order-type {
          font-weight: 500;
          text-transform: capitalize;
        }
      }
      .update-text{
        margin-top: 30px;
        text-align: center;
        font-size: 14px;
      }
  
      .buttonItems {
        margin-top: 30px;
        .closebtn-cls, .save-button {
          width: 176px;
          font-size: 12px;
          padding: 10px 10px;
        }
      }
    }
}
  