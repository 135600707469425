.scheduleOrderContainer {
	display: flex;
	justify-content: center;
	height: 493px;
	width: 100%;
	border-radius: 0;
	background: $Brand-Black;
	border-bottom: 1px solid $Dark-Gray;

	.TitleOrder {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 44px;
		line-height: 48px;
		text-align: center;
		letter-spacing: 1.5px;
		text-transform: uppercase;
		color: #fff;
		margin-bottom: 80px;

		@media #{$breakpoint-below-tablet} {
			font-size: 24px;
			line-height: 32px;
			letter-spacing: 1px;
		}
	}

	.StripeImg {
		text-align: center;
		margin-bottom: 10px;
	}

	.dateAndTime {
		width: 780px;
		height: 244px;
		background: #fff;
		position: relative;
	}

	.dateAndTime::before {
		content: '';
		position: absolute;
		height: 72px;
		width: 35px;
		right: 0%;
		left: 97%;
		bottom: 38%;
		border-radius: 100px 0 0 100px;
		background: $Brand-Black;
	}

	.dateAndTime::after {
		content: '';
		position: absolute;
		height: 72px;
		width: 35px;
		right: 0%;
		left: -1%;
		bottom: 37%;
		border-radius: 0 100px 100px 0;
		background: $Brand-Black;
	}

	.dateAndTimeMob {
		width: 335px;
		height: 284px;
		background: #fff;
		position: relative;
	}

	.dateAndTimeMob::before {
		content: '';
		position: absolute;
		height: 25px;
		width: 56px;
		bottom: 94%;
		left: 42%;
		border-radius: 0 0 100px 100px;
		background: $Brand-Black;
	}

	.dateAndTimeMob::after {
		content: '';
		position: absolute;
		height: 25px;
		width: 56px;
		top: 94%;
		left: 42%;
		border-radius: 100px 100px 0 0;
		background: $Brand-Black;
	}

	.scheduled {
		height: 24px;
		background: $Brand-Black;
		border-radius: 10px;
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 20px;
		text-align: center;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #fff;
		padding-right: 6px;
		padding-left: 13px;
	}

	.scheduledMob {
		height: 24px;
		width: 102px;
		background: $Brand-Black;
		border-radius: 10px;
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 15px;
		line-height: 20px;
		text-align: center;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #fff;
		margin-left: 34%;
		margin-top: 17px;
		padding-top: 3px;
	}

	.orderNum {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		text-align: center;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		color: #494949;
		margin-left: 10px;
	}

	.orderNumMob {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 16px;
		line-height: 20px;
		text-align: center;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #494949;
		margin-top: 12px;
	}

	.contents {
		margin-left: 60px;
		padding-top: 59px;
		margin-bottom: 16px;
	}

	.contentsMob {
		text-align: center;
		padding-top: 30px;
	}

	.dateAndButton {
		margin-left: 60px;
		margin-right: 51px;
		display: flex;
		justify-content: space-between;
	}

	.dateAndButtonMob {
		text-align: center;
	}

	.day {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 38px;
		line-height: 43px;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: $Brand-Black;
	}

	.dayMob {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 22px;
		text-align: center;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: $Brand-Black;
	}

	.time {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 38px;
		line-height: 43px;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #e4002b;
	}

	.timeMob {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 22px;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: #e4002b;
	}

	.dayandTimeMob {
		margin-bottom: 30px;
		margin-top: 10px;
	}
}
