.ab-feed.ab-show.ab-effect-slide {
  display: none;
}
.msg-text {
   text-transform: uppercase;
}
@media #{$breakpoint-below-tablet} {
   .account-message-wrapper {
      padding: 0 14px;
   }
}
#message-wrapper {
  .ab-feed .ab-feed-buttons-wrapper {
    display: none;
  }

  .ab-feed-buttons-wrapper {
    display: none;
 }
 .ab-feed-body {
    border: 0px !important;
    padding: 0px !important;
 }
 
 .ab-feed .ab-card .ab-card-body {
    display: flex;
    flex-direction: column;
 }
 .ab-feed.ab-show.ab-effect-slide {
    display: block;
    margin-bottom: 24px;
    background-color: white;
    width: 100%;
    box-shadow: none;
 }
 .ab-card.ab-effect-card.ab-classic-card.with-image {
    border: 0;
    box-shadow: none;
    background-color: #f8f7f5;
    display: flex;
    align-items: center;
 }
 .ab-card.ab-effect-card.ab-captioned-image.with-image {
    background-color: #f8f7f5;
    border: 0;
    box-shadow: none;
    display: flex;
    align-items: center;
 }
 .ab-feed .ab-card .ab-pinned-indicator {
    border-color: transparent #e4002b transparent transparent !important;
 }
 .ab-feed .ab-card .ab-unread-indicator {
    background-color: #e4002b;
 }
 .ab-feed .ab-card .ab-image-area {
    width: 20% !important;
 }
 
 @media (max-width: 745px) {
    .ab-feed .ab-card .ab-image-area {
       width: 30% !important;
    }
 }
 
 .ab-feed .ab-card.ab-classic-card.with-image .ab-image-area {
    height: auto !important;
    padding: inherit !important;
    position: inherit !important;
 }
 .ab-feed .ab-card .ab-title a {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
 }
 
 .ab-feed .ab-description {
    padding-top: 10px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
 }
 
 @media (max-width: 745px) {
    .ab-feed .ab-description {
       flex-direction: column;
    }
 }
 
 .ab-feed .ab-card.ab-captioned-image.with-image .ab-title {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
 }
 
 .ab-feed .ab-card.ab-classic-card.with-image .ab-card-body {
    padding-left: 0px !important;
 }
 
 .ab-feed .ab-card .ab-url-area a {
    float: none;
    border: 1.5px solid #202124;
    border-radius: 200px;
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    padding: 10px 40px;
    color: #202124;
 }
 
}