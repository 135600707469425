.alert-model-view-root {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.alert-action-container {
	margin-top: 40px;
	width: 100%;
	justify-content: space-evenly;
	max-width: 25rem;
}

.alet-modal {
	z-index: $z-index-max + 1;
	width: 100%;

	&.store-time-msg,
	&.plp-warning-msg {
		z-index: 9999;
	}

	& .modal-content {
		width: 40.32%;
		height: 252px;
		left: 30%;
		top: 30%;
		background: $white;
		box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
		border-radius: 10px;

		@media #{$breakpoint-below-tablet} {
			width: 100%;
			bottom: 0;
			left: 0;
			top: unset;
			border-radius: 0;
			border-top-left-radius: 10px;
			border-top-right-radius: 10px;
		}

		& .modal-header {
			border-bottom: none;

			@media #{$breakpoint-below-tablet} {
				// padding:6%;
				border-radius: 0;
				font-size: 20px;
				letter-spacing: 1px;
				line-height: 22px;
				border-top-left-radius: 10px;
				border-top-right-radius: 10px;
			}
		}

		& .modal-body {
			box-shadow: none;
			background: $white;

			@media #{$breakpoint-below-tablet} {
				padding: 10px 20px;
			}

			.alert-title {
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 20px;
				line-height: 24px;
				text-align: center;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				color: #202124;
			}

			.alert-content {
				padding-top: 10px;
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				text-align: $center;
				letter-spacing: -0.1px;
				color: $Brand-Black;
				width: 237px;
				&.menuPlp {
					width: 230px;
				}
			}

			.alert-cancel {
				background-color: white;
				margin-right: 15px;

				&:hover {
					background-color: $Brand-Black;
					color: $white;
				}
				&:focus-visible {
					background-color: $Brand-Black;
					color: $white;
				}
			}
			/*
			.alert-confirm
			{   width: 156px;
				height: 44px;
				white-space: nowrap;
				line-height:24px;
			}
			.alert-cancel {
				width: 156px;
				height: 44px;
				white-space: nowrap;	
			}
             */
			.alert-red-text {
				color: $Brand-Red;
			}

			.alert-red-text::after {
				content: '\a';
				white-space: pre;
			}
			
			.alert-border-color {
				border: 1px solid #202124 !important ; 
				font-weight: 500;
			}
		}
	}

	&.location-confirm-modal{
		& .modal-content{
			height: 360px;
		}
	}
}
.timebased-alert {
	.modal-content {
		@media #{$breakpoint-below-and-above-medium-desktop}{
			width: 50% !important;
			height: 90% !important;
			left: 25% !important;
			top: 0% !important;
			padding-top: 0% !important;

		@media #{$breakpoint-above-medium-tablet} {
			width: 50% !important;
			height: 75% !important;
			left: 25% !important;
			top: 13% !important;
		}

		@media #{$breakpoint-medium-desktop} {
			width: 50% !important;
			height: 90% !important;
			left: 25% !important;
			top: 6% !important;
		}

		@media #{$breakpoint-ipad-12-pro-portrait} {
			width: 68% !important;
			height: 41% !important;
			left: 18% !important;
			top: 12% !important;

		}

		@media #{$mobile-only} {
			height: 100% !important;
			width: 100% !important;
			left: 0px !important;
			top: 0px !important;
		}

		.modal-header {
			@media #{$breakpoint-above-medium-tablet} {
				padding: 15px !important;
			}

			@media #{$mobile-only} {
				padding-top: 18px !important;
				padding-bottom: 8px !important;
			}
		}

		.modal-body {
			padding: 0% !important;

			@media #{$mobile-only} {
				display: flex;
			}

			.alert-title {
				@media #{$breakpoint-above-medium-tablet} {
					width: 85% !important;
					line-height: 32px !important;
					font-family: $National2Condensed;
					font-style: normal;
					font-weight: 700;
					font-size: 32px;
					text-align: center;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: #202124;
				}
				@media #{$breakpoint-ipad-12-pro-portrait} {
					width: 78%;
				}

				@media #{$mobile-only} {
					width: 378px !important;
					height: 72px;
					font-family: $National2Condensed;
					font-style: normal !important;
					font-weight: 700 !important;
					font-size: 36px !important;
					line-height: 35px !important;
					text-align: center;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: #202124;
				}
			}

			.alert-content {
				@media #{$breakpoint-above-medium-tablet} {
					width: 58% !important;
					font-family: $National2Regular;
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 150%;
					text-align: center;
					letter-spacing: -0.1px;
					color: #626365;
					// padding-top: 9px !important;
				}

				@media #{$mobile-only} {
					padding-top: 10%;
					width: 372px !important;
					height: 63px !important;
					font-family: $National2Regular;
					font-style: normal;
					font-weight: 400;
					font-size: 16px;
					line-height: 150%;
					text-align: center;
					letter-spacing: -0.1px;
					color: #626365;
				}
		}
			}
			img{
				@media #{$breakpoint-above-medium-tablet} {
				width: 30%;
				}
				@media #{$mobile-only} {
					padding-top: 10%;
					width: 50%;
				}
			}

			.alert-action-container {
				margin-top: 11px !important;
			}
		}
	}
}
.timebased-alert-notification {
	.modal-content {
		height: auto !important;
		top: 10% !important;
		@media #{$mobile-only} {
			height: auto !important;
			top: 0% !important;
		}
		.modal-body {
			@media #{$mobile-only} {
				padding: 0% 10% !important;
			}
			@media #{$breakpoint-below-and-above-medium-desktop} {
				padding-bottom: 2% !important;
			}
		}
	}
}

.timebased-alert-popup{
    .modal-content {
        height: 50% !important;
        .modal-body {
            @media #{$mobile-only} {
                padding: 5% 10% !important;
            }  
        }
    }  
}