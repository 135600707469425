// font faces goes here
@font-face {
	font-family: 'National 2 Regular';
	src: url(../styles/staticAssets/fonts/National2-Regular.otf) format('truetype');
}

@font-face {
	font-family: 'HelveticaNeue-Bold';
	src: url(../styles/staticAssets/fonts/HelveticaNeue-Bold.otf) format('truetype');
}

@font-face {
	font-family: 'National 2 Condensed';
	src: url(../styles/staticAssets/fonts/National2Condensed-Bold.otf) format('truetype');
}

@font-face {
	font-family: 'National2CondensedWeb-Medium';
	src: url(../styles/staticAssets/fonts/National2CondensedWeb-Medium.woff) format('truetype');
}

@font-face {
	font-family: 'National2Test-Black';
	src: url(../styles/staticAssets/fonts/National2Test-Black.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-BlackItalic';
	src: url(../styles/staticAssets/fonts/National2Test-BlackItalic.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-Bold';
	src: url(../styles/staticAssets/fonts/National2Test-Bold.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-BoldItalic';
	src: url(../styles/staticAssets/fonts/National2Test-BoldItalic.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-Extrabold';
	src: url(../styles/staticAssets/fonts/National2Test-Extrabold.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-ExtraboldItalic';
	src: url(../styles/staticAssets/fonts/National2Test-ExtraboldItalic.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-Extralight';
	src: url(../styles/staticAssets/fonts/National2Test-Extralight.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-ExtralightItalic';
	src: url(../styles/staticAssets/fonts/National2Test-ExtralightItalic.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-Light';
	src: url(../styles/staticAssets/fonts/National2Test-Light.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-LightItalic';
	src: url(../styles/staticAssets/fonts/National2Test-LightItalic.otf) format('truetype');
}

@font-face {
	font-family: 'National2-Medium';
	src: url(../styles/staticAssets/fonts/National2-Medium.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-Medium';
	src: url(../styles/staticAssets/fonts/National2Test-Medium.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-MediumItalic';
	src: url(../styles/staticAssets/fonts/National2Test-MediumItalic.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-Regular';
	src: url(../styles/staticAssets/fonts/National2Test-Regular.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-RegularItalic';
	src: url(../styles/staticAssets/fonts/National2Test-RegularItalic.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-Thin';
	src: url(../styles/staticAssets/fonts/National2Test-Thin.otf) format('truetype');
}

@font-face {
	font-family: 'National2Test-ThinItalic';
	src: url(../styles/staticAssets/fonts/National2Test-ThinItalic.otf) format('truetype');
}
