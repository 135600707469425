#OrderModalPopup {
	margin-left: 10px;
	font-family: $National2Condensed;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: $Brand-Black;

	@media #{$breakpoint-below-tablet} {
		margin-left: 152px;
		font-family: $National2Condensed;
		font-weight: 500;
		font-size: 14px;
		line-height: 24px;
	}
}

.order-modal {
	@media #{$breakpoint-below-tablet} {
		position: absolute;
		width: 100%;
		z-index: 9;
		height: 100%;
		left: 0;
		top: 0;
	}

	& .modal-content {
		position: absolute;
		width: 580px;
		height: auto;
		left: 33%;
		// top: 10%;
		// bottom: 10%;

		@media #{$breakpoint-below-tablet} {
			position: absolute;
			width: 100%;
			height: auto;
			left: 0%;
			//   top: 0%;
			//   bottom:0%
		}

		& .modal-body {
			padding-right: 40px;
			padding-left: 35px;

			@media #{$breakpoint-below-tablet} {
				padding-right: 11px;
				padding-left: 7px;
			}
		}

		& .modal-header {
			@media #{$breakpoint-below-tablet} {
				width: 100%;
				height: auto;
			}
		}
	}
}

.heading {
	display: flex;
	justify-content: space-between;
	padding-bottom: 20px;
	border-bottom: 2px solid $Brand-Black;

	.yourOrders {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 24px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		color: $Brand-Black;

		@media #{$breakpoint-below-tablet} {
			font-size: 20px;
			line-height: 22px;
			width: 50%;
		}
	}

	.idNum {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		text-align: center;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		color: $Brand-Black;
	}
}

.CompletedDate {
	display: grid;
	grid-column-gap: 0;
	grid-template-columns: 27% auto;
	padding: 10px;
	margin-bottom: 10px;

	@media #{$breakpoint-below-tablet} {
		grid-template-columns: 36% auto;
		padding: 9px;
		margin-bottom: 0;
	}
}

.completed {
	font-family: $National2Condensed;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: $Brand-Black;
	text-align: left;
}

.dateCompleted {
	font-family: $National2Test;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: #494949;
	text-align: left;
}

.KfcphoneNum {
	font-family: $National2Test;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	text-decoration-line: underline;
	color: $Brand-Black;
	margin-right: 61px;
}

.KfcphoneIcon {
	margin-right: 10px;
}

.detailsAccordian {
	margin-bottom: 30px;

	.global-accordian-container .global-accordian-header-container {
		height: 60px;
		padding: 19px;
		padding-left: 6px;
	}

	.global-accordian-container {
		border-top: 2px solid black;
		border-bottom: 2px solid black;
	}

	.global-accordian-container .global-accordian-header-container .global-accordian-arrow {
		width: 16px;
		height: 12px;
	}
}

.number {
	background: #e3e3e3;
	border-radius: 4px;
	margin-right: 0px;
	padding: 4px 8px;
	margin-left: 5px;
	font-size: 12px;
    line-height: 22px;
    color: #202124;
    font-weight: 400;
	font-family: $National2Medium;
}

.foodName {
	font-family: $National2Medium;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: $Brand-Black;
	&.subDesc {
		font-family: $National2Regular !important;
		font-size: 12px !important;
		color: $Dark-Gray;
		font-weight: 400;
		line-height: 22px;
	}
}

.ItemPrice {
	text-align: right;
}

.billDescription {
	display: flex;
	justify-content: space-between;
	margin-bottom: 15px;
}

.price {
	display: flex;
	justify-content: space-between;
	margin-top: 30px;
}

.priceList {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
}

.fonts {
	font-family: $National2Test;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	letter-spacing: -0.1px;
	color: #494949;
	text-transform: capitalize;
}

.amount {
	font-family: $National2Test;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	text-align: right;
	color: $Brand-Black;
}

.total {
	font-family: $National2Condensed;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 25px;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: $Brand-Black;
}

.totalAmount {
	display: flex;
	justify-content: space-between;
	margin-top: 19.82px;
	margin-bottom: 40px;
}
