.not-found-container {
	display: flex;
	flex-direction: row;
	column-gap: 5.138%;
	height: 552px;
	align-items: center;
	justify-content: center;

	@media #{$breakpoint-below-tablet} {
		flex-direction: column-reverse;
		height: 100%;
	}

	.not-found-kfc-bucket-part {
		width: 29.583%;
		height: 75.905%;

		@media #{$breakpoint-below-tablet} {
			width: unset;
			height: unset;
			margin: 30px 0 60px 0;
		}
	}

	.not-found-error-part {
		@media #{$breakpoint-below-tablet} {
			display: flex;
			flex-direction: column;
		}
	}

	.not-found-error-text {
		font-family: $National2Condensed;
		font-style: normal;
		font-size: 64px;
		line-height: 76px;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin: 0;

		&.red-text {
			color: $Brand-Red;

			@media #{$breakpoint-below-tablet} {
				margin-top: 30px;
			}
		}

		@media #{$breakpoint-below-tablet} {
			font-size: 36px;
			line-height: 36px;
			text-align: center;
			text-transform: uppercase;
		}
	}

	.take-me-home-button {
		margin-top: 40px;
		font-family: $National2Regular;
		color: $white;
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		background: $Brand-Black;
		border-radius: 200px;
		line-height: 24px;
		border: none;
		padding: 0.625rem 2.5rem;

		@media #{$breakpoint-below-tablet} {
			margin-top: 30px;
			align-self: center;
		}
	}
}

.account-remove-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// height: 552px;
	min-height: 60vh;

	@media #{$breakpoint-below-tablet} {
		height: 90%;
	}

	.account-remove-confirm-icon {
		@media #{$breakpoint-below-tablet} {
			// margin: 60px auto;
			margin: 45px auto;
		}
	}

	.main-text-account-remove {
		width: 40%;
		font-family: $National2Condensed;
		font-weight: 700;
		font-style: normal;
		font-size: 6.55vh;
		// line-height: 76px;
		line-height: initial;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin: 0;
		text-align: center;
		white-space: pre-wrap;

		@media #{$breakpoint-below-tablet} {
			width: 90%;
			font-size: 36px;
			line-height: 36px;
			text-align: center;
			text-transform: uppercase;
			margin-bottom: 20px;
		}
		@media #{$breakpoint-ipad-12-pro-portrait} {
			width: 100%;
		}
	}

	.account-remove-buttons {
		.sign-up-account-remove {
			margin-right: 7.5px;
			margin-top: 40px;
			font-family: $National2Regular;
			color: $Brand-Black;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			background: none;
			border-radius: 200px;
			line-height: 24px;
			border: 1.5px solid $Brand-Black;
			padding: 0.625rem 3.8rem;

			@media #{$breakpoint-below-tablet} {
				margin: 0 0 80px 0;
			}
		}

		.sign-up-account-remove-login-fail{
			@media #{$breakpoint-below-tablet} {
				margin: 40px 0 80px 0;
			}
		}

		.take-me-home-account-remove {
			margin-left: 7.5px;
			margin-top: 40px;
			font-family: $National2Regular;
			color: $white;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			background: $Brand-Black;
			border-radius: 200px;
			line-height: 24px;
			border: 1.5px solid $Brand-Black;
			padding: 0.625rem 2.5rem;

			@media #{$breakpoint-below-tablet} {
				margin: 30px 0 20px 0;
			}
		}

		@media #{$breakpoint-below-tablet} {
			display: flex;
			flex-direction: column-reverse;
		}
	}
}

.thankyou-success-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	// height: 552px;
	min-height: 60vh;
	width: 90%;
    margin: 60px auto;

	@media #{$breakpoint-below-tablet} {
		height: 90%;
		margin: 0 auto;
	}

	.thankyou-confirm-icon {
		@media #{$breakpoint-below-tablet} {
			// margin: 60px auto;
			margin: 45px auto;
		}
	}
	.ty-received-text{
		font-family: $National2Regular;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.1px;
		color: #494949;
		text-align: center;

		@media #{$breakpoint-below-tablet} {
			margin-top: 20px;
		}
	}
	.thankyou-received-text{
		width: 100%;
		position: absolute;
		left: 32.64%;
		right: 32.64%;
		font-family: $National2Regular;
		font-style: normal;
		font-weight: 400;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.1px;
		color: #494949;
		margin-top: 80px;
		@media #{$breakpoint-below-tablet} {
			position: absolute;
			width: 67%;
			left: 17.6%;
			right: 13.6%;
			top: 18.45%;
			font-family: $National2Regular;
			font-style: normal;
			font-weight: 400;
			font-size: 16px;
			line-height: 24px;
			text-align: center;
			letter-spacing: -0.1px;	
			color: #494949;
		}
	}
	

	.main-text-thankyou {
		// width: 40%;
		font-family: $National2Condensed;
		font-weight: 700;
		font-style: normal;
		font-size: 4.75rem;
		line-height: initial;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin: 0;
		margin-bottom: 10px;
		text-align: center;
		white-space: pre-wrap;

		@media #{$breakpoint-below-tablet} {
			width: 90%;
			font-size: 36px;
			line-height: 1px;
			text-align: center;
			text-transform: uppercase;
			margin-bottom: 20px;
		}
		@media #{$breakpoint-ipad-12-pro-portrait} {
			width: 100%;
		}
	}

	.thankyou-confirm-button {
		margin-top: '50px';
		.take-me-home-thankyou {
			margin-left: 7.5px;
			font-family: $National2Regular;
			color: $white;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			background: $Brand-Black;
			border-radius: 200px;
			line-height: 24px;
			border: 1.5px solid $Brand-Black;
			padding: 0.625rem 2.5rem;
            margin-top: 50px;
			@media #{$breakpoint-below-tablet} {
				margin: 0px 0 20px 0;
			}
		}

		@media #{$breakpoint-below-tablet} {
			display: flex;
			flex-direction: column-reverse;
			margin-top: 50px;
		}
	}
}
