.offers-container {
	background-color: $Brand-Black;
	overflow: hidden;
	margin-top: 90px;

	& .offersCard-container {
		margin-left: 12%;

		@media #{$breakpoint-below-tablet} {
			margin: 0;
			text-align: center;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		& .offers-Icon {
			margin-bottom: 4.6%;
			margin-left: 3.2%;

			& .offersItem-icon {
				padding: 0 1%;
			}
		}

		& .offersContainerHeader-items {
			color: $white;
			display: flex;
			align-items: center;
			margin-bottom: 40px;
			margin-left: 3.2%;
			justify-content: space-between;

			& .view-all-text {
				@media #{$breakpoint-above-desktop} {
					margin-right: 186px;
				}

				@media #{$breakpoint-below-desktop} {
					margin-right: 230px;
				}

				@media #{$breakpoint-between-tablet-desktop} {
					margin-right: 167px;
				}

				@media #{$breakpoint-below-tablet} {
					margin-right: 0;
				}
			}

			@media #{$breakpoint-below-tablet} {
				flex-direction: column;
			}

			& .offersContainer-header {
				// margin-right: 652px;
				font-family: $National2Condensed;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 38px;
				line-height: 43px;
				font-weight: bold;

				@media #{$breakpoint-below-tablet} {
					width: 100%;
					height: 32px;
					font-size: 24px;
					line-height: 32px;
					margin: 0;
					margin-bottom: 10px;
				}
			}

			& .offersViewAll-link {
				font-family: $National2Regular;
				font-weight: 500;
				font-size: 14px;
				line-height: 24px;
				color: $white;
				text-decoration: none;
				margin-right: 10px;

				&:hover {
					cursor: pointer;
				}
			}

			& .offersViewAlldeals-arrow {
				display: inline-block;
			}
		}

		& .offersCard-list {
			display: flex;
			padding: '5px 0';
			flex-direction: column;

			@media #{$breakpoint-below-tablet} {
				width: 100%;
			}

			& .offers-slideContainer {
				width: 80%;
				margin-bottom: 8.3%;
				margin-left: 2%;

				@media #{$breakpoint-below-tablet} {
					display: none;
				}

				& .offers-slider {
					width: 100%;
					height: 3px;
					-webkit-appearance: none;
					background: $Dark-Gray;
					outline: none;
					border-radius: 5%;
					-webkit-transition: 0.2s;
					transition: opacity 0.2s;
				}

				& .offers-slider::-webkit-slider-thumb {
					-webkit-appearance: none;
					appearance: none;
					width: 10%;
					height: 3px;
					border-radius: 5%;
					background: $white;
					cursor: pointer; /* Cursor on hover */
				}

				& .offers-slider::-moz-range-thumb {
					-webkit-appearance: none;
					appearance: none;
					width: 10%;
					height: 2px;
					border-radius: 5%;
					background: $white;
					cursor: pointer; /* Cursor on hover */
					border: 0;
				}
			}

			& .offersCarousal-list {
				margin-bottom: 5.9%;

				@media #{$breakpoint-below-tablet} {
					margin-bottom: 23%;
				}

				& .slick-prev {
					left: -70px;

					@media #{$breakpoint-below-tablet} {
						z-index: 1;
						left: 0;
					}
				}

				& .slick-prev::before {
					font-family: unset;
					font-size: 36px;
					content: '\2039'; // \203A HTML Entry for ‹
					color: #202124;
					background-color: white;
					border-radius: 50%;
					display: inline-block;
					width: 48px;
					height: 48px;
					text-align: center;
				}

				& .slick-prev:focus::before {
					outline: 1px auto -webkit-focus-ring-color;
				}

				& .slick-list {
					width: 135%;
					padding-left: 2rem;

					@media #{$breakpoint-below-tablet} {
						padding-left: 0;
					}

					& .slick-track {
						margin-left: 0;

						@media #{$breakpoint-below-tablet} {
							margin-left: 20px;
							width: 2863px !important;
						}

						& .lastOffersCard-fade {
							opacity: 0.5;
						}

						& .slick-slide {
							opacity: 1 !important;
							margin-left: 37px !important;
							width: 310px;
							height: 398px !important;

							@media #{$breakpoint-below-tablet} {
								width: 8.8% !important;
								height: 369px !important;
							}

							& .offers-card {
								box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);
								position: relative;
								height: 398px;
								background-color: $Mashed-Potato-Gray;
								border-radius: 8px;
								text-align: center;
								display: flex;
								flex-direction: column;

								@media #{$breakpoint-below-tablet} {
									height: 369px !important;
								}

								& .offersImageText-background {
									height: 59%;
									object-fit: cover;
									border-top-left-radius: 3%;
									border-top-right-radius: 3%;
									@media #{$breakpoint-below-tablet} {
										height: 57%;
									}
								}

								& .offersOnlyImage-background {
									border-radius: 3%;
									height: inherit;
									position: relative;
									background: linear-gradient(190.77deg, rgba(32, 33, 36, 0) 21.45%, rgba(32, 33, 36, 0.6) 98.01%);
								}

								& .offersRibbon-container {
									position: absolute;
									left: -12px;
									top: 12px;
									z-index: 1000;

									& .offers-ribbon {
										position: relative;
										// transform: scaleX(3);
									}

									& .ribbon-text {
										position: absolute;
										top: 4px;
										left: 12px;
										font-family: $National2Condensed;
										font-style: normal;
										font-weight: bold;
										font-size: 16px;
										line-height: 20px;
										letter-spacing: 1px;
										text-transform: uppercase;
										color: $white;	
									}

									& .offersTimer-text {
											width: 150px;
											position: absolute;
											left: 4px !important;
											top: 3px;
											overflow: hidden !important;
											font-family: $National2Condensed;
											font-style: normal;
											font-weight: bold;
											font-size: 16px;
											line-height: 20px;
											letter-spacing: 1px;
											text-transform: uppercase;
											color: $Brand-Red;

												& .offers-timer {
												display: inline-block;
												position: absolute;
												left: 0px;
    											top: 4px;
											}
										}
								}

								& .offersCard-content {
									display: flex;
									flex-direction: column;
									justify-content: space-evenly;
									align-items: center;
									margin-left: 20px;
    								margin-right: 20px;

									& .offersImageTextCard-title {
										height: 43px;
										font-family: $National2Condensed;
										font-size: 38px;
										font-weight: 700;
										line-height: 43px;
										color: $Brand-Red;
										text-transform: uppercase;
										overflow: hidden;
										text-overflow: ellipsis;
										text-align: center;
										display: -webkit-box;
										-webkit-line-clamp: 1;
										-webkit-box-orient: vertical;
									}

									& .offersOnlyTextCard-title {
										width: 100%;
										height: 86px;
										font-family: $National2Condensed;
										font-weight: 700;
										font-size: 30px;
										line-height: 22px;
										letter-spacing: 1px;
										margin-top: 23%;
										text-transform: uppercase;
									}

									& .validity {
										color: $Dark-Gray;
										margin-top: 9%;
										font-family: $National2Regular;
										font-weight: 400;
										font-style: normal;
										font-size: 14px;
										line-height: 24px;
										letter-spacing: -0.1px;
										text-align: center;
									}

									& .offersCard-text,
									.offersOnlyTextCard-text {
										//width: 87%;
										height: 48px;
										font-family: $National2Regular;
										font-weight: 400;
										font-size: 14px;
										//line-height: 24px;
										color: $Brand-Black;
										overflow: hidden;
										text-overflow: ellipsis;
										-webkit-line-clamp: 2;
										display: -webkit-box;
										-webkit-box-orient: vertical;
									}

									& .offersOnlyTextCard-text {
										height: 29.5%;
										margin-top: 6%;
									}

									& .offersButton-item {
										display: flex;
										justify-content: inherit;
										position: absolute;
										bottom: -1%;
										align-items: baseline;
										width: 100%;
										margin-bottom: 30px;

										@media #{$breakpoint-below-tablet} {
											margin-bottom: 25px;
										}

										& .offersView-details:hover,
										.offers-redeem:hover {
											cursor: pointer;
										}

										& .offersView-details {
											border: none;
											background: none;
											// outline: none;
											text-decoration: underline;
											padding: initial;
											font-family: $National2Medium;
											font-style: normal;
											font-weight: 500;
											font-size: 14px;
											line-height: 24px;
											color: $Dark-Gray;
											&:hover {
												background-color: $Light-Gray;
											}

											&:active {
												background-color: #cfd0d0;
											}

											&:focus-visible {
												background: #fff;
												border: 2px solid #2e4abd;
											}

											&:disabled {
												opacity: 0.5;
												background: transparent;
												cursor: not-allowed !important;
											}

											@media #{$breakpoint-below-tablet} {
												font-size: 12px;
												line-height: 22px;
											}
										}

										& .offers-redeem {
											font-family: $National2Medium;
											font-weight: 500;
											font-size: 14px;
											line-height: 24px;
											&:disabled {
												color: $Brand-Black;
												cursor: not-allowed !important;
											}

											@media #{$breakpoint-below-tablet} {
												padding: 10px 40px;
											}
										}

										
									}
								}

								& .offersFullImageCard-content {
									& .fullImageCardButton-item {
										.offers-redeem {
											&:hover {
												cursor: pointer;
												color: $Brand-Black;
												background-color: $white;
											}

											&:focus-visible {
												color: $Brand-Black;
												background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #fff;
												border: 2px solid transparent !important;
												box-shadow: 0 0 0 2px #ffbd15;
												border-radius: 200px;
											}

											&:active {
												cursor: pointer;
												color: $Brand-Black;
												background: linear-gradient(0deg, rgba(32, 33, 36, 0.2), rgba(32, 33, 36, 0.2)), #fff;
											}

											&:disabled {
												background-color: transparent;
												color:$Brand-Black;
												cursor: not-allowed;
											}
										}
										& .offersView-details,
									
										.offers-redeem {
											font-family: $National2Medium;
											border-color: $white;
											color: $white;
											font-weight: 500;
											font-size: 14px;
											line-height: 24px;
										}
									}
								}
							}
						}
					}
				}

				& .slick-next {
					right: 89px;
				}

				& .slick-next::before {
					font-family: unset;
					font-size: 36px;
					content: '\203A'; // \203A HTML Entry for ›
					color: #202124;
					background-color: white;
					border-radius: 50%;
					display: inline-block;
					width: 48px;
					height: 48px;
					text-align: center;
				}

				& .slick-next:focus::before {
					outline: 1px auto -webkit-focus-ring-color;
				}

				& .slick-next,
				.slick-prev {
					@media #{$breakpoint-below-tablet} {
						display: none;
					}
				}
			}
		}
	}
}

.alert-toast {
	width: 298px;
	top: 451px;

	& .text-img-div {
		text-align: $center;
	}

	& .text {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 22px;
		font-family: $National2Regular;
		margin-left: 5px;
	}
}
