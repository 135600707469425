@mixin search-text-nutrition {
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
}
.sopac-medium-nutritionAllergen-container {
	background-color: $white;
	.medium-category-name {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		// font-size: 44px;
		line-height: 58px;
		text-transform: uppercase;
		padding-top: 3.75rem;
		h2 {
			font-weight: 700;
			color:$black;
			font-size: 44px;
			line-height: 58px;
		}
		@media #{$breakpoint-below-tablet} {
			font-size: 36px;
			line-height: 36px;
			color: $Brand-Black;
			margin-left: 20px;
			width: 274px;
		}
		//padding-top: 8%;
		@media #{$breakpoint-below-tablet} {
			font-size: 36px;
			line-height: 36px;
			color: $Brand-Black;
			margin-left: 20px;
			width: 274px;
		}

		@media #{$breakpoint-below-and-above-medium-desktop} {
			padding-top: 54px;
		}
	}
	.medium-subcategory-name {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		line-height: 24px;
		letter-spacing: 0.5px;
		text-transform: uppercase;
		margin-top: 35px;
		@media #{$breakpoint-below-tablet} {
			display: flex;
			align-items: flex-end;
			letter-spacing: 1px;
			color: $Brand-Black;
			margin-left: 20px;
			margin-top: 24px;
		}
	}
	.product-container {
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;
		@media #{$breakpoint-below-tablet} {
			display: flex;
			flex-direction: column;
			justify-content: center;
			margin-top: 30px;
		}
		.medium-menu-product-card {
			position: relative;
			width: 31%;
			// height: 475px;
			max-height: 300px;
			font-family: $National2Regular;
			background: $white;
			box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			margin-right: 2.3%;
			margin-bottom: 1.875rem;
			a {
				text-decoration: none;
			}
			@media #{$breakpoint-below-and-above-medium-desktop} {
				max-width: 280px;
			}
			@media #{$breakpoint-medium-desktop} {
				max-height: 290px;
			}
			@media #{$breakpoint-above-desktop} {
				max-height: 480px;
			}
			@media #{$large-only} {
				margin-right: 2%;
			}
			@media #{$breakpoint-below-tablet} {
				position: relative;
				background: $white;
				box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
				border-radius: 4px;
				width: 94%;
				height: 290px;
				padding: 15px 12px;
				margin-bottom: 15px;
				margin-right: 17px;
				margin-left: 18px;
				a {
					text-decoration: none;
				}
			}
			.medium-menu-product-image {
				width: 100%;
				// height: 210px;
				height: auto;
				margin-bottom: 15px;
				border-top-left-radius: 8px;
				border-top-right-radius: 8px;
				@media #{$breakpoint-above-desktop} {
					height: auto;
				}
				@media #{$breakpoint-below-tablet} {
					position: absolute;
					//   left: 65.59%;
					right: 3.82%;
					top: 4.91%;
					bottom: 65.28%;
					width: 100px;
					height: 75px;
					border-radius: 4px;
				}
			}
			.specials-product-ribbon {
				position: absolute;
				top: 3%;
				left: 6%;
				transform: scaleX(1.5);
				@media #{$breakpoint-below-tablet} {
					position: absolute;
					left: 5%;
					transform: scaleX(1.5);
				}
			}
			.specials-ribbon-text {
				position: absolute;
				top: 4%;
				left: 0%;
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 20px;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				color: $white;
				@media #{$breakpoint-below-tablet} {
					position: absolute;
					top: 4%;
					left: 0%;
				}
			}
			.discount-product-ribbon {
				position: absolute;
				top: 3%;
				left: -12%;
				transform: scaleX(1.5);
			}
			.discount-ribbon-text {
				position: absolute;
				left: 0;
				top: 3%;
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $Brand-Red;
			}
			.exclusive-product-ribbon {
				position: absolute;
				top: 3%;
				left: -3%;
				transform: scaleX(2.5);
			}
			.menu-product-save {
				position: absolute;
				left: 87.86%;
				right: 3.57%;
				top: 2.11%;
				bottom: 92.84%;
				cursor: pointer;
				z-index: 1;
				@media #{$breakpoint-below-tablet} {
					position: absolute;
					left: 78.88%;
					right: 12.06%;
					top: 82.51%;
					bottom: 9.43%;
					cursor: pointer;
					display: none;
				}
			}
			.menu-card-content {
				margin-left: 20px;
				margin-right: 20px;
				margin-bottom: 20px;
				@media #{$breakpoint-below-tablet} {
					margin-left: auto;
					margin-right: auto;
					margin-bottom: auto;
				}
				.medium-menu-product-header {
					margin-bottom: 5px;
					font-style: normal;
					font-size: 16px;
					line-height: 24px;
					font-weight: 500;
					font-family: $National2Medium;
					color: $Brand-Black;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					@media #{$breakpoint-below-tablet} {
						width: 199px;
						font-family: $National2Regular;
						color: $Brand-Black;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						/* number of lines to show */
						-webkit-box-orient: vertical;
					}
				}
				.medium-menu-price-and-KJ {
					display: inline-block;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					margin-bottom: 5px;
					color: $Dark-Gray;
					margin-left: 2px;
					@include Dot();
				}
				.Price-and-KJ-info {
					display: flex;
				}
				.medium-menu-product-price {
					font-family: $National2Medium;
					color: $Brand-Black;
					font-style: normal;
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					margin-bottom: 5px;
				}
				.medium-menu-product-description {
					font-style: normal;
					font-weight: normal;
					font-family: $National2Regular;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: -0.1px;
					color: $Dark-Gray;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					/* number of lines to show */
					-webkit-box-orient: vertical;
				}
				&.menu-card-content-fav {
					.medium-menu-product-header {
						@media #{$breakpoint-below-tablet} {
							width: 58%;
						}
					}
					.medium-menu-product-price {
						.price-difference-container {
							.prev-price {
								text-decoration: line-through;
							}
							.new-current-price {
								color: #ff2e00;
							}
						}
					}
					.product-details-mobile-view {
						.medium-menu-product-description {
							min-height: 62px;
							width: 57%;
						}
						.price-veg-nonveg {
							margin-top: 15px;
							display: flex;
						}
						.heart-icon {
							padding-top: 3px;
						}
						.medium-menu-price-and-KJ {
							margin-left: 10px;
							margin-top: 1px;
						}
						.mobile-fav-menu-style,
						.mobile-fav-menu-style-unavailable {
							position: absolute;
							left: 87%;
							@media (min-width: 380px) {
								left: 90%;
							}
						}
					}
				}
			}
			.medium-menu-cart-button {
				display: flex;
				justify-content: center;
				//width: 80%;
				height: 44px;
				margin-top: 10px;
				//position: absolute;
				//left: 10%;
				//right: 10%;
				//bottom: 1rem;
				@media #{$breakpoint-below-tablet} {
					display: flex;
					flex-direction: row;
					justify-content: center;
					align-items: center;
					position: absolute;
					left: 4.41%;
					right: 25%;
					bottom: 5.66%;
					width: 68%;
				}
			}
		}
		.product-card-active {
			cursor: pointer;
			z-index: 50;
			@media #{$breakpoint-below-tablet} {
				text-decoration: none !important;
			}
		}
	}
	&.medium-menu-container-fav-menu {
		.product-container {
			margin-top: 20px;
			margin-right: 20px;
			@media #{$breakpoint-below-tablet} {
				margin-right: 40px;
			}
			.medium-menu-product-card {
				width: 280px;
				@media #{$breakpoint-below-tablet} {
					width: 100%;
				}
				.medium-menu-cart-button {
					margin-bottom: 20px;
				}
				.cart-btn-container {
					width: 100%;
					.edit-selection {
						text-decoration-line: underline;
						font-family: $National2Regular;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 20px;
						bottom: 28px;
						position: relative;
						float: right;
					}
					.cardButtonOnly {
						bottom: 0;
					}
					.mobile-fav-menu-style,
					.mobile-fav-menu-style-unavailable {
						display: none;
					}
					@media #{$breakpoint-below-tablet} {
						display: flex;
						.cardButtonOnly {
							position: static;
						}
						.mobile-fav-menu-style {
							display: flex;
							position: absolute;
							right: 10%;
							bottom: 17%;
						}
						.mobile-fav-menu-style-unavailable {
							display: flex;
							position: absolute;
							bottom: 50px;
							right: 11.5%;
						}
						// img {
						// 	margin-left: -32px;
						// 	margin-top: 40px;
						// }
					}
				}
				@media #{$medium-only} {
					.cardwithQuantity {
						.mobile-fav-menu-style {
							bottom: 12%;
						}
					}
				}
				@media #{$mobile-only} {
					.quantityAddtocart {
						justify-content: flex-start;
						margin-left: -10px;
						.cart-btn-container {
							display: inline-flex;
							justify-content: center;
							//position: absolute;
							//bottom: 0;
							.cardButtonOnly {
								position: revert;
								width: 90%;
								.medium-menu-cart-button {
									position: relative;
									width: 90%;
									left: 0;
									bottom: 0;
								}
							}
							.mobile-fav-menu-style {
								margin-left: 0;
								//position: relative;
								right: 0;
							}
							.mobile-fav-menu-style-unavailable {
								position: relative;
								display: flex;
								top: 5px;
								right: 12px;
							}
					
						}
					}
					.cardwithNoquantity {
						.cart-btn-container {
							.mobile-fav-menu-style {
								right: 3.82%;
							}
						}
					}
				}
				.medium-menu-product-image-fav {
					@media #{$breakpoint-below-tablet} {
						width: 123px;
						height: 93px;
					}
				}
			}
		}
	}
	&.medium-menu-container-fav-menu-disabled {
		.medium-menu-product-image-fav {
			opacity: 0.4;
		}
		.medium-menu-cart-button-disabled {
			background-color: #dbdbdb;
			border: none;
			//color: #494949;
			font-weight: bold;
		}
	}
}

.sopac-small-nutrition-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin-top: 30px;
	@media #{$mobile-only} {
		margin-top: 0px;
	  }
	&:first-child {
		margin-top: 0;
		.small-category-name {
			margin-top: 60px;
			@media #{$mobile-only} {
				margin-top: 39px;
			  }
		}
		.offsetFirstIndex .small-category-name {
			margin-top: 0;
			@media #{$mobile-only} {
				margin-top: -16px;
			  }
		}
	}
	.product-view-section {
		display: flex;
		justify-content: flex-end;
		align-items: baseline;
		margin-right: 20px;
		margin-top: 2vw;
		margin-bottom: 10px;
		.product-view {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: 500;
			font-size: 12px;
			line-height: 22px;
			text-align: right;
			color: $Dark-Gray;
			opacity: 0.5;
		}
		.product-view-up-icon {
			margin-left: 10px;
		}
		.product-view-carousal-icon {
			margin-left: 10px;
		}
	}
	.product-view-divider {
		border-top: 1px solid #e3e3e3 !important;
		width: 100%;
		margin-bottom: 30px !important;
		margin-left: auto !important;
		margin-right: auto !important;
		margin-top: auto !important;
	}
	.small-category-name {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 20px;
		line-height: 22px;
		text-transform: uppercase;
		color: $Brand-Black;
		margin-left: 4px;
		width: 274px;
		margin-top:20px;
		@media #{$breakpoint-between-mob-tablet} {
			width: auto;
		}
	}
	.small-subcategory-name {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 24px;
		display: flex;
		align-items: flex-end;
		letter-spacing: 1px;
		text-transform: uppercase;
		color: $Brand-Black;
		margin-left: 20px;
		margin-top: 24px;
	}
	.product-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-top: 30px;
		// z-index: 1;;
		.small-menu-product-card {
			position: relative;
			background: $white;
			box-shadow: 0 2px 9px rgba(0, 0, 0, 0.15);
			border-radius: 4px;
			border-radius: 8px;
			width: 97%;
			// height: 280px;//sopac
			min-height: 315px;
			// padding: 15px 15px; //sopac
			margin:0 5px 30px 5px;
			@media #{$breakpoint-below-tablet} {
				a {
					text-decoration: none !important;
				}
			}
			.cardwithQuantity {
				justify-content: space-evenly;
				margin-left: 0;
				width: 70%;
				//column-gap: 4%;
				@media #{$medium-only} {
					//column-gap: 30%;
				}
			}
			@media screen and (max-width: 1022px) {
				.cardwithQuantity.loggedIN {
					width: 28%;
				}
			}
			.small-menu-product-image {
	
				bottom: 65.28%;
				width: 100%;
			
				height: auto;
				border-radius: 8px 8px 0 0	;
			
			}
			.specials-product-ribbon {
				position: absolute;
				top: 2%;
				left: 11%;
				transform: scaleX(1.8);
				
			}
			.specials-ribbon-text {
				position: absolute;
				top: 3%;
				left: -3%;
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 20px;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				color: $white;
			}
			.discount-product-ribbon {
				position: absolute;
				top: 3%;
				left: -8%;
				transform: scaleX(1.8);
				@media #{$breakpoint-between-mob-tablet} {
					left: -5%;
				}
			}
			.discount-ribbon-text {
				position: absolute;
				left: 0;
				top: 3%;
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $Brand-Red;
			}
			.exclusive-product-ribbon {
				position: absolute;
				left: -2%;
				top: 3%;
				transform: scaleX(2.5);
			}
			.menu-product-save {
				position: absolute;
				left: 87.88%;
				cursor: pointer;
				z-index: 1;
				@media (min-width: 411px) and (max-width: 465px) {
					left: 84%;
				}
			}
			.menu-card-content {
				.small-menu-product-header {
					width: 199px;
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
					font-family: $National2Regular;
					color: $Brand-Black;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					// display: -webkit-box;
					// -webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
					margin-bottom: 5px;
					@media #{$breakpoint-tablet-only} {
						width: auto;
						// margin-top: 5%;
						padding: 15px;
					}
					@media #{$mobile-only} {
						// width: 56%;
						width: 100% !important;
						padding: 15px;
						font-weight: 500;
						font-family: $National2Medium;
					}
				}
				.small-menu-product-header-shift {
					width: 199px;
					font-style: normal;
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
					font-family: $National2Regular;
					color: $Brand-Black;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					/* number of lines to show */
					-webkit-box-orient: vertical;
					margin-bottom: 5px;
					// margin-top: 31px;
					@media #{$breakpoint-between-mob-tablet} {
						width: auto;
					}
					@media #{$mobile-only} {
						// width: 56%;
						width: 100%;
						padding: 15px
					}
				}
				.small-menu-price-and-KJ {
					display: inline-block;
					margin-left: 2px;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					color: $Dark-Gray;
					margin-bottom: 5px;
					@media #{$breakpoint-between-mob-tablet} {
						margin-bottom: 15px;
					}
					@media #{$mobile-only} {
						font-family: $National2Regular;
					  }
					@include Dot();
				}
				.small-price-and-KJ-info {
					display: flex;
				}
				.small-menu-product-price {
					font-style: normal;
					font-family: $National2Regular;
					color: $Brand-Black;
					font-weight: 600;
					font-size: 16px;
					line-height: 24px;
					@media #{$breakpoint-between-mob-tablet} {
						margin-bottom: 20px;
					}
				}
				.small-menu-product-description {
					font-family: $National2Regular;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: -0.1px;
					color: $Dark-Gray;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 3;
					/* number of lines to show */
					-webkit-box-orient: vertical;
					margin-top: 5px;
					margin-bottom: 14px;
					width: 56%;
				}
				.PriceVegNonveg {
					display: flex;
					@media #{$breakpoint-between-mob-tablet} {
						margin-top: 25px;
					}
				}
				.hearticonDiv {
					padding-top: 4px;
				}
				.description-and-image {
					min-height: 70px;
				}
			}
			.small-menu-cart-button {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				width: 80%;
				height: 44px;
				@media (min-width: 769px) and (max-width: 1022px) {
					width: 62%;
				}
				@media #{$breakpoint-between-mob-tablet} {
					width: 62%;
				}
			}
		}
		.small-menu-product-card-long {
			height: 330px;
		}
		.product-card-active {
			cursor: pointer;
			z-index: 50;
			@media #{$breakpoint-below-tablet} {
				text-decoration: none !important;
				z-index: 0;
			}
		}
		& .quantityAddtocart {
			.cardButtonOnly.loggedIN {
				@media (min-width: 342px) and (max-width: 1022px) {
					width: 89%;
					// margin-left: -42px;
				}
				@media (max-width: 340px) {
					width: 86%;
					left: -3%;
				}
			}
		}
	}
	.card-counter {
		display: none;
		position: absolute;
		right: 0;
		bottom: 0;
		color: #848282;
	}
}

.offsetWithLocalized {
	margin-top: 70px;
}

.offsetOtherIndex {
	margin-top: 62px;
	@media #{$mobile-only} {
		margin-top: 43px;
	  }
}

.offsetFirstIndex {
	margin-top: 50px;
}

.offsetFirstIndexWithBanner {
	margin-top: 50px;
}

@media #{$mobile-only} {
	.mob-plp-au-nutrition {
		.mob-nav-row {
			height:50px;
			padding: 5px 20px 0 20px;
			border-top: 1px solid rgba(219,219,219,.7);
			border-bottom: 1px solid rgba(219,219,219,.7);
			& .mob-search-input {
				border-radius: 200px;
				padding: 0 18px 0 24px;
				margin-top: 2px;
			}
			& .totalFilterAppliedCount{
				left: 13%;
				top: 1%;
			}

			& .mob-search-img {
				height: 19px;
			}

			.mob-outer-ul {
				margin-top: 8px;

				& li {
					&:first-child {
						margin-left: 17px;	
					}

					& a {
						font-weight: 400;
						padding-bottom:32px;
						position: relative;
					}

					& a.active {
						border-bottom: 3px solid $Brand-Black;
					}
				}
			}
			
		}

		.mob-disclimer-content {
			margin: 0px 20px 40px 20px;

			.info-content-mobile {
				font-family: $National2Regular;
			}
		}
		.rnaNutritionAllergen-header{	
			position: sticky;
			top: 0 !important;
		}
		.sidenav-filter {
			.refineItemsPartMobPLP {
				.refineItemsText {
					@include search-text-nutrition;
				}
			}
		}	
	}
	
}
@media #{$breakpoint-tablet-only} {
	.mob-plp-au-nutrition {
	  .mob-nav-row {
		padding-left: 20px;
		.mob-outer-ul {
		  & li {
			& a.active {
			  border-bottom: 3px solid $Brand-Black;
			}
		  }
		}
	  }
	  .rnaNutritionAllergen-header{	
		margin-top: -50px;
	}
	.sidenav-filter {
		.refineItemsPartMobPLP {
			.refineItemsText {
				@include search-text-nutrition;
			}
		}
	}	
	}

}
.nutration-and-allergen-guide-info {

	p {
		font-family: $National2Condensed;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: 2px;
		text-align: center;
	}

	div {
		height: 78px;
		// width: 802px;
		left: 319px;
		top: 117px;
		font-family: $National2Condensed;
		font-size: 74px;
		font-weight: 700;
		line-height: 78px;
		letter-spacing: 2px;
		text-align: center;

	}

	@media #{$breakpoint-below-tablet} {
		div {
			height: 92px;
			width: 335px;
			left: 20px;
			top: 181px;
			font-size: 42px;
			line-height: 46px;
			letter-spacing: 1px;
		}

	}

}

.hr-line {
	margin-top: 50px;
	border-bottom: 1px solid #DBDBDB;
	
}
.energy-kj-title{
	display: flex;
	justify-content: flex-end;
	margin-right: 115px;
	font-family: $National2Medium;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0px;
	@media #{$breakpoint-below-tablet} {
		width: 100%;
		padding-right: 20px;
		margin-top: -18px;
		font-size: 12px;

	}

}
	.nutration-allergen-right-nav{
		margin-top: 40px;
	}