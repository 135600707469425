
.save-order-modal {
	& .modal-content {
		position: absolute;
		width: 38%;
		height: 365px;
		left: 32%;
		top: 30%;

		@media #{$breakpoint-below-tablet} {
			width: 100%;
			height: 365px;
			left: 0;
			top: 43%;
		}

		& .modal-header {
			border-bottom: 0;

			@media #{$breakpoint-below-tablet} {
				padding: 3% 1%;
			}
		}

		& .modal-body {
			padding-right: 40px;
			padding-left: 35px;
		}
	}
}

.fontstyle {
	font-family: $National2Condensed;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.5px;
	text-transform: uppercase;
	color: $Brand-Black;
}

.modalContants {
	margin-top: 32px;
}

.inputfield {
	border: none;
	background: transparent;
	border-bottom: 1px solid $Brand-Black;
	width: 380px;
	
}
.inputfield:focus-visible {
    outline:none;
}
.inputbox {
	display: flex;
	justify-content: center;
	margin-top: 60px;
}

.buttonItems {
	display: flex;
	justify-content: space-evenly;
	margin-top: 40px;
}

.closebtn-cls {
	margin-right: 40px;
}
