.add-hope-container{
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
    max-width: 1220px;
    .tipping-details{
       max-width: 100%;
        flex-basis: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
    .flex-container>div{
        width: 168px !important;
        height: 40px;
        padding-left: 0px;
        @media #{$breakpoint-below-tablet} {
            width: 26% !important;
         }
         @media #{$medium-only} {
            width: 148px !important;
         }
         @media #{$breakpoint-iphone13-pro-max} {
            width: 28% !important;
		}
        @media #{$breakpoint-above-tablet} {
            width: 29% !important;
         }
    }
    .labelContent{
        display:none;
    }
    .tipping-details {
        margin-top: 40px;
        margin-bottom: 40px;
        padding-left: 0px;
        .button-contained[data-type="primary"] {
            border-radius: 22px;
            @media #{$mobile-only} {
                width: 105% !important;
             }
        }
    }
}
.add-hope-banner-block{
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    }
    .banner-image{
        @media #{$medium-only} {
            width: 768px;
            height: 400px;
         }
        
    }
    .add-hope-content-area{    
        .add-hope-longtext{
            font-family: $National2ExtraBold;
            @media #{$breakpoint-below-tablet} {
                font-size: 10px;
             }
        }
       .add-hope-title{
            font-family: $National2ExtraBold;
            @media #{$breakpoint-below-tablet} {
                font-size: 20px;
             }
        }
        .addhope-display-paragraph{
            @media #{$breakpoint-below-tablet} {
               font-size: 9px;
            }
        }
        .add-hope-bucket-img{
            @media #{$breakpoint-below-tablet} {
                margin-left: 50px;
             }
             @media #{$medium-only} {
                margin-left: 0px;
             }
        }
        .add-hope-text{
            display:inline-block;
        }
        .add-hope-unordered-list{
            margin-bottom: 10px;
            font-family: "Arial Black", "Arial Bold", sans-serif;
            padding-left: 10px;
            margin-top: 42px;
            @media #{$breakpoint-below-tablet} {
                margin-top: 8px;
                padding-left: 0px;
            }
            @media #{$medium-only} {
                margin-top: 45px;
                padding-left: 80px;
            }
        }
        .add-hope-list{
            line-height: 45px;
            min-width: 54px;
            text-align: center;
            border: 1px solid #000;
            display: inline-block;
            padding: 10px;
            list-style: none;
            margin-right: 8px;
            font-size: 50px;
            @media #{$breakpoint-below-tablet} {
                line-height: 18px;
                font-size: 12px;
                padding: 5px;
                min-width: 15px;
                margin-right: 3px
            }
            @media #{$medium-only} {
                line-height: 40px;
                font-size: 12px;
                padding: 5px;
                min-width: 8%;
                margin-right: 4px;
            }
          }
        .add-hope-text-new{
            display:inline-block;
            color: #e4002b;
            font-size: 19px;
            font-family:$National2ExtraBold;
            position: relative;
            padding-left: 10px;
            @media #{$breakpoint-below-tablet} {
               padding-left: 0px;
               font-size: 9px;
            }
            @media #{$medium-only} {
                padding-left: 80px;
                font-size: 11px;
        }
    }
        .flex-container > div{
            flex-basis: 100%;
            max-width: 165px
        }
        .tip-text{
            margin-top: 0px !important;
        }
}
.content-area{
    width: 90%;
    margin: 0 auto;
}
.wrapper-content {
    padding-top: 50px;
    padding-bottom: 30px;
    @media #{$breakpoint-below-tablet} {
        padding-top: 20px;
        padding-bottom: 0px;     
    }
    @media #{$medium-only} {
    padding-left: 100px;
    padding-right: 100px;}
}
.addHopeFormContainer {
    height: fit-content;
    justify-content: center;
    margin-left: 70px;
    width: 68%;
    @media #{$breakpoint-below-tablet} {
        width: 100%;
        margin-left: 0px;
    }
    @media #{$medium-only} {
        padding-left: 124px;
     }
    background-color: #f8f7f5;
    .add-hope-form-container { 
        @media #{$breakpoint-below-tablet} {
            padding: 0;
        }
    }
    .checkoutContactInfoContainer{
        margin-top: -40px;
    }
    .donate-form-details {
        margin-bottom: -36px;
        padding-right: 40px;
        margin-top: -50px;
    margin-left: 171px;
        @media #{$breakpoint-below-tablet} {
           padding: 0px;
           margin: 0px;
         }
    }
    .contactInfoContent{
        margin-top: 0px;
        @media #{$breakpoint-below-tablet} {
           padding: 0px;
         }
    }
    .row{
        margin-left: 0px;
        margin-right: 0px;
    }
    .form{
        padding-left: 100px;
    }
    .labelContent-addHope {
        font-family:  $National2Condensed;
        font-style: normal;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        color: #202124;
        font-size: 24px;
        line-height: 24px;
        font-weight: 700;
    @media #{$breakpoint-below-tablet} {
        font-size: 20px;
        line-height: 20px;
     }
    }
    .info-label {
        padding-right: 0;
        margin-top: 40px;
        margin-left: 25px;
        padding-left: 0px;
        @media #{$breakpoint-below-tablet} {
            margin-top: 24px;
            margin-left: 0px;
            padding-left: 20px;
         }
        
    }
    .info-label-snapscan {
         max-width: 22%;
         @media #{$breakpoint-below-tablet} {
            max-width: 100%;
            padding-left: 10px;
         }
     }
    .donate-details {
        margin-top: 40px;
        margin-bottom: 40px;
        margin-left: 24px;
        @media #{$breakpoint-below-tablet} {
            margin-top: 12px;
            padding-left: 20px;
            margin-left: 0px;
            margin-bottom: 0px;
    }}
    .donate-details-snapscan {
        margin-top: -50px;
        margin-bottom: 20px;
        margin-left: 24px;
        padding-left: 680px;
        padding-bottom: 40px;
        @media #{$breakpoint-below-tablet} {
            margin-top: 12px;
            padding-left: 20px;
            margin-left: 0px;
            margin-bottom: 0px;
         }
    }
    .tip-text-donate {
        font-family:$National2Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        letter-spacing: -0.1px;
        color: #494949;
        margin-bottom: 14px;
        @media #{$breakpoint-below-tablet} {
            margin-bottom: 0px;
         }
    }
    .addHope-button {
        background-color: #202124 !important;
        color: #fff;
    }  
    .snapscan { 
        padding-right: 40px;
        padding-bottom: 40px;
        padding-left: 500px;
    }
    .txt-align-center {
        text-align: left !important;
    }
}