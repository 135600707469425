.ourAds-desktop-container {
	display: flex;
	padding-left: 104px;
	padding-right: 130px;

	@media #{$breakpoint-above-desktop} {
		padding-left: 143px;
	}

	.ourAds-section-left {
		width: 294px;

		.ourAds-header-container {
			
			.ourAds-icon {
				margin-bottom: 36px;
			}

			.ourAds-header {
				margin-bottom: 30px;
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 38px;
				line-height: 43px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $Brand-Black;
			}

			.ourAds-eff-date {
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: -0.1px;
				color: $Dark-Gray;
			}
		}
	}

	.ourAds-section-right {
		margin-left: 120px;
		width: 780px;
		margin-top: 70px;

		@media #{$breakpoint-above-desktop} {
			margin-left: 288px;
			width: 1000px;
		}

		.ourAds-description-desktop {
			margin-bottom: 60px;

			.ourAds-description {
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 16px;
				line-height: 24px;
				letter-spacing: -0.1px;
				color: $Dark-Gray;
			}

			.ourAds-description > ul {
				padding-left: 13px;
				margin-top: 56px;
			}
		}

		.ourAds-questions-desktop {
			margin-bottom: 120px;

			.ourAds-accordian {
				padding-top: 50px;
				padding-bottom: 50px;
				border-bottom: 2px solid $Light-Gray;

				.global-accordian-container {
					&:focus {
						outline: none !important;
					}

					.global-accordian-header-container {
						padding-left: 30px;
						padding-right: 44px;

						.global-accordian-header {
							font-family: $National2Medium;
							font-style: normal;
							font-weight: 500;
							font-size: 16px;
							line-height: 24px;
							display: flex;
							align-items: center;
							color: $Brand-Black;
						}

						.global-accordian-arrow {
							width: 10px;
							height: 10px;
						}
					}

					.global-accordian-content {
						margin-top: 50px;
						font-family: $National2Regular;
						font-style: normal;
						font-weight: normal;
						font-size: 16px;
						line-height: 24px;
						letter-spacing: -0.1px;
						color: $Dark-Gray;
						padding-left: 30px;
						padding-right: 70px;

						.ourAds-accordian-answer > p > b {
							font-family: $National2Regular;
							font-style: normal;
							font-weight: 500;
							font-size: 18px;
							line-height: 30px;
							color: $Brand-Black;
						}

						.ourAds-accordian-answer > ul {
							padding-left: 14px;
						}
					}
				}
			}

			.ourAds-accordian:first-child {
				border-top: 2px solid $Light-Gray;
			}

			.ourAds-accordian-open {
				background-color: $Mashed-Potato-Gray;
			}
		}
	}
}

.ourAds-mobile-container {
	.ourAds-mobile-header {
		margin-bottom: 40px;
		padding-right: 20px;
		padding-left: 20px;

		.ourAds-icon {
			margin-bottom: 20px;
		}

		.ourAds-header {
			margin-bottom: 20px;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 32px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $Brand-Black;
		}

		.ourAds-eff-date {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.1px;
			color: $Dark-Gray;
		}
	}

	.ourAds-mobile-description {
		margin-bottom: 40px;
		font-family: $National2Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 24px;
		letter-spacing: -0.1px;
		color: $Dark-Gray;
		padding-right: 20px;
		padding-left: 20px;
	}

	.ourAds-description > ul {
		padding-left: 17px;
		margin-top: 30px;
	}

	.ourAds-mobile-questions {
		margin-bottom: 60px;

		.ourAds-accordian {
			padding-top: 36px;
			padding-bottom: 36px;
			border-bottom: 2px solid $Light-Gray;
			padding-right: 20px;
			padding-left: 20px;

			.global-accordian-container {
				&:focus {
					outline: none !important;
				}

				.global-accordian-header-container {
					.global-accordian-header {
						font-family: $National2Medium;
						font-style: normal;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						display: flex;
						align-items: center;
						color: $Brand-Black;
					}
				}

				.global-accordian-content {
					margin-top: 24px;
					font-family: $National2Regular;
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 24px;
					letter-spacing: -0.1px;
					color: $Dark-Gray;

					.ourAds-accordian-answer > p > b {
						font-family: $National2Regular;
						font-style: normal;
						font-weight: 500;
						font-size: 18px;
						line-height: 30px;
						color: $Brand-Black;
					}

					.ourAds-accordian-answer > ul {
						padding-left: 14px;
					}
				}
			}
		}

		.ourAds-accordian:first-child {
			border-top: 2px solid $Light-Gray;
		}

		.ourAds-accordian-open {
			background-color: $Mashed-Potato-Gray;
		}
	}
}
