.nutration-allergen-view{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 28px 37px;
    font-family: $National2Medium;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    color: #202124;
    justify-content: space-between;

    @media #{$breakpoint-below-tablet}  {
        padding: 19px 15px;
    }
}
.nutration-allergen-view:nth-child(even) {
    background: #FFFFFF;
    height: 80px;
    @media #{$breakpoint-below-tablet}  {
        height: 60px;
    }
}
.nutration-allergen-view:nth-child(odd) {
    background: #F8F7F5;
    height: 80px;
    @media #{$breakpoint-below-tablet}  {
        height: 60px;
    }
}
.kj-value-and-more-info{
    display: flex;
    justify-content: space-between;
}
.kj-value{
    padding-right: 46px;
    font-family: $National2Medium;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0px;
    text-align: right;
    display: flex;
    align-items: center;
    @media #{$breakpoint-below-tablet}  {
        padding-right: 33px;
    }
}
.more-info{
    font-family: $National2Medium;
    font-size: 12px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    text-decoration: underline;
    cursor: pointer

}

.nutration-and-allergen-guide-section{

    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #F8F7F5;
    .nutration-and-allergen-guide-info {

        p {
            font-family: $National2Condensed;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 2px;
            text-align: center;
            margin-bottom: 0px;
        }
    
        div {
            height: 78px;
            // width: 802px;
            left: 319px;
            top: 117px;
            font-family: $National2Condensed;
            font-size: 74px;
            font-weight: 700;
            line-height: 78px;
            letter-spacing: 2px;
            text-align: center;
    
        }
    
        @media #{$breakpoint-below-tablet} {
            div {
                height: 92px;
                width: 335px;
                left: 20px;
                top: 181px;
                font-size: 42px;
                line-height: 46px;
                letter-spacing: 1px;
            }
    
        }
    
    }
    
}


.no-padding{
    padding: unset !important;
}
.padding-mobile{
    padding: 0px 15px;
}
.position-relative{
    position: relative;
}
.no-search-results-found{
    font-family: $National2Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    padding: 15px;
    height: 300px;

}
.setLocationData.for-nutrigen-banner{
    @media #{$mobile-only} {
        background: #202124;
        color: #fff;
    } 
    & .google-location-img{
        @media #{$mobile-only} {
            margin-top: 0;
        }
    }
    & .setLocation-button{
        @media #{$mobile-only} {
            margin: auto;
            background-color: transparent !important;
            color: #fff !important;
            font-size: 14px;
            width: 45%;
        }
        &:focus{
            outline: none;
        }
        &:focus-visible{
            outline: none;
        }
        &::after{box-shadow: none;}
    }
    & .setLocationText{
        @media #{$mobile-only} {
            display: none;
        }
    }
    .arrow_white{
        background: url("../../staticAssets/images/arrow_white.svg") no-repeat;
        height: 10px;
        width: 10px;
        display: inline-block;
    }
  }
.nutrition-accordian {
    width: 100%;
    background: #F8F7F5;
    padding: 28px 37px;
    @media #{$breakpoint-below-tablet}  {
        padding: 19px 15px;
        height: auto;
    }
    .global-accordian-container {
        .global-accordian-header-container {
            padding: unset;
            display: flex;
            .global-accordian-header {
                font-family: $National2Medium;
                font-size: 16px;
                font-weight: 500;
                line-height: 24px;
                letter-spacing: 0px;
                text-align: left;
                color: #202124;
            }
        }
    }
}
