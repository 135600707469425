.deeplink-alert-btn-container {
	margin-top: 30px !important;
}

.alert-btn-container {
	.alert-confirm {
		width: 231px !important;
	}
}

.disposition-module-form {
	margin: 0 20%;
	text-align: $center;

	@media #{$breakpoint-below-tablet} {
		margin: 0 8%;
	}

		& .content-text {
			text-align: $center;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: 700;
			font-size: 38px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $Brand-Black;
	
			@media #{$breakpoint-below-tablet} {
				font-size: 20px;
				line-height: 22px;
				width: 66%;
				margin: auto;
			}
	
			@media #{$mobile-only} {
				width: 68%;
			}
		}

	& .info-text {
		margin-top: 5%;
		font-family: $National2Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		color: $Brand-Black;

		@media #{$breakpoint-below-tablet} {
			//margin-top: 10%;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: -0.1px;
		}

		@media #{$medium-only} {
			margin-top: 4%;
		}

		@media #{$breakpoint-tablet-only} {
			margin-top: 5%;
		}
	}

	& .form {
		display: flex;
		padding: 0 10%;
		flex-direction: column;

		& .disposition-button {
			margin: 1rem 0;
			@media #{$breakpoint-iphone-se-2020}{
				border: 1px solid #202124;
				box-shadow :none;
			}
		}
	}
}
