.offer-deals-container {
	background-color: #fff;
	margin-bottom: 20px;

	&.no-scroll {
		overflow: hidden !important;
	}

	.offer-card-container {
		display: flex;
		flex-wrap: wrap;
		margin-top: 50px;
		// justify-content: space-between;
		@media #{$breakpoint-below-tablet} {
			margin-top: 0 !important;
		}

		.offers-menu-product-image {
			width: 100%;
			height: 234px;
			margin-bottom: 10px;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}

		.offers-menu-product-full-image {
			width: 100%;
			height: 100%;
			margin-bottom: 10px;
			border-top-left-radius: 8px;
			border-top-right-radius: 8px;
		}
		.medium-card-offers-deals.store-card {
			display: flex;
			padding: 0;
			justify-content: center;
			margin-top: 0;
			.offer-store-text {
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 38px;
				line-height: 43px;
				text-align: center;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: #fff;
				position: absolute;
				top: 40%;
				width: 90%;
			}
		}

		.medium-card-offers-deals.login-card {
			display: flex;
			flex-direction: column;
			padding: 0;
			justify-content: center;
			margin-top: 0;
			background-color: $Brand-Red;

			.offer-login-text {
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 38px;
				line-height: 43px;
				text-align: center;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: #fff;
				width: 87%;
				margin: 24% auto 6% auto;
			}
		}

		.medium-card-offers-deals:nth-of-type(3n) {
			@media #{$breakpoint-above-tablet} {
				margin-right: 0 !important;
			}
		}

		.medium-card-offers-deals:nth-of-type(3n + 1) {
			@media #{$breakpoint-above-tablet} {
				margin-left: 0 !important;
			}
		}

		.medium-card-offers-deals:nth-of-type(2n) {
			@media #{$breakpoint-tablet-only} {
				margin-right: 0 !important;
			}
		}

		.medium-card-offers-deals:nth-of-type(2n + 1) {
			@media #{$breakpoint-tablet-only} {
				margin-left: 0 !important;
			}
		}

		.medium-card-offers-deals {
			position: relative;
			// margin-left: 30px;
			// width: 29.66%;
			width: 31.8%;
			height: 398px;
			font-family: $National2Regular;
			background: #fff;
			box-shadow: 0 4px 7px rgba(0, 0, 0, 0.1);
			border-radius: 8px;
			margin-bottom: 40px;
			margin-right: 2.3%;

			@media #{$mobile-only} {
				width: 100% !important;
				margin: 3% auto;
			}

			@media #{$breakpoint-tablet-only} {
				margin-right: 2%;
				width: 48.8% !important;
			}

			.coupon-time-block {
				display: flex;
				flex-wrap: nowrap;
				overflow: hidden !important;

				& img.coupon-time-icon {
					margin-top: 2.4%;
					width: auto;
					height: 14px;
					padding-right: 3%;
				}
			}

			.offersRibbon-container {
				position: absolute;
				left: -12px;
				top: 18px;
				z-index: 0.1;

				.offers-ribbon {
					position: relative;
				}

				.ribbon-text {
					position: absolute;
					top: 4px;
					left: 10px !important;
					font-family: $National2Condensed;
					font-style: normal;
					font-weight: 700;
					font-size: 16px;
					line-height: 20px;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: #e4002b;
					height: 20px !important;
				}

				.time-text {
					width: 150px;
					position: absolute;
					left: 4px !important;
					overflow: hidden !important;
				}

				.text-white {
					color: #fff !important;
				}
			}

			img.offers-store-image {
				object-fit: cover;
				border-radius: 8px;
				width: 100%;
				transform: scale(1.08) translateY(5px);

				@media #{$breakpoint-below-tablet} {
					transform: translateY(5px) scaleY(1.05) scaleX(1.055) translateX(1px) !important;
				}
			}

			.offer-store-btn-container {
				position: absolute;
				top: 75%;
				color: white;
				margin: auto;

				button.store-btn {
					padding: 11px 45px;
					border-radius: 200px;
					height: 44px;
					text-align: center;
					align-items: center;
					flex-direction: row;
					cursor: pointer;
					outline: none;
					margin: auto;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					display: $d-flex;
					background-color: transparent;
					border: 2px solid #fff;
					color: #fff;

					&:hover {
						cursor: pointer;
						color: $Brand-Black;
						background-color: $white;
					}

					&:focus-visible {
						color: $Brand-Black;
						background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #fff;
						border: 2px solid transparent !important;
						box-shadow: 0 0 0 2px #ffbd15;
						border-radius: 200px;
					}

					&:active {
						cursor: pointer;
						color: $Brand-Black;
						background: linear-gradient(0deg, rgba(32, 33, 36, 0.2), rgba(32, 33, 36, 0.2)), #fff;
					}

					&:disabled {
						background: transparent;
						color: #dbdbdb;
						opacity: 0.5;
						border: 1.5px solid #dbdbdb;
						cursor: not-allowed;
					}
				}
			}

			.offer-login-btn-container {
				margin: 5% auto auto auto;
				color: white;

				button.login-btn {
					padding: 11px 35px;
					border-radius: 200px;
					height: 44px;
					text-align: center;
					align-items: center;
					flex-direction: row;
					cursor: pointer;
					outline: none;
					margin: auto;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					display: $d-flex;
					background-color: transparent;
					border: 2px solid #fff;
					color: #fff;

					&:hover {
						cursor: pointer;
						color: $Brand-Black;
						background-color: $white;
					}

					&:focus-visible {
						color: $Brand-Black;
						background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #fff;
						border: 2px solid transparent !important;
						box-shadow: 0 0 0 2px #2E4ABD;
						border-radius: 200px;
					}

					&:active {
						cursor: pointer;
						color: $Brand-Black;
						background: linear-gradient(0deg, rgba(32, 33, 36, 0.2), rgba(32, 33, 36, 0.2)), #fff;
					}

					&:disabled {
						background: transparent;
						color: #dbdbdb;
						opacity: 0.5;
						border: 1.5px solid #dbdbdb;
						cursor: not-allowed;
					}
				}
			}

			.offers-card-content-text {
				.offers-card-title-desc {
					margin-top: 19.08%;
					text-align: center;

					.offers-deals-product-title {
						margin: 0 6% 5% 6%;
						font-style: normal;
						font-weight: 700;
						font-size: 38px;
						line-height: 43px;
						font-family: $National2Condensed;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						height: 86px;
						-webkit-box-orient: vertical;
					}

					.offers-deals-product-desc {
						margin: 0 6% 5% 6%;
						font-style: normal;
						font-weight: 400;
						height: 72px;
						font-family: $National2Regular;
						font-size: 14px;
						line-height: 24px;
						letter-spacing: -0.1px;
						color: #202124;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}

					.offers-deals-product-valid {
						text-align: center;
						font-style: normal;
						font-weight: 400;
						height: 24px;
						font-family: $National2Regular;
						font-size: 14px;
						line-height: 24px;
						letter-spacing: -0.1px;
						color: #202124;
					}
				}
			}

			.offers-card-content {
				margin-left: 20px;
				margin-right: 20px;
				margin-bottom: 20px;

				.offers-deals-product-title {
					margin-bottom: 5px;
					font-style: normal;
					color: #e4002b;
					font-weight: bold;
					font-size: 38px;
					line-height: 43px;
					font-family: $National2Condensed;
					overflow: hidden;
					text-overflow: ellipsis;
					text-align: center;
					display: -webkit-box;
					-webkit-line-clamp: 1;
					-webkit-box-orient: vertical;
				}

				.offers-deals-product-desc {
					font-style: normal;
					font-weight: 400;
					font-family: $National2Regular;
					height: 48px;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: -0.1px;
					color: #202124;
					overflow: hidden;
					text-align: center;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
			}

			.offer-button-container {
				display: flex;
				justify-content: inherit;
				position: absolute;
				bottom: -5%;
				align-items: baseline;
				width: 100%;
				margin-bottom: 30px;

				.view-details-btn {
					border: none;
					background: none;
					outline: none;
					font-family: $National2Medium;
					text-decoration: underline;
					padding: initial;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					color: #494949;
					margin: auto 0 auto auto;
					border-radius: 3px;

					&:hover {
						background-color: $Light-Gray;
					}

					&:active {
						background-color: #cfd0d0;
					}

					&:focus-visible {
						background: #fff;
						border: 2px solid #2e4abd;
					}

					&:disabled {
						opacity: 0.5;
						background: transparent;
						cursor: not-allowed !important;
					}
				}

				.redeem-btn {
					padding: 11px;
					width: 42.5%;
					border-radius: 200px;
					height: 44px;
					text-align: center;
					align-items: center;
					flex-direction: row;
					cursor: pointer;
					outline: none;
					margin: auto 7% 5px auto;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					font-family: $National2Medium;
					display: $d-flex;
					background-color: transparent;
					border: 1.5px solid $Brand-Black;
					color: $Brand-Black;

					&:hover {
						cursor: pointer;
						background-color: $Brand-Black;
						color: $white;
					}

					&:focus-visible {
						border: 2px solid $white;
						box-shadow: 0 0 0 2px $blue-done;
						background: $Brand-Black;
						color: $white;
					}

					&:active {
						cursor: pointer;
						background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), $Brand-Black;
					}

					&:disabled {
						background-color: transparent;
						color: $Brand-Black;
						cursor: not-allowed;
					}

					.btn-text {
						margin: auto;
					}
				}

				.image-full-text-btn {
					color: #fff;

					&:hover {
						background-color: $Dark-Gray;
					}

					&:active {
						background-color: $Light-Gray;
					}

					&:focus-visible {
						background: transparent;
						border: 2px solid #ffbd15;
					}

					&:disabled {
						opacity: 0.5;
						background: transparent;
						color: $Light-Gray;
						cursor: not-allowed !important;
					}
				}

				.redeem-button-full-image-text {
					color: #fff;
					border: 1.5px solid #fff;

					&:hover {
						cursor: pointer;
						color: $Brand-Black;
						background-color: $white;
					}

					&:focus-visible {
						color: $Brand-Black;
						background: linear-gradient(0deg, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.1)), #fff;
						border: 2px solid transparent !important;
						box-shadow: 0 0 0 2px #ffbd15;
						border-radius: 200px;
					}

					&:active {
						cursor: pointer;
						color: $Brand-Black;
						background: linear-gradient(0deg, rgba(32, 33, 36, 0.2), rgba(32, 33, 36, 0.2)), #fff;
					}

					&:disabled {
						background-color: transparent;
						color: #fff;
						cursor: not-allowed;
					}
				}
			}
		}
	}

	.load-more-container {
		width: fit-content;
		margin: 10px auto 100px auto;
		display: flex;
		flex-direction: column;

		@media #{$breakpoint-below-tablet} {
			margin-bottom: 40px !important;
		}

		button.load-btn {
			font-family: $National2Test, $National2Regular;
			font-style: normal;
			font-weight: 500;
			font-size: 14px;
			line-height: 24px;
			padding: 10px 40px;
			border: 1.5px solid $Brand-Black;
		}

		.page-desc {
			width: fit-content;
			margin: 10px auto 0;
			font-family: $National2Test, $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			color: $Dark-Gray;
		}
	}
}

.alert-toast {
	width: 298px;
	top: 451px;

	& .img-text-div {
		text-align: $center;
	}

	& .text {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 22px;
		font-family: $National2Regular;
		margin-left: 5px;
	}
}
