.lto-banner-container {
	margin: 80px 0px;
	position: relative !important;
	height: 424px;
	background: #F8F7F5;
}

/*
.lto-image {
	position: absolute;
    width: 42.6%;
    height: 504px;
    right: 6.6%;
    top: -40px;
}
.lto-text-section {
	height: 240px;
	width: 479px;
	left: 16%;
	top: 92px;
	border-radius: 200px;
	position: absolute;
}
*/
.lto-image{
    width: 52%;
    position: absolute;
    top: -2.5rem;
    right: 0;
	@media #{$breakpoint-above-tablet}{
		height: 504px;
	}
	@media #{$breakpoint-desktop-small}{
		width: 60%;
	}
	@media #{$breakpoint-samsung-galaxy-tab-s7-landscape}{
		width: 60%;
	}
}
.lto-text-section{
    width:45%;
    padding-top: 5.75rem;
		@media #{$breakpoint-medium-desktop} {
			padding-left: 1.15%;
		}
}
.smallTxtLTO {
	font-family: $National2Condensed;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 20px;
	letter-spacing: 2px;
	text-transform: $uppercase;
	color: $Brand-Black;
	margin-bottom: 10px;
	left:15.9%;
	top:21.7%;
}

.largeTextLTO {
	font-family: $National2Condensed;
	font-style: normal;
	font-weight: bold;
	font-size: 64px;
	letter-spacing: 1px;
	text-transform: $uppercase;
	color: $Brand-Black;
	line-height: 68px;
	margin-bottom: 30px;
}

@media #{$medium-only} {
	.lto-text-section {
		left: 10%;
	}
}
@media #{$breakpoint-between-mob-tablet} {
	.smallTxtLTO {
		font-size: 16px;
	}
	.largeTextLTO {
		font-size: 45px;
		width: 280px;
	}
}

@media #{$mobile-only} {
	
	.lto-banner-container {
		margin: 40px 0px;
		position: relative !important;
		height: 581px;
		width: 100%;
    	overflow-x: hidden;
		display: flex;
	}
	.lto-text-section {	
		margin-left: 20px;
		top: 60px;
	}
	.smallTxtLTO {
		height: 20px;
		width: 310px;
		left: 20px;
		top: 60px;
		border-radius: nullpx;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 2px;
	}
	.largeTextLTO {
		height: 92px;
		width: 315px;
		left: 20px;
		top: 90px;
		border-radius: nullpx;
		font-size: 42px;
		font-style: normal;
		font-weight: 700;
		line-height: 46px;
		letter-spacing: 1px;
		text-align: left;
		margin-bottom: 20px;
	}
	.lto-image {
		width: 89.5%;
    height: 275px;
    top: 46%;
		margin: 10px 20px 30px 20px;
		right: unset;
 }
}
@media #{$breakpoint-below-tablet} {
	.lto-banner-container {
		padding-left: 2rem;
		margin: 40px 20px;
		position: relative !important;
		height: 581px;
		width: auto;
    	overflow-x: hidden;
		display: flex;
	}
	.lto-text-section {	
		margin-left: 20px;
		top: 60px;
	}
	.smallTxtLTO {
		height: 20px;
		width: 310px;
		left: 20px;
		top: 60px;
		border-radius: nullpx;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 20px;
		letter-spacing: 2px;
	}
	.largeTextLTO {
		height: 92px;
		width: 315px;
		left: 20px;
		top: 90px;
		border-radius: nullpx;
		font-size: 42px;
		font-style: normal;
		font-weight: 700;
		line-height: 46px;
		letter-spacing: 1px;
		text-align: left;
		margin-bottom: 20px;
	}
	.lto-image {
		width: 89.5%;
    height: 275px;
    top: 46%;
		margin: 10px 20px 30px 20px;
		right: unset;
 }
}