.rest-map-view{
    height: 270px;
    @media #{$breakpoint-below-tablet} {
        height: 205px;
    }
}

.retaurant-view-container{
    .strips-Icon{
        width: 56px;
        height: 34px;
        img{
            width: inherit;
            height: inherit;
        }
    }
    .fav-icon{
        margin: 34px 0 8px 0px;
    }
    .store-name-rdp {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 64px;
        line-height: 76px;
        text-transform: uppercase;
        color: #202124;
        @media #{$breakpoint-below-tablet} {
            font-size: 36px;
            line-height: 36px;
            margin-bottom: 26px;
        }
    }
    .store-address{
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: #494949;
        width: 280px;
    }
    .store-channel-details{
        display: inline-flex;
        flex-wrap: wrap;
        grid-gap: 1%;
        gap: 1%;
        width: 204px;
        margin-top: 10px;
        .store-card-order-option{
            font-family: $National2Medium;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 22px;
            color: #494949;
            text-transform: capitalize;
            margin-right: 9px;
            img{
                margin-right: 5px;
            }
        }
    }
    @mixin restraunt-detail-titles {
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #202124;
        margin: 0;
    }
    .separator {
        background-color: #dbdbdb;
        height: 1px;
        margin: 26px 0 20px 0;
        width: 100%;
    }
    .restraunt-detail-wrapper {
        display: flex;

        .restraunt-detail-addr {
            .store-contact-wrapper {
                display: flex;
            }
            .store-contact {
                margin-top: 15px;
                .contact-icon {
                    width: 19px;
                    height: 19px;
                    position: relative;
                    left: -2px;
                }
            
                .contact-info {
                    font-family: $National2Regular;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 24px;
                    text-decoration-line: underline;
                    color: #202124;
                    position: relative;
                    top: -2px;
                }
            }
            .kfcdirection{
                font-family: $National2Regular;
                font-style: normal;
                // font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                text-decoration-line: underline;
                font-weight: bold;
                color: #202124;
                margin: 15px 0 16px 30px;
                display: block;
                span{
                    margin-left: 5px;
                }
            }
        }

        .restraunt-detail-cnt {
            @media #{$breakpoint-below-tablet} {
                margin-top: 42px;
            }
            .hour-blk-wrapper {
                margin-bottom: 80px;
                @media #{$breakpoint-below-tablet} {
                    margin: 0 -20px 50px -20px;
                }
                .hour-blk {
                    display: flex;
                    justify-content: space-between;

                    @media #{$breakpoint-below-tablet} {
                        padding: 0 20px;
                    }
                    .opening-hrs.info-timingClosed {
                        display: flex;
                    }

                    .closed-text:before {
                        background: #FF2E00;
                        display: inline-block;
                        content: '';
                        border-radius: 0.375rem;
                        height: 8px;
                        width: 8px;
                        margin-right: 0.3rem;
                    }
                    .info-timingClosed .list-circle {
                        display: list-item;
                        color: $Red-Orange !important;
                        margin-left: 23px;
                        left: 19px;
                    }
                    .closed-text {
                        color: $Red-Orange !important;
                    }

                    .hour-title {
                        @include restraunt-detail-titles;
                        @media #{$breakpoint-below-tablet} {
                            font-size: 20px;
                        }
                    }
                    .opening-hrs {
                        font-family: $National2Regular;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 24px;

                        .open-text {
                            font-family: $National2Regular;
                            font-style: normal;
                            font-weight:400;
                            font-size: 16px;

                            &:before {
                                background: #008300;
                                display: inline-block;
                                content: '';
                                border-radius: 0.375rem;
                                height: 8px;
                                width: 8px;
                                margin-right: 0.3rem;
                            }
                        }
                    }

                }
                .accordian-blk {
                    margin-top: 26px;
                    border-top: 1px solid #dbdbdb;
                    @media #{$breakpoint-below-tablet} {
                        margin-top: 16px;
                    }

                    .global-accordian-content {
                        margin: 38px 0 0 26px;
                        @media #{$breakpoint-below-tablet} {
                            margin: 36px 0 0 20px;
                        }
                    }
                    .global-accordian-header-container {
                        padding-left: 26px;
                        cursor: initial;
                        @media #{$breakpoint-below-tablet} {
                            padding-left: 20px;
                        }
                        .global-accordian-header {
                            font-family: $National2Medium;
                            color: #202124;
                        }
                    }
                    .global-accordian-arrow {
                        position: relative;
                        right: 15px;
                        cursor: pointer;
                    }

                    .global-accordian-header{
                        font-family: $National2Medium;
                        color: $Brand-Black;
                    }
                    .hours-view {
                        width: 100%;
                        margin: 12px 0;
                        .dispotion-hours-text{
                          font-family: $National2Regular;
                          font-style: normal;
                          font-weight: bold;
                          font-size: 14px;
                          line-height: 24px;
                          color: $black;
                          margin: 12px 0 7px 0;
                        }
                        .hours-text{
                          width: 100%;
                          font-family: $National2Regular;
                          font-style: normal;
                          font-weight: bold;
                          font-size: 14px;
                          line-height: 25px;
                          display: flex;
                          align-items: center;
                          letter-spacing: -0.1px;
                          color: $black;
                        }
                        .day-details{
                          width: 50%;
                        }
                        .special-day{color: $step-completed-color;}
                        .time-details{
                          width: 50%;
                          text-align: right;
                          margin-right: 20%;
                          @media #{$breakpoint-below-tablet} {
                            margin-right: 20px;
                            }
                        }
                      }
                }
                .rdp-accordian-container {
                    padding: 36px 0;
                    border-bottom: 2px solid #e3e3e3;
                }
                .rdp-accordian-open {
                    background-color: #f8f7f5;
                }
            }
            .amenities-blk {
                margin-bottom: 80px;
                display: grid;
                @media #{$breakpoint-below-tablet} {
                    margin-bottom: 50px;
                }
                .amenities-title {
                    @include restraunt-detail-titles;
                    @media #{$breakpoint-below-tablet} {
                        font-size: 20px;
                    }
                }
                .amenities-img {
                    width: 28px;
                    height: 28px;
                    margin-right: 11px;
                }
                .amenities-list-wrapper {
                    padding: 0;
                    list-style-type: none;
                }
                .amenities-list {
                    width: 50%;
                    float: left;
                    font-family: $National2Medium;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 24px;
                    color: #202124;
                    margin-bottom: 15px;
                    text-transform: capitalize;
                    @media #{$mobile-tablet-only} {
                        width: 100%;
                        font-size: 14px;
                        margin-bottom: 13px;
                    }
                }
            }
            .delivery-blk {
                margin-bottom: 120px;
                @media #{$breakpoint-below-tablet} {
                    margin-bottom: 80px;
                }
                .delivery-not-available {
                    font-size: 16px;
                    font-weight: 500;
                    font-family: $National2Regular;
                }
                .delivery-title {
                    @include restraunt-detail-titles;
                    @media #{$breakpoint-below-tablet} {
                        font-size: 20px;
                    }
                }
                .kfc-lockup {
                    display: flex;
                    width: 336px;
                    height: 180px;
                    background: #F8F7F5;
                    justify-content: space-around;
                    align-items: center;
                    text-decoration: none;

                    .KFCbucket {
                        width: 85px;
                        height: auto;
                    }                    

                    .kfc-lockup-cnt {
                        @include restraunt-detail-titles;
                        color: #494949;
                        font-size: 20px;
                        line-height: 22px;
                        width: 101px;
                        text-align: center;
                    }
                }
                .aggregator-logo-wrap {
                    width: 100%;
                    margin-top: 20px;
                    
                    .aggregator-logo {
                        text-align: center;
                        text-decoration: none;
                        display: inline-block;
                        margin-right: 12px;
                        &.nolink {
                            pointer-events: none;
                            cursor: default;
                        }
                        @media #{$breakpoint-below-tablet} {
                            margin-right: 2px;
                        }

                        &-img {
                            width: 162px;
                            height: 100px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                        }

                        .aggregator-url {
                            font-family: $National2Regular;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 24px;
                            color: #202124;
                            text-transform: capitalize;

                            img {
                                margin-left: 10px;
                            }
                        }
                    }
                }
            }            
        }
    }
}