.contactUs-desktop-container {
	display: flex;
	padding-left: 104px;
	padding-right: 130px;

	@media #{$breakpoint-above-desktop} {
		padding-left: 143px;
	}

	.contactUs-section-left {
		width: 294px;

		.contactUs-header-container {

			.contactUs-icon {
				margin-bottom: 30px;
			}

			.contactUs-header {
				margin-bottom: 20px;
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 38px;
				line-height: 43px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $Brand-Black;
			}

			.contactUs-subheader {
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: -0.1px;
				color: $Dark-Gray;
				border-bottom: 1px solid $light-grey;
				padding-bottom: 30px;
			}

			.button-container {
				display: flex;
				flex-direction: column;
				margin-top: 30px;

				.contactus-button {
					padding: 5px 15px;
					margin-bottom: 20px;
					width: 242px;
				}

				.icon {
					margin-right: 10px;
				}
			}
		}
	}

	.contactUs-section-right {
		margin-left: 120px;
		width: 780px;
		margin-top: 100px;
		border-top: 2px solid $Brand-Black;

		@media #{$breakpoint-above-desktop} {
			margin-left: 288px;
			width: 1000px;
		}

		.contactUs-title {
			margin-top: 60px;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 24px;
			letter-spacing: 0.5px;
			text-transform: uppercase;
			color: $Brand-Black;
		}

		.select-topic-container {
			background: $Mashed-Potato-Gray;
			border-radius: 3px;
			padding: 30px 60px;
			margin-top: 20px;
			display: flex;
			flex-direction: column;

			.select-topic-label {
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 25px;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				color: $Brand-Black;
				margin-bottom: 0px;
			}

			.select-topic-selection-conatiner {
				display: flex;
				flex-direction: column;

				.select-topic-selection {
					border: 0;
					border-bottom: 1px solid $Brand-Black;
					font-family: $National2Regular;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					background-color: $Mashed-Potato-Gray;
					color: $Brand-Black;
					padding-bottom: 10px;
					margin-top: 15px;
					background-image: url("../../../styles//staticAssets/images/dropdownArrowBlack.svg");
					background-repeat: no-repeat;
					background-position: right;
					cursor: unset;
				}

				.inverted-arrow {
					background-image: url("../../../styles//staticAssets/images/SingleArrowUp.svg");
				}

				.select-topic-option-conatiner {
					display: flex;
					width: 46%;
					position: absolute;
					margin-top: 49px;
					flex-direction: column;
					background: $Brand-Black;
					box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
					border-radius: 3px;
					padding: 10px 0 20px 0;

					@media #{$large-only } {
						width: 38%;
					}

					@media #{$breakpoint-below-tablet } {
						width: 36%;
					}

					@media #{$breakpoint-between-tablet-desktop} {
						width: 44%;
					}

					.select-topic-option {
						height: 36px;
						font-family: $National2Regular;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 24px;
						color: $white;
						padding: 5px 14px;
						cursor: pointer;
					}

					.select-topic-option:hover {
						height: 36px;
						width: 100%;
						font-family: $National2Regular;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 24px;
						background: #363639;
						color: $white;
						padding: 5px 14px;
						cursor: pointer;
					}
				}
			}
		}

		.contactUs-questions-container {
			padding-top: 50px;
			display: flex;
			flex-direction: column;

			.questions-form-header {
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 25px;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				color: $Brand-Black;
				margin-bottom: 0px;
			}

			.questions-form-subheader {
				margin-top: 10px;
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: -0.1px;
				color: $Brand-Black;
			}

			.questions-form-container1 {
				background: $Mashed-Potato-Gray;
				border-radius: 3px;
				padding: 30px 60px;
				margin-top: 20px;
				display: flex;
				flex-direction: column;

				.container1-header {
					font-family: $National2Condensed;
					font-style: normal;
					font-weight: bold;
					font-size: 18px;
					line-height: 25px;
					letter-spacing: 0.5px;
					text-transform: uppercase;
					color: $Brand-Black;
					margin-bottom: 0px;
				}

				.container1-input {
					height: 94px;
					margin-top: 15px;
					border: 1px solid $Dark-Gray;
					box-sizing: border-box;
					border-radius: 3px;
					background: $Mashed-Potato-Gray;
					padding: 8px 15px;
				}

				.container1-input::placeholder {
					font-family: $National2Regular;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: -0.1px;
					color: $Brand-Black;
					opacity: 0.5;
				}
			}

			.questions-form-container2 {
				background: $Mashed-Potato-Gray;
				border-radius: 3px;
				padding: 30px 60px;
				margin-top: 20px;
				display: flex;
				flex-direction: column;

				.container2-header {
					font-family: $National2Condensed;
					font-style: normal;
					font-weight: bold;
					font-size: 18px;
					line-height: 25px;
					letter-spacing: 0.5px;
					text-transform: uppercase;
					color: $Brand-Black;
					margin-bottom: 0px;
				}

				.radio-container {
					display: flex;
					flex-direction: column;

					.form-radio-wrapper {
						display: flex;
						flex-direction: row;
						margin-top: 15px;
						align-items: center;

						input[type='radio'] {
							margin-right: 10px;
							appearance: none;
							height: 16px;
							width: 16px;
							background-color: transparent;
							border-radius: 100%;
							cursor: pointer;
							outline: none;
							display: flex;
							align-items: center;
							justify-content: center;
							border: 1px solid $Dark-Gray;
							box-sizing: border-box;
						}

						input[type='radio']::after {
							content: '•';
							color: $Brand-Black;
							font-size: 2em;
							display: none;
							margin: 0 1.5px 4.7px 1.5px;
						}

						input[type='radio']:checked {
							background-color: inherit;
						}

						input[type='radio']:checked::after {
							display: block;
						}

						span {
							font-family: $National2Regular;
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 24px;
							letter-spacing: -0.1px;
							color: $Brand-Black;
						}
					}
				}
			}

			.questions-form-container3 {
				background: $Mashed-Potato-Gray;
				border-radius: 3px;
				padding: 30px 60px;
				margin-top: 20px;
				display: flex;
				flex-direction: column;

				.container3-header-block{
					display: flex;
					justify-content: space-between;

					.mandatory-note{
						font-family: $National2Regular;
						font-size: 12px;
						font-style: normal;
						font-weight: 400;
						line-height: 22px;
						letter-spacing: 0px;
						color: #494949;
						margin: 0px;
						padding-top: 5px;
					}

					.container3-header {
						font-family: $National2Condensed;
						font-style: normal;
						font-weight: bold;
						font-size: 18px;
						line-height: 25px;
						letter-spacing: 0.5px;
						text-transform: uppercase;
						color: $Brand-Black;
						margin-bottom: 15px;
					}

				}

				.input-conatiner {
					margin-bottom: 10px;
				}

				.input {
					background: $Mashed-Potato-Gray;
				}

				@-moz-document url-prefix() {
					.input::-moz-placeholder {
						color: transparent;
					}
				}
			}

			.captcha {
				margin-top: 30px;
				align-self: center;
			}

			.button-container {
				margin-top: 40px;
				margin-bottom: 120px;
				display: flex;
				align-self: center;

				.contactus-button {
					padding: 5px 15px;
					width: 124px;
					height: 44px;
					margin: 0 9px;
				}
			}
		}
	}
}

.contactUs-mobile-container {
	.contactUs-mobile-header {
		margin-bottom: 40px;
		padding-right: 20px;
		padding-left: 20px;

		.contactUs-icon {
			margin-bottom: 20px;
		}

		.contactUs-header {
			margin-bottom: 10px;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 24px;
			line-height: 32px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $Brand-Black;
		}

		.contactUs-subheader {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 16px;
			line-height: 24px;
			letter-spacing: -0.1px;
			color: $Dark-Gray;
			border-bottom: 1px solid $light-grey;
			padding-bottom: 30px;
		}

		.button-container {
			display: flex;
			flex-direction: row;
			margin-top: 30px;

			.contactus-button {
				width: 160px;
				margin-bottom: 20px;
				padding: 5px 5px;
			}

			.space {
				width: 15px;
			}

			.icon {
				margin-right: 10px;
			}
		}
	}

	.contactUs-section-body {
		.contactUs-title {
			margin-top: 60px;
			font-family: $National2Condensed;
			font-style: normal;
			font-weight: bold;
			font-size: 20px;
			line-height: 22px;
			letter-spacing: 1px;
			text-transform: uppercase;
			color: $Brand-Black;
			padding-right: 20px;
			padding-left: 20px;
		}

		.select-topic-container {
			background: $Mashed-Potato-Gray;
			padding: 30px 20px;
			margin-top: 20px;
			display: flex;
			flex-direction: column;

			.select-topic-label {
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 20px;
				letter-spacing: 1px;
				text-transform: uppercase;
				color: $Brand-Black;
				margin-bottom: 0px;
			}

			.select-topic-selection-conatiner {
				display: flex;
				flex-direction: column;

				.select-topic-selection {
					border: 0;
					border-bottom: 1px solid $Brand-Black;
					font-family: $National2Regular;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
					background-color: $Mashed-Potato-Gray;
					color: $Brand-Black;
					padding-bottom: 10px;
					margin-top: 15px;
					background-image: url("../../../styles//staticAssets/images/dropdownArrowBlack.svg");
					background-repeat: no-repeat;
					background-position: right;
					cursor: unset;
				}

				.inverted-arrow {
					background-image: url("../../../styles//staticAssets/images/SingleArrowUp.svg");
				}

				.select-topic-option-conatiner {
					display: flex;
					left: 2%;
					right: 2%;
					position: absolute;
					margin-top: 49px;
					flex-direction: column;
					background: $Brand-Black;
					box-shadow: 0 4px 4px rgba(0, 0, 0, 0.1);
					border-radius: 3px;
					padding: 10px 0 20px 0;

					.select-topic-option {
						height: 36px;
						font-family: $National2Regular;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 24px;
						color: $white;
						padding: 5px 14px;
					}

					.select-topic-option:hover {
						height: 36px;
						width: 100%;
						font-family: $National2Regular;
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 24px;
						background: #363639;
						color: $white;
						padding: 5px 14px;
					}
				}
			}
		}

		.contactUs-questions-container {
			padding-top: 30px;
			display: flex;
			flex-direction: column;

			.questions-form-header {
				font-family: $National2Condensed;
				font-style: normal;
				font-weight: bold;
				font-size: 18px;
				line-height: 25px;
				letter-spacing: 0.5px;
				text-transform: uppercase;
				color: $Brand-Black;
				padding-right: 20px;
				padding-left: 20px;
				margin-bottom: 0px;
			}

			.questions-form-subheader {
				margin-top: 10px;
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
				letter-spacing: -0.1px;
				color: $Brand-Black;
				padding-right: 20px;
				padding-left: 20px;
			}

			.questions-form-container1 {
				background: $Mashed-Potato-Gray;
				padding: 30px 20px;
				margin-top: 10px;
				display: flex;
				flex-direction: column;

				.container1-header {
					font-family: $National2Condensed;
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 20px;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: $Brand-Black;
					margin-bottom: 0px;
				}

				.container1-input {
					height: 94px;
					margin-top: 20px;
					border: 1px solid $Dark-Gray;
					box-sizing: border-box;
					border-radius: 3px;
					background: $Mashed-Potato-Gray;
					padding: 8px 15px;
				}

				.container1-input::placeholder {
					font-family: $National2Regular;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: -0.1px;
					color: $Brand-Black;
					opacity: 0.5;
				}
			}

			.questions-form-container2 {
				background: $Mashed-Potato-Gray;
				padding: 30px 20px;
				margin-top: 10px;
				display: flex;
				flex-direction: column;

				.container2-header {
					font-family: $National2Condensed;
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 20px;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: $Brand-Black;
					margin-bottom: 0px;
				}

				.radio-container {
					margin-bottom: 5px;
					display: flex;
					flex-direction: column;

					.form-radio-wrapper {
						display: flex;
						flex-direction: row;
						margin-top: 15px;
						align-items: center;

						input[type='radio'] {
							margin-right: 10px;
							appearance: none;
							height: 16px;
							width: 16px;
							background-color: transparent;
							border-radius: 100%;
							cursor: pointer;
							outline: none;
							border: 1px solid #000;
							display: flex;
							align-items: center;
							justify-content: center;
						}

						input[type='radio']::after {
							content: '•';
							color: $Brand-Black;
							font-weight: 900;
							font-size: 1.4em;
							display: none;
							margin: 0 1.5px 3px 1.5px;
						}

						input[type='radio']:checked {
							background-color: inherit;
						}

						input[type='radio']:checked::after {
							display: block;
						}

						span {
							font-family: $National2Regular;
							font-style: normal;
							font-weight: normal;
							font-size: 14px;
							line-height: 24px;
							letter-spacing: -0.1px;
							color: $Brand-Black;
						}
					}
				}
			}

			.questions-form-container3 {
				background: $Mashed-Potato-Gray;
				padding: 30px 20px;
				margin-top: 10px;
				display: flex;
				flex-direction: column;

				.container3-header {
					font-family: $National2Condensed;
					font-style: normal;
					font-weight: bold;
					font-size: 16px;
					line-height: 20px;
					letter-spacing: 1px;
					text-transform: uppercase;
					color: $Brand-Black;
					margin-bottom: 15px;
				}

				.input-conatiner {
					margin-bottom: 10px;
				}

				.input {
					background: $Mashed-Potato-Gray;
				}

				@-moz-document url-prefix() {
					.input::-moz-placeholder {
						color: transparent;
					}
				}
			}

			.captcha {
				margin-top: 30px;
				align-self: center;
			}

			.button-container {
				margin-top: 40px;
				margin-bottom: 60px;
				display: flex;
				align-self: center;

				.contactus-button {
					padding: 5px 15px;
					width: 124px;
					height: 44px;
					margin: 0 9px;
				}
			}
		}
	}
}

.error-text {
	padding-top: 6px;
	color: #cc2400;
	font-family: $National2Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
}

.submit-alert-conatiner {
	display: flex;
	flex-direction: column;

	.alert-title {
		font-family: $National2Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 16px;
		line-height: 20px;
		text-transform: unset;
		color: $Brand-Black;
		margin-bottom: 30px;
		align-self: flex-start;
	}

	.alert-message {
		font-family: $National2Regular;
		font-style: normal;
		font-weight: normal;
		height: 32px;
		font-size: 16px;
		line-height: 20px;
		color: $Brand-Black;
		margin-bottom: 18px;
	}

	.alert-button {
		background-color: $Brand-Red;
		margin: 0;
		font-family: $National2Regular;
		font-size: 15px;
		align-self: flex-end;
		border-radius: 5px;
		width: 115px;
		height: 34px;
	}
}

.conatct-us-alet-modal {
	z-index: $z-index-max + 1;
	width: 100%;

	& .modal-content {
		width: 450px;
		height: 170px;
		left: 38%;
		top: 34%;
		box-shadow: 0 4px 9px rgba(0, 0, 0, 0.15);

		@media (min-width: 200px) and (max-width: 1023px) {
			left: 2%;
			width: 96%;
		}

		& .modal-header {
			height: 0;
			padding-top: 10px;
			border-bottom: none;

			.custom-modal-close {
				display: none;
			}
		}

		& .modal-body {
			padding: 0 15px 15px 15px;
			box-shadow: none;
			background-color: $white;
		}
	}
}
