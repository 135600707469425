.delivery-module-form {
  margin: 0 15%;

  @media #{$large-only} {
    margin: 0 10%;
  }

  @media #{$breakpoint-below-tablet} {
    margin: 0 6%;
  }

  @media #{$breakpoint-below-iphone5} {
    margin: 0 5%;
  }

  @media #{$mobile-only} {
    margin: 0 1%;
  }

  ::placeholder {
    /* Firefox */
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Brand-Black;
    opacity: 0.7;
    padding-left: 3%;

    @media #{$breakpoint-below-tablet} {
      padding-left: 4%;
    }
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 & Edge */
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: $Brand-Black;
    opacity: 0.7;
    padding-left: 3%;

    @media #{$breakpoint-below-tablet} {
      padding-left: 4%;
    }
  }

  .login-section {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 142px);

    @media #{$breakpoint-below-tablet} {
      width: 100%;
    }

    .delivery-member-signin {
      font-size: 14px;
      padding-top: 1px;

      .delivery-signin-link {
        font-size: 14px;
        color: $Brand-Black !important;
        margin-left: 3px;
        text-decoration: underline;
        text-underline-offset: 1px;
        text-decoration-skip-ink: none;
        @-moz-document url-prefix() {
          text-underline-offset: 2px;
        }
      }
    }

    .delivery-location-link {
      color: $Brand-Black !important;
      vertical-align: middle;
      text-decoration: underline;
      text-underline-offset: 1px;
      text-decoration-skip-ink: none;
      @-moz-document url-prefix() {
        text-underline-offset: 2px;
      }

      .location-img {
        margin-right: 5px;
      }
    }
  }

  .delivery-address-section {
    display: flex;
    align-items: baseline;

    @media #{$breakpoint-below-tablet} {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .search-delivery-address {
      width: 100%;
      margin-right: 10px;

      @media #{$breakpoint-below-tablet} {
        margin-right: 0;
      }

      .input-delivery-address {
        position: relative;

        input {
          margin-top: 17px !important;
          padding-left: 11.5%;
          padding-right: 10%;
          padding-top: 2%;
          padding-bottom: 2%;
          width: 100%;

          @media #{$breakpoint-below-tablet} {
            padding-left: 13%;
            padding-right: 13%;
          }

          @media #{$breakpoint-above-desktop} {
            padding-left: 7.5%;
          }
        }

        & input::placeholder {
          padding-left: 0 !important;
        }

        & input::-webkit-input-placeholder {
          /* WebKit browsers */
          padding-left: 0 !important;
        }

        & input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          padding-left: 0 !important;
        }

        & input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          padding-left: 0 !important;
        }

        & input:-ms-input-placeholder {
          /* Internet Explorer 10+ */
          padding-left: 0 !important;
        }

        .suggestion-list {
          margin-top: 2%;
          border-radius: 4px;
          position: absolute;
          z-index: 3;
          max-height: 45vh;
          overflow-y: auto;
          width: 100%;
          background-color: $Mashed-Potato-Gray;

          & .suggetion-pin {
            position: relative;
            bottom: 1px;
          }

          .suggestion-item-container {
            background-color: $Brand-Black;
            &:hover {
              background-color: #f8f7f5;
              opacity: 0.9;
            }
          }

          & .suggestion-items {
            background-color: $Brand-Black;
            color: white;
            font-family: $National2Regular;
            padding: 1% 1% 1% 3%;
            cursor: pointer;
          }

          & .suggest-text {
            font-family: $National2Regular;
            font-weight: 400;
            padding-left: 3%;
            color: rgba(255, 255, 255, 0.5);
          }

          .suggested-address-alignment-delivery {
            display: flex;
            flex-direction: row;

            & .suggestion-description {
              padding-left: 10px;
            }
          }
        }
      }
    }

    .delivery-confirm-button {
      &.delivery-confirm-button-in {
        position: relative;
        &[data-loading='true']::after {
          content: '';
          box-sizing: border-box;
          position: absolute;
          top: 50%;
          left: 50%;
          width: 20px;
          height: 20px;
          margin-top: -0.71428571rem;
          margin-left: -0.71428571rem;
          border-radius: 50%;
          border: 2px solid #fff;
          border-top-color: #000;
          -webkit-animation: button-spinner 0.8s linear infinite;
          animation: button-spinner 0.8s linear infinite;
          box-shadow: none;
        }
      }
      @media #{$breakpoint-below-tablet} {
        margin-top: 15px;
      }
    }

    .store-search-input {
      width: 80%;
      border: 1px solid $Inactive-Gray;
      box-sizing: border-box;
      border-radius: 200px;
      height: 44px;
    }

    .store-search-input.error-border {
      border: 1px solid $Red-Orange;
    }

    @media #{$breakpoint-below-tablet} {
      @media ($minMozDevicePixelRatio) {
        .store-search-input {
          padding-left: 44px !important;
          padding-top: 10px !important;
        }
      }
    }

    .google-location-img {
      top: 50%;
      position: absolute;
      left: 22px;

      @media #{$breakpoint-below-tablet} {
        @media ($minMozDevicePixelRatio) {
          .store-search-input {
            padding-left: 44px !important;
            padding-top: 10px !important;
            margin-top: 2.5% !important;
          }
        }
      }

      @media #{$breakpoint-below-tablet} {
        //top: 28px;
        //width: 12px;
      }

      @media #{$mobile-only} {
        //top: 23px;
        //width: 8px;
      }
    }

    .clearTextIcon {
      position: absolute;
      top: 50%;
      right: 20px;
      cursor: pointer;

      @media #{$breakpoint-between-mob-tablet} {
        top: 50%;
        right: 20px;
      }
    }
  }

  .store-form-separation {
    border-top: 1px solid $Light-Gray;
    margin-top: 40px !important;
    margin-bottom: 0 !important;

    @media #{$breakpoint-below-tablet} {
      margin-top: 20px !important;
      margin-bottom: 0 !important;
    }
  }

  .map-button-form {
    display: flex;
    justify-content: center;
    margin-top: 30px;

    @media #{$breakpoint-below-tablet} {
      margin-top: 20px;
    }

    .show-map-div {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .delivery-map-text {
        font-family: $National2Regular;
        font-style: italic;
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        color: $Brand-Black;
        opacity: 0.8;
      }

      .map-link {
        color: $Brand-Black !important;
        font-weight: 500 !important;
        font-family: $National2Medium;
        font-size: 14px !important;
        line-height: 24px !important;
        text-decoration: underline;
        text-decoration-skip-ink: none;
        text-transform: capitalize;

        & .map-img {
          margin-right: 5px;
          height: 10px;
          width: 11px;
        }
      }
    }
  }
}

// .delivery-map.takeawayDineInMap{
// 	.gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom{
// 		bottom: 60%!important; //calc(100% - 75px)
// 	}
// }

.delivery-map.takeawayDineInMap {
  height: 60%;

  @media #{$breakpoint-above-desktop} {
    height: 74% !important;
  }

  @media #{$breakpoint-below-desktop-above-tablet} {
    height: 53% !important;
  }

  @media #{$breakpoint-below-tablet} {
    @media (orientation: landscape) {
      height: 100% !important;
    }
  }

  & .store-card-mobile-view {
    @media #{$breakpoint-below-tablet} {
      @media (orientation: landscape) {
        width: 50% !important;
        bottom: unset !important;
        padding: 3% !important;
      }
    }
  }
}

.deliver-map-zoom {
  .gmnoprint.gm-bundled-control.gm-bundled-control-on-bottom {
    @media #{$mobile-tablet-only} {
      bottom: 150px !important;
    }
  }
}

.delivery-map {
  margin: 40px -3% -3%;
  height: 81%;
  display: grid;

  @media #{$breakpoint-below-tablet} {
    height: 79.5% !important;
  }

  @media screen and (max-width: 575px) {
    height: 40% !important;
  }

  & .search-store-card-btn {
    @media #{$breakpoint-below-tablet} {
      margin: 0 1%;
      padding: 11px 18px;
    }
  }

  & .view-map {
    display: flex;
    height: 100%;
  }
}

.MuiAlert-icon {
  display: none !important;
}

.MuiSnackbar-anchorOriginTopCenter {
  top: 50% !important;
}

.MuiAlert-standardSuccess {
  color: white !important;
  background-color: black !important;
}

.success-img {
  padding: 0 10px 0 0;
}

.map-top-note {
  position: absolute;
  display: flex;
  width: 99%;
  justify-content: center;
  z-index: 1;
}

.pointer-disabled {
  pointer-events: none;
}

.map-top-note-text {
  font-family: $National2Regular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: $Dark-Gray;
  background: $white;
  border: 1px solid $Light-Gray;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px 30px 6px 30px;
  margin-top: 2%;
}

.map-arrow-right {
  /* position: absolute;
	left: 89%; */
  bottom: 0;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 66px;
  transform: rotate(-180deg);
  z-index: 1;
  height: 32.00000000000012px;
  width: 32.00000000000012px;

  img {
    height: 18px;
    width: 24.5px;
    left: 0;
    top: 6px;
    position: absolute;
    border-radius: 0;
  }
}

.map-arrow-left {
  /* position: absolute;
	left: 78%; */
  bottom: 0;
  background: white;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  border-radius: 66px;
  z-index: 1;
  height: 32.00000000000012px;
  width: 32.00000000000012px;

  img {
    height: 18px;
    width: 24.5px;
    left: 2px;
    top: 6px;
    position: absolute;
    border-radius: 0;
  }
}

#google-map {
  width: 100%;
}
.takeawayDineInMap {
  @media #{$mobile-only} {
    #google-map {
      min-height: 350px !important;
      max-height: 350px !important;
    }
  }
  @media #{$breakpoint-iphone-se-2020} {
    #google-map {
      min-height: auto !important;
    }
  }
}
.alert-location-popup {
  z-index: 2;
  width: 66%;
  margin: 0 15% 0 14%;
  padding: 2% 0;
  height: 195px;
  background: $white;
  border: 1px solid $Light-Gray;
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  margin-top: 2%;
  display: flex;
  align-items: center;
  // justify-content: center;
  flex-direction: column;
  font-family: $National2Regular;

  @media #{$breakpoint-below-tablet} {
    width: 100%;
    //margin-left: 8px;
    height: auto; //160px;
    padding-bottom: 6%;
    position: unset;
    margin: 10px auto;
    top: 210px; //175px;
  }

  .location-alert-icon {
    margin-top: 26px;
  }

  .location-error-message {
    margin-top: 8px;
    font-size: 14px;
    font-weight: normal;
    color: $Red-Orange;
  }

  .location-error-address {
    margin-top: 7px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }

  .location-error-button-section {
    margin-top: 20px;
    display: flex;

    .ml {
      margin-left: 7px;
      padding-left: 20px;
      padding-right: 20px;
      @media #{$breakpoint-iphone-11-pro-portrait} {
        padding: 2px 12px;
      }
      @media only screen and (min-width: 375px) {
        padding: 11px 35px !important;
      }
    }

    .mr {
      margin-right: 7px;
    }
  }

  & .button {
    @media #{$breakpoint-below-tablet} {
      padding: 11px 50px;
    }

    @media #{$breakpoint-below-iphone5} {
      padding: 11px 24px;
    }

    @media #{$breakpoint-below-include-iphone5SE} {
      padding: 10px 24px;
    }
  }

  & .suggested-address-unit {
    font-family: $National2Regular;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin-left: 10px;
  }
}

.error-popup-saved-address {
  width: 66%;
  // margin: 0 15% 0 14%;
  height: 170px;
  background: $white;
  border: 1px solid $Light-Gray;
  box-sizing: border-box;
  border-radius: 8px;
  position: absolute;
  top: 35%;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-family: $National2Regular;
  z-index: 4;

  @media #{$breakpoint-below-tablet} {
    width: 90%;
    margin: 0 auto;
    height: 160px;
    // position: absolute;
    // top: 200px;
    margin-left: -10px;
  }

  .location-alert-icon {
    margin-top: 23px;
  }

  .location-error-message {
    margin-top: 5px;
    font-size: 14px;
    font-weight: normal;
    // color: $Error-State;
  }

  .location-error-address {
    margin-top: 3px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    color: #ff2e00;
  }

  .location-error-button-section {
    margin-top: 20px;
    display: flex;

    .ml {
      margin-left: 7px;
    }

    .mr {
      margin-right: 7px;
    }
    @media #{$breakpoint-below-iphone5} {
      margin-top: 0px !important;
      .button {
        font-size: 11px !important;
      }
    }
    @media #{$breakpoint-below-tablet} {
      .button {
        padding: 11px 5px !important;
      }

      .mr {
        margin-right: unset !important;
      }

      .ml {
        margin-left: 6px;
      }

      .retrybtn {
        padding: 11px 40px !important;
      }
    }
  }
}

.error-adjust {
  // width: 100% !important;
  margin: 0 0 0 0 !important;

  @media #{$breakpoint-below-tablet} {
    top: unset;
  }
}

.error-popup-saved-address-map-view {
  top: 55% !important;

  @media #{$breakpoint-below-tablet} {
    top: 220px !important;
  }
}
.alert-location-popup1 {
  z-index: 2;
  padding: 6% 15%;
  height: 174px;
  left: -2px;
  background: $white;
  border: 1px solid $Light-Gray;
  box-sizing: border-box;
  border-radius: 8px;
  margin-top: 2%;
  text-align: center;
  // justify-content: center;
  //flex-direction: column;
  font-family: $National2Regular;
  @media #{$breakpoint-below-tablet} {
    width: 90%;
    margin-left: 8px;
    height: auto; //160px;
    padding-bottom: 6%;
    position: absolute;
    top: 210px; //175px;
  }
}

.global-map {
  margin: 0;
}
