.savingdivDelivery{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
    .savingText{
        text-transform: uppercase;
        font-family: $National2Condensed;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 22px;
        letter-spacing: 1px;
        margin-right: 40px;
         @media #{$breakpoint-below-tablet} {
             margin-right: 0px;
             margin-bottom: 20px;
             width: 255px;
             text-align: center;
         }
    }
    @media #{$breakpoint-below-tablet} {
        display: flex;
        flex-direction: column;
    }
}