.site-footer {
	background-color: $Brand-Black;
	padding: 5% 5% 0% 5%;
	font-size: 15px;
	line-height: 24px;
	color: #fff;

	& select {
		-moz-appearance: none; /* Firefox */
		-webkit-appearance: none; /* Safari and Chrome */
		appearance: none;
	}

	// @media #{$breakpoint-below-tablet} {
	// 	padding-bottom: 25%;
	// }

	& .row {
		margin-top: 3%;
	}

	& .row-mobile {
		font-family: $National2Regular;
		font-weight: 500;
		color: #fff;

		.global-accordian-container {
			.global-accordian-header-container {
				padding: 17px 17px;
				border-top: 1px solid $Dark-Gray;
				border-bottom: 1px solid $Dark-Gray;

				.global-accordian-header {
					color: white;
					font-family: $National2Regular;
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 24px;
				}
			}

			.global-accordian-content {
				.footer-mobile-list {
					padding-left: 17px;
					list-style: none;
					outline: none;
				}

				.footer-mobile-list > li {
					padding-top: 20px;
					outline: none;
				}

				.footer-mobile-list > li > a {
					color: white;
					font-family: $National2Regular;
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: -0.1px;
					text-decoration: unset;
					white-space: nowrap;
					outline: none;
				}

				@media screen and (max-width: 276px) {
					.footer-mobile-list > li > a {
						white-space: pre-wrap;
					}
				}
			}
		}

		.footer-accordian-open {
			background: rgba(73, 73, 73, 0.4);
			outline: none;
		}
	}

	& .accordion-conatiner {
		& li {
			display: block;
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			letter-spacing: -0.1px;
			color: $Light-Gray;
			margin-bottom: 5px;

			& a {
				color: $Light-Gray;
				font-weight: normal;
				text-decoration: none;
				font-size: 14px;
				cursor: pointer;

				&:hover {
					color: #fff;
				}
			}
		}
	}

	.row-copyright {
		margin-top: 9%;
	}

	.pdp-row-copyright {
		margin-top: 9%;

		@media #{$breakpoint-below-tablet} {
			margin-top: 0;
			height: 12vh;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	& .mobile-header-logo {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		margin-top: 10%;
		margin-bottom: 14%;
	}

	& .mobile-language {
		display: flex;
		// justify-content: space-between;
		justify-content: flex-end;
		margin: 6% 4%;
		align-items: center;

		@media screen and (max-width: 320px) {
			margin: 6% 4%;
		}

		& button {
			background-color: $Brand-Black;
			border: none;
			@media #{$mobile-only} {
				width: 90px;
				padding: 0;
			}
		}
	}

	.mobile-horizontal {
		border-top: 1px solid $Dark-Gray;
	}

	hr {
		margin: 0 !important;
	}

	.app-store {
		display: block;
		line-height: 4;
		color: $Inactive-Gray;
		padding: 18px 20px 18px 20px;
		margin-bottom: 13%;

		& .flex {
			font-size: 14px;
		}
	}

	.row-one {
		display: inline-flex;
		justify-content: space-evenly;
		width: 100%;
		font-family: $National2Regular;
		font-size: 14px;
		margin-top: 2%;
		margin-bottom: 4%;

		& img {
			cursor: pointer;
		}

		& .category-items {
			display: inline-flex;
			justify-content: space-evenly;
			width: 42%;
			margin-left: 2%;

			& li {
				list-style-type: none;
			}
		}

		& .findstore {
			width: 9%;

			a {
				text-decoration: underline !important;
				color: #fff !important;
				font-size: 13px;
				font-weight: normal;
				cursor: pointer;
			}

			img {
				margin-right: 7%;
			}
		}
	}

	.row-two {
		display: $d-inline-flex;
		justify-content: space-between;
		width: 100%;
		text-align: $center;
		margin-top: 2%;
		font-family: $National2Regular;
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 22px;
		padding-bottom: 14px;

		& .lang-div {
			margin-left: 4%;

			& button {
				background-color: $Brand-Black;
				border: none;
			}
		}

		& .social-div {
			margin-right: 4%;
		}
	}

	.findstore-mobile {
		padding: 10px 18px;
		width: 100%;
		margin-top: 3%;
		margin-bottom: 3%;

		& img {
			margin-right: 3%;
		}

		& a {
			color: $white;
			font-weight: normal;
			text-decoration: none;
			font-size: 14px;
		}
	}

	.social-icons {
		text-align: $right;
		margin-top: 0;
		@media #{$mobile-only} {
			display: flex;
			max-width: 190px;
		}
	}

	& .language-select {
		border-radius: 34px;
		padding: 2% 5% 3% 23%;
		background-color: $Brand-Black;
		font-family: $National2Regular;
		color: $white;
		border: 1px solid $Dark-Gray;
		box-sizing: border-box;
		width: 89px;
		height: 36px;
		cursor: pointer;
		text-transform: uppercase;
		pointer-events: none;

		@media #{$breakpoint-below-tablet} {
			margin-top: 0;
		}

		@media (max-width: 480px) {
			padding: 1% 7% 2% 28px;
			min-width: 90px;
		}
	}

	& .dropdown-img-footer {
		background: url('../../staticAssets/images/down-arrow.svg') no-repeat 100% 100%;
		height: 15px;
		width: 12px;
		position: relative;
		margin-top: 4%;
		right: 27%;
		display: table;

		@media #{$mobile-only} {
			margin-top: 5%;
			right: 56% !important;
		}

		@media #{$breakpoint-below-tablet} {
			right: 36%;
		}
	}

	.social-btns {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		column-gap: 5%;

		@media #{$mobile-only} {
			column-gap: unset;
		}

		& img {
			width: 100%;
			height: 100%;
			cursor: pointer;
		}

		a:nth-of-type(2) {
			margin-left: 5%;
		}
	}

	.flex {
		margin-left: 0;
		margin-bottom: 0;
	}

	.app {
		&-btn {
			width: 45%;
			max-width: 160px;
			color: #fff;
			margin: 20px 10px;
			text-align: left;
			border-radius: 5px;
			text-decoration: none;
			font-family: 'Lucida Grande', sans-serif;
			font-size: 10px;
			text-transform: uppercase;
			border: 1.44323px solid #494949;

			&.blu {
				background-color: #101010;
				transition: background-color 0.25s linear;

				&:hover {
					background-color: #454545;
				}
			}

			img {
				text-align: center;
				font-size: 28px;
				margin-right: 7px;
			}
		}
	}
}

.nonlocalized-footer {
	padding-bottom: 25%;
}

.localized-footer {
	padding-bottom: 10%;
	//padding-bottom: 18%;
}


.header-text-footer {
	font-family: $National2Regular;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	color: $white;
	margin-bottom: 10px;
}
.footer-links {
	padding-left: 0;
	margin-top: 0;
	list-style: none;
	width: 30%;
	text-align: left;

	& li {
		display: block;
		font-family: $National2Regular;
		font-style: normal;
		font-weight: normal;
		font-size: 14px;
		line-height: 24px;
		letter-spacing: -0.1px;
		color: $Light-Gray;
		margin-top: 5px;

		& a {
			color: $Light-Gray;
			font-weight: 400;
			text-decoration: none;
			font-size: 14px;
			cursor: pointer;
			white-space: nowrap;			

			&:hover {
				color: #fff;
			}
		}
	}

	& .kfc-footer-logo {
		height: 60px;
		width: 60px;
	}
}

.footer-links.inline {
	li {
		display: inline-block;
	}
}

.copyright-mobile {
	margin: auto;
	// padding-bottom: 7%;
}

.column {
	display: flex;
	align-items: center;

	.copyright-text {
		margin: 0;
		font-family: $National2Regular;
		font-size: 12px;
		text-align: $center;
		opacity: 0.9;
		line-height: 12px;
		color: $Inactive-Gray;
	}
}

.social-icons {
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	text-align: center;

	li {
		display: inline-block;
		margin-bottom: 4px;
		margin-left: 10px;

		& a:focus {
			outline: none;
		}

		img {
			cursor: pointer;

			@media #{$breakpoint-below-tablet} {
				height: 50px;
				width: 50px;
			}

			@media #{$breakpoint-below-include-iphone5} {
				height: 40px;
				width: 40px;
			}
		}

		li.title {
			margin-right: 15px;
			text-transform: uppercase;
			color: #96a2b2;
			font-weight: 700;
			font-size: 13px;
		}
	}

	.social-icons.size-sm {
		a {
			line-height: 34px;
			height: 34px;
			width: 34px;
			font-size: 14px;
		}
	}
}

/**  Mobile Styles **/
.accordion {
	font-family: $National2Regular;
	background-color: $Brand-Black;
	color: $white;
	cursor: pointer;
	padding: 18px;
	width: 100%;
	border: none;
	text-align: $left;
	outline: none;
	font-size: 14px;
	transition: 0.4s;
	border-top: 1px solid $Dark-Gray !important;

	&:hover {
		background-color: #ccc;
	}

	&::after {
		content: url('../../staticAssets/images/SingleArrow.svg');
		color: $white;
		font-weight: bold;
		float: right;
		margin-left: 5px;
	}
}

// .active {
// 	background-color: #ccc;

// 	&::after {
// 		content: "\2303";
// 	}
// }

.panel {
	padding: 0 18px;
	background-color: white;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.2s ease-out;
}
