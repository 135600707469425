.scheduleOrder-form {
	margin: 0 15%;

	@media #{$breakpoint-below-tablet} {
		margin: 0 2vw;
	}

	& .order-type {
		font-family: $National2Condensed;
		font-style: normal;
		font-weight: bold;
		font-size: 18px;
		line-height: 25px;
		letter-spacing: 0.5px;
		text-transform: $uppercase;
		color: $Brand-Black;
		margin-top: 3vh;
	}

	& .ordertype-input-div {
		position: relative;

		& input {
			border-top: none;
			border-left: none;
			border-right: none;
			border-bottom: 1px solid $Light-Gray;
			background-color: transparent;
			padding-bottom: 2vh;
			padding-top: 2vh;
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 24px;
			color: $Brand-Black;
			padding-right: 8%;
		}

		& input:focus,
		:active {
			outline: none;
		}
	}

	& .edit-input {
		position: absolute;
		top: 0;
		right: 0;
		text-decoration: underline;
		padding-top: 1.5vh;

		a {
			color: $Brand-Black;
		}
	}

	& .confirm-schedule-btn {
		margin-top: 5vh;
	}

	& .schedule-date-time {
		display: flex;
		justify-content: space-between;
		margin-top: 2.22vh;

		@media #{$breakpoint-below-tablet} {
			display: block;
		}

		& .asap-default {
			font-weight: 600;
		}

		& .schedule-date,
		.schedule-time {
			width: 48%;
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 12px;
			line-height: 22px;
			color: $Dark-Gray;

			@media #{$breakpoint-below-tablet} {
				width: 100%;
				// margin-bottom: 12px;
				margin-top: 2vh;
			}

			& select {
				width: 97%;
				border-top: none;
				border-left: none;
				border-right: none;
				border-bottom: 1px solid $Light-Gray;
				background-color: transparent;
				padding-bottom: 1vh;
				padding-top: 1vh;
				font-family: $National2Regular;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 24px;
			}

			& select:focus,
			:active {
				outline: none;
			}
		}
	}

	& .schedule-btn-div {
		@media #{$breakpoint-below-tablet} {
			& .confirm-schedule-btn {
				margin-top: 15vw;
				width: 100%;
			}
		}
	}
}

.error-container {
	height: 22vh;
	background: white;
	padding: 4% 10% 6% 10%;
	margin: 2% 0 2% 0;
	border-radius: 5px;
	font-family: $National2Regular;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 24px;
	text-align: center;
	letter-spacing: -0.1px;
	color: #ff2e00;

	& .error-img {
		background: url("../../staticAssets/images/Error.svg") no-repeat 100% 100%;
		height: 16px;
		width: 19px;
		display: block;
		margin: auto;
	}
}

.hide-error-container{
	display: none;
}

.error-can-not-deliver{
	margin: 0 !important;
    padding: 15px 0 !important;
    background: #FFF4F2;
    height: 13vh;
	.error-sub-container{
		display: flex;
		justify-content: flex-start;
		margin-left:20.59px;
		@media #{$breakpoint-below-tablet} {
			align-items: flex-start;
			height: 11vh;
		}

		img{
			margin-right: 6px;
		}
	}

	.store-name{
		font-weight: bold;	
	}

	.error-msg{
		margin-left: 9.29px;
		color: $Brand-Black;
		@media #{$breakpoint-below-tablet} {
			margin-top: -5px;
    		text-align: start;
		}
	}
}

.time-input-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-family: $National2Regular;
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 24px;
	text-align: left;
	color: $Brand-Black;
	border-bottom: 1px solid #e3e3e3;
	padding-bottom: 5px;
}

.time-inputbox {
	&.disable-time-dropdown{
		pointer-events: none;
		cursor: not-allowed!important;
	}
	border-bottom: 1px solid $Brand-Black;
}

.schedule-time-list-container {
	position: absolute;
	background: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
	border-radius: 4px;
	width: 31.5%;
	height: 30%;
	list-style: none;
	margin-bottom: 40px;
	padding-right: 10px;
	padding-top: 20px;

	> ul {
		list-style: none;
		overflow-y: scroll;
		white-space: nowrap;
		padding: 0%;
		width: 100%;
		max-height: 100%;
		margin: 0;
		cursor: pointer;

		&::-webkit-scrollbar {
			width: 3px;
		}

		&::-webkit-scrollbar-track {
			background: $Light-Gray;
			margin-top: 10px;
			margin-bottom: 10px;
		}

		&::-webkit-scrollbar-thumb {
			background: $Brand-Black;
		}

		> li {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 38px;
			padding-inline-start: 8%;

			&:hover {
				background: $Mashed-Potato-Gray;
			}
		}
	}

	@media #{$breakpoint-below-tablet} {
		width: 90vw;
	}

	@media #{$breakpoint-between-tablet-desktop} {
		position: absolute;
		width: 31.5%;
		height: 35%;
		overflow-y: auto;

		ul {
			height: 100%;
			overflow-y: auto;
			max-height: none;
			width: 99% !important;
		}
	}
}

@media #{$mobile-only} {
	.schedule-time-list-container {
		height: 25vh;
	}
}

.dateTitleDatePicker {

	@media #{$breakpoint-below-tablet} {
		margin-bottom: 0;
	}
}

.date-pick {
	position: relative;
	width: 100%;

	.react-datepicker-wrapper {
		width: 100%;
	}

	.custom-class {
		border: none;
		border-bottom: 1px solid #e2dcdc;
		background: none;
		outline: none;
		width: 100%;
	}

	.date-icon {
		position: absolute;
		bottom: 40%;
		right: 3%;
		cursor: pointer;
	}

	.react-datepicker__day--today {
		border-radius: 100%;
		border: 1px solid $Dark-Gray;
	}

	.react-datepicker__current-month {
		padding-bottom: 2.5%;
		border-bottom: 1px solid $Light-Gray;
		margin-bottom: 8%;
	}

	.react-datepicker-popper {
		margin-top: -2px !important;
		min-width: 250px;
		width: 100%;
		left: auto !important;
		right: 0;

		.react-datepicker {
			border: none;
			border-radius: 0;
			width: 100%;
			box-shadow: 0 3px 5px #dedede;

			.react-datepicker__navigation {
				outline: none;
				top: 20px;
				transform: rotate(45deg);
				border: 2px solid $Brand-Black;
				margin-top: 4px;
			}

			.react-datepicker__navigation--previous {
				left: 25px;
				border-width: 0 0 2px 2px;
			}

			.react-datepicker__navigation--next {
				right: 25px;
				border-width: 2px 2px 0 0;
			}

			.react-datepicker__month-container {
				width: 100%;

				.react-datepicker__header {
					border-radius: 0;
					background: none;
					border: none;
					padding-top: 7%;
				}

				.react-datepicker__day-name,
				.react-datepicker__day {
					width: 11%;
					outline: none;

					@media #{$breakpoint-below-tablet} {
						padding: 8px 10px;
						width: 12%;
					}
				}

				.react-datepicker__day:hover {
					background: $Brand-Black;
					color: $white;
					border-radius: 100%;
				}

				.react-datepicker__day--selected {
					outline: none;
					font-weight: bold;
					background: $Brand-Black;
					color: $white;
					border-radius: 100%;
				}

				.react-datepicker__day--keyboard-selected {
					background: none;
					color: #000;
					border-radius: 50%;
				}

				.react-datepicker__month {
					margin: 0;
				}
			}
		}
	}
}

.alert-delivery-popup {
	margin: 5% 0;
	width: 100%;
	height: 140px;
	background: $white;
	border: 1px solid $Light-Gray;
	box-sizing: border-box;
	border-radius: 8px;
	display: flex;
	align-items: center;
	// justify-content: center;
	flex-direction: column;
	font-family: $National2Regular;

	@media #{$breakpoint-below-tablet} {
		width: 90%;
		// margin-left: 8px;
		height: 157px;
		// position: absolute;
		// top: 175px;
	}

	.location-alert-icon {
		margin-top: 23px;
	}

	.location-error-message {
		margin-top: 5px;
		font-size: 14px;
		font-weight: normal;
		margin-left: 20px;
		margin-right: 20px;
		color: $Red-Orange;
		line-height: 24px;
		text-align: center;
	}

	.location-error-button-section {
		margin-top: 17px;
		display: flex;

		.location-warning-button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 32px;
			padding: 5px 15px;
			font-family: $National2Regular;
			color: $Brand-Black;
			border: 1px solid $Dark-Gray;
			box-sizing: border-box;
			border-radius: 200px;
			font-weight: 500;
			font-size: 12px;
			background: $white;
		}
	}
}

.alert-without-action-button {
	height: 123px;

	@media #{$breakpoint-below-tablet} {
		height: 120px;
	}
}
#schedule-order{
	& .modal-body{
		height: fit-content;
		overflow-y:  unset;
	}
}