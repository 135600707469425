.global-accordian-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	outline: none;

	.global-accordian-header-container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		text-align: left;
		background-color: transparent;
		border: none;

		.global-accordian-header {
			font-family: $National2Regular;
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 24px;
			display: flex;
			align-items: center;
			color: $Dark-Gray;
		}

		.global-accordian-content {
			outline: none;
		}

		.global-accordian-arrow {
			width: 8px;
			height: 8px;
			object-fit: contain;
			cursor: pointer;
			@media #{$breakpoint-tablet-only} {
				width: 12px;
				height: 12px;
			}	
		}
	}
}
