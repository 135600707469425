.ptr,
.ptr__children {
	height: 100%;
	width: 100%;
	-webkit-overflow-scrolling: touch;
	position: relative;
}

.ptr {
	&.ptr--fetch-more-treshold-breached {
		.ptr__fetch-more {
			display: block;
		}
	}
}

.ptr__fetch-more {
	display: none;
}

/**
  * Pull down transition
  */
.ptr__children,
.ptr__pull-down {
	transition: all 0.2s cubic-bezier(0, 0, 0.31, 1);
}

.ptr__pull-down {
	position: relative;
	overflow: hidden;
	left: 0;
	right: 0;
	top: 0;
	visibility: hidden;

	> div {
		display: none;
	}
}

.ptr--dragging {
	/**
    * Hide PullMore content is treshold breached
    */
	&.ptr--pull-down-treshold-breached {
		.ptr__pull-down--pull-more {
			display: none;
		}
	}

	/**
    * Otherwize, display content
    */
	.ptr__pull-down--pull-more {
		display: block;
	}
}

.ptr--pull-down-treshold-breached {
	/**
    * Force opacity to 1 is pull down trashold breached
    */
	.ptr__pull-down {
		opacity: 1 !important;
	}

	/**
    * And display loader
    */
	.ptr__pull-down--loading {
		display: block;
	}
}

.ptr__loader {
	margin: 0 auto;
	text-align: center;
}
