.Receipt-modal {
    font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;
    overflow-y: scroll;

    .modal-content {
        top: 0;

        .modal-body {
            background: $white;

            .global-accordian-content {
                background: $white;
                box-shadow: none;
            }
        }
    }

    .global-accordian-header-container {
        padding: 0;
    }
    
    .reciept-order-detail {
        margin-top: 30px;
    }

    .ReceiptModal {
        padding: 0 20px;
    }

    .reciept-header {
        display: flex;
        justify-content: space-between;
    }

    .your-order {
        font-size: 24px;
        line-height: 24px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
    }

    .receipt-number,
    .receipt-sub-heading,
    .payment-total,
    .payment-total-amount {
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $Brand-Black;
    }

    .reciept-details-heading {
        display: flex;
        margin-top: 30px;
    }

    .receipt-sub-heading {
        flex-basis: 150px;
    }

    .receipt-sub-detail {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: $Dark-Gray;
        text-transform: capitalize;
    }

    .CompletedCallAndDirection {
        display: grid;
        grid-column-gap: 0;
        grid-template-columns: 30% auto;
        padding: 10px;
        margin-bottom: 10px;
        margin-top: 0;
        padding-top: 0;

        @media #{$breakpoint-below-tablet} {
            padding: 1px;
            margin-bottom: 10px;
            margin-top: 0;
            grid-template-columns: 39% auto;
        }
    }

    .anchorTags {
        color: black;
    }

    .KfcphoneNums {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        text-decoration-line: underline;
        color: #202124;
        margin-right: 37px;
        cursor: pointer;
        text-transform: capitalize;

        @media #{$breakpoint-below-tablet} {
            // margin-right: 75px;
            display: block;
            padding-bottom: 11px;
        }
    }

    .KfcDirectionIcon {
        margin-right: 11px;
    }

    .reciept-payment-details {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
    }

    .payment-type {
        font-weight: normal;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: -0.1px;
        color: $Dark-Gray;
        font-family: $National2Regular;
        text-transform: capitalize;
    }

    .payment-amount,
    .item-amount {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        text-align: right;
        color: $Brand-Black;
    }

    .reciept-accordian {
        border-top: 2px solid $Brand-Black;
        border-bottom: 2px solid $Brand-Black;
        margin-top: 32px;
        padding: 5px;

        .global-accordian-container {
            .global-accordian-header-container {
                padding: 0;
            }
        }

        .global-accordian-header,
        .global-accordian-arrow {
            font-size: 18px;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: $Brand-Black;
            font-weight: bold;
            font-family: $National2Condensed;
        }

        .reciept-order-item {
            margin-bottom: 8px;
        }

        .reciept-order-item,
        .reciept-item-details {
            display: flex;
            justify-content: space-between;
        }

        .item-count-box {
            height: 24px;
            width: 24px;
            left: 0;
            top: 0;
            background: $Light-Gray;
            mix-blend-mode: multiply;
            border-radius: 4px;

            .item-count {
                font-family: $National2Regular;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 22px;
                color: $Brand-Black;
            }
        }

        .item-desc-section {
            display: flex;
            flex-direction: column;
            font-family: $National2Regular;
            font-style: normal;
        }

        .item-desc {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: $Brand-Black;
        }

        .item-desc-quantity {
            font-size: 12px;
            line-height: 22px;
            color: $Dark-Gray;
        }
    }

    .width-style {
        width: 30%;
    }

    .width-style-lg {
        width: 65%;
    }

    .ml {
        margin-left: 10px;
    }

    .mr {
        margin-right: 10px;
    }

    .horizontal-bar {
        border-top: 2px solid $Brand-Black;
    }
}
/* order receipt global tenant css start */

@mixin order-receipt-title-font {
	font-family: $National2Condensed;
    font-style: normal;
    font-weight: bold;  
    letter-spacing: 1px;
    text-transform: uppercase;
    color: $Brand-Black;
    font-size: 16px;
    line-height: 20px; 
    margin-bottom: 10px;
}
@mixin order-receipt-value-font {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $Brand-Black;
}
@mixin order-receipt-item-info-title {
    font-family: $National2Regular;
    font-style: normal;
    font-weight: bold;
    color: $Brand-Black;
}
@mixin order-receipt-item-addn-price{
    font-family: $National2Regular;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    color: $Dark-Gray;
    margin-bottom: 6px;
}
@mixin accordion-detail-item-font{
    font-family: $National2Regular;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: $Brand-Black;
}
@mixin account-modal-styles{
    position: absolute;
    width: 48%;
    left: 26%;
    height: auto;
}



#order-history-global{
    & .re-order-btn{
        display: unset;
    }
    @media #{$breakpoint-below-tablet} {
            //margin: 0 4%;
        & .reorder-btn-container{
            width: 100%;
            & .re-order-btn{
                width: 100%;
            }
        }
    }
    &  .account-accordian{
        margin-top: 10px;
        background: $Mashed-Potato-Gray;
    }
    & .order-history-header{
        opacity: 1;
        }

    & .order-date-time {
        font-family: $National2Regular;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 22px;
        display: flex;
        align-items: center;
        text-align: right;
        color: $Dark-Gray;
        margin-right: 10px;
    }

    & .detailed-item-info{
        & .item-details-section {
            display: flex;
            flex-direction: column;
            width: 80%;
            align-items: start;
            margin-left: 6.5%;
            margin-bottom: auto;
            margin-top: 18px;
    
            & .item-title {
                @include accordion-detail-item-font;
                flex: none;
                order: 0;
                flex-grow: 0;
                margin: 2px 0;
            }
    
            & .item-price {
                @include accordion-detail-item-font;
                text-align: right;
                color: $Brand-Black;
                flex: none;
                order: 2;
                flex-grow: 0;
                margin: 10px 0;
            }
        }
    }
    .order-ocasion-option,
		.order-receipt-option {
            font-family: $National2Medium;
        }
    .order-receipt{
        & .order-id{
            @include order-receipt-title-font;
    
            font-size: 20px;
            line-height: 22px;
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            & .accordian-arrow{
                cursor: pointer;
            }
        }
        & .delivery-address-title{
            @include order-receipt-title-font;
        }
        & .delivery-address{
            @include order-receipt-value-font;
            margin-bottom: 10px;
        }
        & .instruction-title{
            @include order-receipt-title-font;
        }
        & .instruction{
            @include order-receipt-value-font;
        }
        & .prepared-by{
            @include order-receipt-title-font;
        }
        & .store-info{
            @include order-receipt-value-font;
            font-weight: 600;
            display: flex;
            margin-bottom: 30px;

            @media #{$breakpoint-below-tablet} {
                justify-content: space-between;
            }

            & .storeName{
                margin-right: 15%;
            }
            & .storeName-delivery{
                margin-right: 10px;
            }
            & .get-direction-link{
                text-decoration: underline;
            }
        }
        & .operating-hrs{
            @include order-receipt-value-font;
            letter-spacing: -0.1px;
            color: $Dark-Gray;
            &.mobile-operating-hrs{
                margin-top: -25px;
                margin-bottom: 30px;
            }
        }
        & .total-title{
            @include order-receipt-title-font;
            margin-top: 14px;
        }
        & .total-amount{
            @include order-receipt-title-font;
            margin-top: 14px;
        }

        & #line{
            border-top: 2px solid $Brand-Black;
            border-bottom: 2px solid $Brand-Black;
            margin-bottom: 20px;
            
            & .global-accordian-header-container{
                height: unset;
                margin-bottom: 0;
                padding: 20px 5% 20px 0;
    
                & .global-accordian-header {
                    @include order-receipt-title-font;
                    font-size: 18px;
                    line-height: 25px;
                    letter-spacing: 0.5px;
                    margin-bottom: 0;
                }
            }
            & .global-accordian-content {
                background: $white;
                box-shadow: none;
    
                & .number{
                    @include order-receipt-item-info-title;
    
                    font-size: 12px;
                    line-height: 22px;
                    background: $Light-Gray;
                    border-radius: 4px;
                    padding: 2px 5px;
                }

                & #food-name-title{
                    @include accordion-detail-item-font;
                }

                & .foodName{
                    @include order-receipt-item-info-title;
    
                    font-size: 14px;
                    line-height: 20px;
                }
    
                & .secondary-description{
                    @include accordion-detail-item-font;
                    font-size: 12px;
                    line-height: 22px;
                    margin-left: 5%;
                    color: $Dark-Gray;
                    font-weight: normal;
                }
    
                & #food-item-container{
                    width: 85%;
                }
            }
        }
        & .price-list{
            display: flex;
            justify-content: space-between;
    
            & .price-text{
                @include order-receipt-item-addn-price;
            }
            & .amount{
                @include order-receipt-item-addn-price;
                font-weight: 600;
                color: $Brand-Black;
            }
        }
    }
    #receipt-modal{
        & .modal-content{
            @include account-modal-styles;
            top: 8%;
            max-height: 85%;

            @media #{$breakpoint-below-tablet} {
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                max-height: 100%;
            }

            & .header-title{
                text-transform: uppercase;
                @media #{$breakpoint-below-tablet} {
                    text-transform: capitalize;
                }
            }
        }

        & .custom-modal-close{
            @media #{$breakpoint-below-tablet} {
            top: 15px;
            }
        }
    }

    #occassion-modal{
        & .modal-content{
            @include account-modal-styles;
            top: 22%;
            max-height: 85%;
            width: 40%;
            left: 30%;

            @media #{$breakpoint-below-tablet} {
                top: 60%;
                height: 40%;
                width: 100%;
                left: 0;
                border-radius: 10px 10px 0 0;
            }
        }
    }

    & .cart-empty-placeholder-view{
        padding-left: 2%;
        padding-right: 0;

        & .cart-empty-placeholder-text{
            @media #{$breakpoint-below-tablet} {
                margin: 0;
                padding: 8% 3.5%;
            }
        }

        & .start-order-btn{
            @media #{$breakpoint-below-tablet} {
                margin-left: 3.5%;
            }
        }
    }

    & .cart-empty-placeholder{
        margin: 3% 0;
        @media #{$breakpoint-below-tablet} {
            margin: 3%;
        }
       
        & .cart-empty-img{
            width: 37%;
            padding-top: 13%;

            @media #{$breakpoint-below-tablet} {
                width: unset;
                margin: 0 auto;
            }
        }
    }

        & .footer-btn{
            display: unset;
            font-weight: bold;
            border: 1.5px solid $Brand-Black;
        }
}
